import { sortDate } from '@utilities/dates/dates';
import { DateTime } from 'luxon';
import { MoneyMovementWithSender } from '../payments-summary/payments-summary';

export const filterAndSortPaymentsByDate = (
  date: string,
  paymentsData?: MoneyMovementWithSender[],
) => {
  const filterByDate = (payments: MoneyMovementWithSender[], d: string) => {
    if (!d) {
      return payments;
    }

    return payments.filter((p) => {
      const localDate = DateTime.fromSQL(p.createdAt).toFormat('yyyy-MM-dd');
      /*
      `localDate` is used for a potential time zone discrepancy.
      The `date` param here is created downstream via Luxon's DateTime.fromSQL function.

      When this fn parses a SQL timestamp from the api response, it uses your
      LOCAL time zone unless explicitly instructed otherwise.

      This means that if your local time zone is behind UTC,
      DateTime.fromSQL(p.createdAt).toFormat('yyyy-MM-dd') will return a date
      that is one day behind the actual date.

      Ex:
      `DateTime.fromSQL('2024-12-19 01:07:43.245+00').toFormat('MMM d, yyyy')`
      translates to: Dec 18, 2024
      */

      return localDate.includes(d);
    });
  };

  const allPayments = paymentsData ?? [];
  const filteredPayments = date ? filterByDate(allPayments, date) : allPayments;

  return filteredPayments
    .map((payment) => ({
      ...payment,
      amount: `${Number(payment.payAmount) / 100}`,
    }))
    .sort((a, b) => sortDate(a, b, false));
};

/**
 * Returns an array of dates within a given interval.
 * If no interval is provided, it uses the first and last payment dates from the payments array.
 *
 * @returns Array of dates in the format 'yyyy-MM-dd'
 */
export const getIntervalDates = (
  payments: MoneyMovementWithSender[],
  fromDate?: string,
  toDate?: string,
) => {
  const allDates: string[] = [];

  if (!payments.length) {
    return allDates; // Return an empty array if there are no payments
  }

  let startDate = fromDate
    ? DateTime.fromISO(fromDate)
    : DateTime.fromSQL(payments[0].createdAt);
  let endDate = toDate
    ? DateTime.fromISO(toDate)
    : DateTime.fromSQL(payments[payments.length - 1].createdAt);

  // Ensure startDate is not after endDate
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  while (startDate <= endDate) {
    allDates.push(startDate.toFormat('yyyy-MM-dd'));
    startDate = startDate.plus({ days: 1 });
  }

  return allDates;
};
