import Country from 'country-state-city/lib/country';
import {
  InternationalPayMethod,
  InternationalRecipient,
  CurrencySelectProps,
  InternationalWire,
  InternationalWireUSD,
} from 'areas/payments/components/send-payment/international-payments/util/types';

export const supportedCurrenciesData: CurrencySelectProps[] = [
  {
    value: 'CAD',
    label: 'Canadian Dollar',
    code: 'CA',
    symbol: '$',
  },
  {
    value: 'CNY',
    label: 'Chinese Yuan',
    code: 'CN',
    symbol: '¥',
  },
  {
    value: 'EUR',
    label: 'Euro',
    code: 'EU',
    symbol: '€',
  },
  {
    value: 'INR',
    label: 'Indian Rupee',
    code: 'IN',
    symbol: '₹',
  },
  {
    value: 'MXN',
    label: 'Mexican Peso',
    code: 'MX',
    symbol: '$',
  },
  {
    value: 'SAR',
    label: 'Saudi Riyal',
    code: 'SA',
    symbol: '﷼',
  },
  {
    value: 'AED',
    label: 'United Arab Emirates Dirham',
    code: 'AE',
    symbol: 'د.إ',
  },
  {
    value: 'GBP',
    label: 'UK Pound Sterling',
    code: 'GB',
    symbol: '£',
  },
];

export const isInternationalPayMethod = (
  method: string,
): method is InternationalPayMethod => {
  return method === 'internationalWireUSD' || method === 'internationalWire';
};

export const isInternationalWireUSD = (
  method: string,
): method is InternationalWireUSD => {
  return method === 'internationalWireUSD';
};

export const isInternationalWire = (
  method: string,
): method is InternationalWire => {
  return method === 'internationalWire';
};

export const isInternationalRecipient = (
  recipient: any,
): recipient is InternationalRecipient => {
  return (
    recipient.currency &&
    recipient.countryOfRecipientsBank &&
    recipient.countryOfRecipient
  );
};

export const internationalWireDisclaimer =
  'Payment services are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office: The Steward Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199). In the US Currency Cloud operates in partnership with CFSB. CFSB fully owns the bank program and services are provided by The Currency Cloud Inc.';

const CC_RESTRICTED_CITIES = ['kherson', 'luhansk', 'donetsk', 'zaporizhzhia'];

export const isRestrictedCity = (city: string) => {
  return CC_RESTRICTED_CITIES.includes(city.toLowerCase());
};

// derived from https://support.currencycloud.com/hc/en-gb/articles/360017656199-Restricted-Countries (+ Russia)
const CC_RESTRICTED_COUNTRY_CODES = [
  'BY',
  'CU',
  'IR',
  'KZ ',
  'LY',
  'MM',
  'KP',
  'SS',
  'SD',
  'SY',
  'VE',
  'RU',
];

export const countrySelectOptions = Country.getAllCountries()
  .map((c) => ({
    label: c.name,
    value: c.isoCode,
  }))
  .filter((c) => !CC_RESTRICTED_COUNTRY_CODES.includes(c.value));

export const formatCamelCasedValue = (field: string) => {
  return field
    .split('')
    .map((char) => {
      return char === char.toUpperCase() ? ` ${char.toLowerCase()}` : char;
    })
    .join('');
};

const parsedFields: Record<string, string> = {
  bicSwift: 'BIC/SWIFT',
  acctNumber: 'Account number',
};

export const formatLabel = (field: string) => {
  const formattedLabel = formatCamelCasedValue(field);
  if (parsedFields[field]) {
    return parsedFields[field];
  }
  return formattedLabel[0].toUpperCase() + formattedLabel.slice(1);
};
