import { Avatar, Button, Text, TextInput } from '@mantine/core';
import { CloseIcon } from '../../../assets/svg';
import useStyles from '../navbar.styles';
import { useState } from 'react';
import { useMultiAccount } from './multi-account-hook';
type Props = {
  closeModal: () => void;
};

const AccountsModal = ({ closeModal }: Props) => {
  const { classes } = useStyles({});

  const [filterText, setFilterText] = useState('');

  const { currentAccount, filteredAccounts, setBusinessId } = useMultiAccount();

  const searchAccount = (name: string) => {
    return (name && name.toLowerCase()).includes(filterText.toLowerCase());
  };

  const filteredAccountsBySearch = filteredAccounts?.filter((item: any) => {
    return searchAccount(item.name);
  });

  return (
    <div>
      <div className={classes.modalHeader}>
        <Text size={'lg'}> Switch accounts </Text>
        <CloseIcon
          width={20}
          height={20}
          onClick={() => closeModal()}
          style={{
            cursor: 'pointer',
          }}
        />
      </div>
      <div style={{ margin: 20 }}>
        <div className={classes.currentAccountContainer}>
          <div className={classes.row}>
            <Avatar
              sx={(theme) => ({
                border: `1px solid ${theme.colors.neutral[5]}`,
              })}
            >
              {currentAccount?.name[0].toUpperCase()}
            </Avatar>
            <Text ml={10} color="#000">
              {currentAccount?.name}
            </Text>
          </div>
          <div>
            <Text size={'sm'} c="neutral.7">
              Current
            </Text>
          </div>
        </div>
        <div>
          <TextInput
            onChange={(e) => setFilterText(e.target.value)}
            mb={20}
            mt={20}
            placeholder="Search accounts"
            label="Search accounts"
          />
        </div>
        <div>
          {filteredAccountsBySearch?.map((item) => {
            return (
              <div
                key={item.accountId}
                className={classes.optionContainer}
                onClick={() => {
                  if (setBusinessId) {
                    setBusinessId(item.businessId);
                  }
                }}
              >
                <div className={classes.row}>
                  <Avatar
                    sx={(theme) => ({
                      border: `1px solid ${theme.colors.neutral[5]}`,
                    })}
                    size={'sm'}
                  >
                    {item?.name[0].toUpperCase()}
                  </Avatar>
                  <Text ml={10} color="#000">
                    {item.name}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountsModal;
