import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
  useDebitCardsModalFilters,
} from '../use-cards-filters';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CardTableRow } from '../card-table-row.model';

type Props = {
  cards: CardTableRow[];
};

type CardNumberFilterProps<T> = Props & {
  filterHook: ActiveFiltersReturnType<T>;
};

const CardNumberFilter = <T extends CardTableRow>({
  cards,
  filterHook,
}: CardNumberFilterProps<T>) => {
  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  const cardLast4s = useMemo(() => {
    return uniq(
      cards.map((d) => ({
        label: getPaddedAccountMask(d.last4, 4),
        value: d.last4,
      })),
      false,
      (d) => d.value,
    );
  }, [cards]);

  return (
    <MultiSelect
      data={cardLast4s}
      placeholder="Card numbers"
      searchable
      value={getFilterByKey('last4')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('last4', {
            key: 'last4',
            filterValue: val,
            fn: (row) => val.includes(row.last4),
            label: `Card numbers: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('last4');
        }
      }}
    />
  );
};

export const CreditCardNumberFilter = ({ cards }: Props) => {
  const filterHook = useCreditCardsModalFilters();

  return <CardNumberFilter cards={cards} filterHook={filterHook} />;
};

export const ChargeCardNumberFilter = ({ cards }: Props) => {
  const filterHook = useChargeCardsModalFilters();

  return <CardNumberFilter cards={cards} filterHook={filterHook} />;
};

export const DebitCardNumberFilter = ({ cards }: Props) => {
  const filterHook = useDebitCardsModalFilters();

  return <CardNumberFilter cards={cards} filterHook={filterHook} />;
};
