import {
  Flex,
  Loader,
  rem,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useGetAllRecipients } from '@queries/use-recipients';
import { useNavigate } from 'react-router-dom';
import { Recipient } from 'types/recipient';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import { useEffect, useState } from 'react';
import RecipientAccountsSelect from '../amount-source-step/recipient-account-select';
import FlexbaseSelect from '@common/select/flexbase-select';
import { parseAccountsInfo } from '@utilities/payments-rows';
import { ParsedAccount } from 'types/parsed-account';
import { PiPlusCircle } from 'react-icons/pi';

type CreateLabelProps = {
  label: string;
  onClick: () => void;
};

const CreateLabel = ({ label, onClick }: CreateLabelProps) => {
  const theme = useMantineTheme();
  return (
    <Flex sx={{ cursor: 'pointer' }} gap="xs" py={'xs'} onClick={onClick}>
      <PiPlusCircle color={theme.colors.neutral[9]} size="1.25rem" />
      <Text c={theme.colors.neutral[9]} fz="sm">
        Add new recipient{label ? `: ${label}` : ''}
      </Text>
    </Flex>
  );
};

const getSelectOptions = (recipientList: Recipient[]) => {
  const mostFrequentlyPaid = recipientList
    .sort((a, b) => b.paymentsCount - a.paymentsCount)
    .slice(0, 3);

  const createGroupData = (data: Recipient[], group: string) => ({
    group,
    items: data.map((item) => ({ value: item.id, label: item.name })),
  });

  const frequentlyPaidGroupData = createGroupData(
    mostFrequentlyPaid,
    'Most frequently paid',
  );

  const mostFrequentlyPaidIds = new Set(
    mostFrequentlyPaid.map((item) => item.id),
  );

  const otherAccounts = createGroupData(
    recipientList.filter((r) => !mostFrequentlyPaidIds.has(r.id)),
    'Other',
  );

  return [frequentlyPaidGroupData, otherAccounts];
};

const useSelectOptions = () => {
  const { data, isPending } = useGetAllRecipients();
  const { setState, state } = useInvoiceWizard();
  const [selectedRecipientId, setSelectedRecipientId] = useState(
    state?.recipient?.id ?? '',
  );

  const activeRecipients =
    data?.recipients.filter((r) => r.status === 'active') ?? [];

  const handleChange = (val: string | null) => {
    setSelectedRecipientId(val || '');

    const foundRecipient = activeRecipients.find((r) => r.id === val);
    setState({ recipient: foundRecipient, recipientAccount: undefined });
  };

  const recipientAccounts = state.recipient
    ? parseAccountsInfo([
        ...(state.recipient.ach ?? []),
        ...(state.recipient.wire ?? []),
      ])
    : [];

  useEffect(() => {
    if (state?.recipient?.id && state.recipientAccount?.id) {
      setState({ isNextEnabled: true });
    } else {
      setState({ isNextEnabled: false });
    }

    if (recipientAccounts.length > 0 && !state.recipientAccount) {
      setState({ recipientAccount: recipientAccounts[0] });
    }
  }, [
    state?.recipient?.id,
    state.recipientAccount?.id,
    recipientAccounts.length,
  ]);

  const handleSelectAccount = (account: ParsedAccount) => {
    setState((prev) => ({
      ...prev,
      recipientAccount: account,
    }));
  };

  return {
    selectOptions: getSelectOptions(activeRecipients),
    isPending,
    selectedRecipientId,
    handleChange,
    recipientAccounts,
    handleSelectAccount,
  };
};

const SelectRecipientStep = () => {
  const navigate = useNavigate();
  const { state } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled = false } = state;
  const {
    selectOptions,
    isPending,
    handleChange,
    selectedRecipientId,
    recipientAccounts,
    handleSelectAccount,
  } = useSelectOptions();

  const handleNavigateToAddRecipientFlow = () => {
    navigate('/recipients/new');
  };

  const handleAddNewPaymentMethod = () => {
    navigate(`/recipients/new?recipientId=${selectedRecipientId}`);
  };

  return (
    <InvoiceWizard.Step hideNext>
      <FlexbaseSelect
        inputProps={{
          required: true,
          disabled: !isInvoiceDraft || isActionDisabled,
          autoFocus: !selectedRecipientId,
          rightSection: isPending ? <Loader size="xs" /> : null,
          rightSectionWidth: rem(30),
        }}
        searchable
        initiallyOpened={!selectedRecipientId}
        label="Name"
        placeholder="Search for a recipient"
        groupedData={selectOptions}
        nothingFound={({ value }) => (
          <CreateLabel
            label={value}
            onClick={handleNavigateToAddRecipientFlow}
          />
        )}
        onChange={handleChange}
        value={selectedRecipientId}
        data-testid={'name'}
        maxDropdownHeight={440}
      />

      {selectedRecipientId && (
        <Stack gap={0} mt="md">
          <Text size="sm">Send to</Text>
          {recipientAccounts.length > 0 && (
            <RecipientAccountsSelect
              disabled={isActionDisabled}
              accounts={recipientAccounts}
              onSelectAccount={handleSelectAccount}
              currentRecipientAccount={state.recipientAccount}
              country={state.recipientAccount?.country ?? ''}
            />
          )}
          <UnstyledButton mt="sm" onClick={handleAddNewPaymentMethod}>
            <Text color="primarySecondarySuccess.6" size="sm">
              {`Add${recipientAccounts.length > 0 ? ' new' : ''} payment method`}
            </Text>
          </UnstyledButton>
        </Stack>
      )}
    </InvoiceWizard.Step>
  );
};

SelectRecipientStep.stepId = 'select-recipient-step';

export default SelectRecipientStep;
