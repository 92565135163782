import { useState } from 'react';
import {
  Box,
  rem,
  Text,
  LoadingOverlay,
  useMantineTheme,
  SegmentedControl,
} from '@mantine/core';
import WireTransfer from './wire-transfer';
import { DepositAccount } from 'types/move-funds.model';
import BankTransfer from './bank-transfer/bank-transfer';
import { RightContentModal } from '@common/modal/right-content.modal';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';

const AddFunds = () => {
  const theme = useMantineTheme();
  const [transferOption, setTransferOption] = useState('wireTransfer');

  const {
    data: externalAccountsData,
    isLoading: externalAccountsIsLoading,
    refetch,
  } = useExternalAccounts();

  const { data: depositAccountsData, isLoading: depositAccountsDataIsLoading } =
    useGetDepositAccounts();

  const depositAccounts = depositAccountsData?.accounts ?? [];
  const externalAccounts = externalAccountsData.filter((acc) => acc.active);
  const openedDepositAccounts = depositAccounts
    .filter((acc) => acc.status === 'Open')
    .map((acc) => ({ ...acc, plaidOrDeposit: 'deposit' }) as DepositAccount);

  const isLoading = externalAccountsIsLoading || depositAccountsDataIsLoading;

  const totalAvailable = depositAccounts.reduce(
    (sum, deposit) => sum + deposit.available,
    0,
  );
  const allowACHFunding = totalAvailable >= 0.01;

  const segmentedControlData = [
    {
      value: 'wireTransfer',
      label: (
        <Box>
          <Text>Wire transfer</Text>
          <Text c="neutral.6" size="sm">
            Arrives in 1-2 business days
          </Text>
        </Box>
      ),
    },
  ];
  if (allowACHFunding) {
    segmentedControlData.splice(1, 0, {
      value: 'bankTransfer',
      label: (
        <Box>
          <Text>Bank transfer</Text>
          <Text c="neutral.6" size="sm">
            Arrives in 3 business days
          </Text>
        </Box>
      ),
    });
  }

  return (
    <RightContentModal>
      <RightContentModal.Header title="Add funds" titleSize={rem(40)} />
      <RightContentModal.Body>
        {isLoading ? (
          <LoadingOverlay visible />
        ) : (
          <>
            <SegmentedControl
              mb="xl"
              fullWidth
              value={transferOption}
              onChange={setTransferOption}
              data={segmentedControlData}
              styles={{
                root: {
                  borderRadius: rem(4),
                  backgroundColor: theme.colors.neutral[0],
                },
                label: {
                  color: theme.colors.neutral[6],
                  '&[data-active]': {
                    color: theme.colors.neutral[9],
                  },
                },
                indicator: {
                  borderRadius: rem(4),
                  backgroundColor: theme.colors.neutral[2],
                  border: `1px solid ${theme.colors.neutral[3]}`,
                },
              }}
            />

            {transferOption === 'wireTransfer' ? (
              <WireTransfer depositAccounts={openedDepositAccounts} />
            ) : (
              <BankTransfer
                refetchPlaidAccounts={refetch}
                plaidAccounts={externalAccounts}
                depositAccounts={openedDepositAccounts}
              />
            )}
          </>
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default AddFunds;
