import { Navigate, useNavigate } from 'react-router-dom';
import { CreateWizard, WizardState, WizardStep } from './create-wizard';
import { StepOne } from './steps/step-one';
import { StepTwo } from './steps/step-two';
import { Box } from '@mantine/core';
import { useIssueDebitCardFeatureFlag } from '@utilities/feature-flags';

const steps: WizardStep[] = [
  {
    id: StepOne.stepId,
    title: 'Step one',
    element: <StepOne />,
    metadata: {
      someHint: <Box>Hint: this is step one</Box>,
    },
  },
  {
    id: StepTwo.stepId,
    title: 'Step two',
    element: <StepTwo />,
  },
];

const DebitCardWizard = () => {
  const navigate = useNavigate();
  const isFeatureFlagEnabled = useIssueDebitCardFeatureFlag();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const initialState: WizardState = {
    someStateValue: '',
    counter: 0,
  };

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  return (
    <CreateWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
    />
  );
};

export default DebitCardWizard;
