import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { SwitchFilter } from '@common/filter/switch-filter';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

type CreditTransactionsAttachmentsFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

export const CreditAttachmentsFilter = ({
  filterHook,
}: CreditTransactionsAttachmentsFilterProps) => {
  const { addFilter, getFilterByKey } = filterHook;

  const onOptionChange = (selectedKey: string, selectedValue: boolean) => {
    let label = '';
    let filterFn: (row: CreditTransactionsTableRow) => boolean = () => true;

    switch (selectedKey) {
      case 'memo':
        label = selectedValue ? 'Has memo' : 'No memo';
        filterFn = (row) =>
          selectedValue ? !!row.description : !row.description;
        break;
      case 'receipt':
        label = selectedValue ? 'Has receipt' : 'No receipt';
        filterFn = (row) => (selectedValue ? !!row.docId : !row.docId);
        break;
    }

    addFilter(selectedKey, {
      label: label,
      filterValue: selectedValue,
      key: selectedKey,
      fn: filterFn,
      showChip: true,
    });
  };

  return (
    <SwitchFilter
      options={[
        { label: 'Has memo', key: 'memo' },
        { label: 'Has receipt', key: 'receipt' },
      ]}
      value={{
        memo: getFilterByKey('memo')?.filterValue,
        receipt: getFilterByKey('receipt')?.filterValue,
      }}
      onOptionChange={onOptionChange}
    />
  );
};

export const CreditTransactionsAttachmentsFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditAttachmentsFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsAttachmentsFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditAttachmentsFilter filterHook={filterHook} />;
};
