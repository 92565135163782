import { Box, Button, Flex, Group, Text } from '@mantine/core';
import { useStyles } from './edit-recipient.styles';
import { useForm } from '@mantine/form';
import FlexbaseInput from '@common/input/flexbase-input';
import { validateRequired } from '@utilities/validators';
import { Recipient } from 'types/recipient';
import { useUpdateRecipient } from '@queries/use-recipients';
import { showNotification } from '@mantine/notifications';
import { RightContentModal } from '@common/modal/right-content.modal';

type Props = {
  closeModal: () => void;
  recipient: Recipient;
};

type RecipientData = {
  name: string;
  contactName: string;
  email: string;
};

const EditRecipient = ({ closeModal, recipient }: Props) => {
  const { classes } = useStyles();
  const { mutate: updateRecipient, isPending: isPendingUpdate } =
    useUpdateRecipient();

  const form = useForm({
    initialValues: {
      name: recipient.name ?? '',
      contactName: recipient.contactName ?? '',
      email: recipient.email ?? '',
    },
    validate: {
      name: (value) =>
        validateRequired(value) ? null : 'Please enter a name.',
      contactName: (value) => {
        return validateRequired(value) ? null : 'Please enter a contact name.';
      },
      email: (value) => {
        return validateRequired(value) ? null : 'Please enter an email.';
      },
    },
  });

  const editRecipient = async (recipientData: any) => {
    const validationResult = form.validate();

    if (validationResult.hasErrors) {
      return null;
    } else {
      try {
        updateRecipient(
          { id: recipient.id, params: { ...recipientData } },
          {
            onSuccess: () => {
              showNotification({
                color: 'flexbase-teal',
                message: 'Successfully updated recipient',
              });
              closeModal();
            },
            onError: () => {
              showNotification({
                color: 'red',
                message: 'Unable to update recipient',
              });
            },
          },
        );
      } catch (e) {
        return null;
      }
    }
  };

  const handleSubmit = (values: RecipientData) => {
    editRecipient(values);
  };

  return (
    <RightContentModal>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <RightContentModal.SingleTitleHeader
          title={<Text className={classes.title}>Edit recipient</Text>}
        />

        <Flex justify="space-between" direction="column">
          <Box p="xl">
            <FlexbaseInput
              label={<Text>Name</Text>}
              {...form.getInputProps('name')}
              placeholder="Enter name"
            />
            <Flex justify="space-between" mt="md">
              <FlexbaseInput
                mt="md"
                w="48%"
                label={'Contact name'}
                placeholder="Enter contact name"
                {...form.getInputProps('contactName')}
              />
              <FlexbaseInput
                mt="md"
                w="48%"
                label={'Email'}
                placeholder="Enter email"
                {...form.getInputProps('email')}
              />
            </Flex>
          </Box>

          <Box>
            <Box className={classes.containerButton}>
              <hr />
              <Group justify="space-between" p="1rem 2rem">
                <Button
                  variant="outline"
                  onClick={() => {
                    closeModal();
                  }}
                  bg="neutral.0"
                >
                  Cancel
                </Button>
                <Button loading={isPendingUpdate} type="submit">
                  Edit Recipient
                </Button>
              </Group>
            </Box>
          </Box>
        </Flex>
      </form>
    </RightContentModal>
  );
};

export default EditRecipient;
