import {
  Avatar,
  Badge,
  Box,
  Flex,
  rem,
  Text,
  useMantineTheme,
} from '@mantine/core';
import AccountBox from 'components/account-box/account-box';
import FlexbaseSelect from 'components/select/flexbase-select';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useIntlPaymentsFeatureFlag } from 'utilities/feature-flags';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import PaymentStep from 'areas/payments/components/payment-step';
import BeneficiaryRequirements from 'areas/payments/components/send-payment/international-payments/beneficiary-requirements';
import InternationalPaymentAccountForm from 'areas/payments/components/send-payment/international-payments/international-payment-account-form';
import {
  BeneficiaryRequirementsFormRef,
  InternationalPaymentRecipientFormRef,
  InternationalRecipient,
  InternationalRecipientForm,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import PaymentAccountForm, {
  PaymentRecipientFormRef,
} from 'areas/payments/components/send-payment/payment-account-form';
import { useStyles } from './payment-method.styles';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import FlagIcon from 'areas/payments/components/send-payment/flag-icon';
import { ParsedAccount } from 'types/parsed-account';
import { Recipient as NewRecipientType } from 'types/recipient';
import { ACH_COPY, WIRE_COPY } from 'constants/constants';
import { PayMethod, Recipient } from 'types/payments';
import {
  counterpartyInitial,
  recipientInitial,
} from 'areas/payments/components/constants';
import {
  supportedCurrenciesData,
  isInternationalPayMethod,
  isInternationalWire,
} from '@utilities/international-payments';
import { PiVault } from 'react-icons/pi';

type PayMethodOption = {
  value: PayMethod;
  label: string;
};

const paymentMethodOptions: PayMethodOption[] = [
  { value: 'ach', label: 'Domestic ACH' },
  { value: 'wire', label: 'Domestic Wire' },
  { value: 'book', label: 'Internal transfer' },
  { value: 'internationalWireUSD', label: 'International wires - USD' },
  {
    value: 'internationalWire',
    label: 'International wires - Foreign exchange',
  },
];

export const Icon = ({
  method,
  flag,
}: {
  method: PayMethod;
  flag?: string;
}) => {
  const theme = useMantineTheme();
  return (
    <Avatar
      sx={() => ({
        border: `1px solid ${theme.colors.neutral[1]}`,
      })}
    >
      {isInternationalPayMethod(method) && flag ? (
        <FlagIcon flagNationCode={flag} width={rem(22)} />
      ) : (
        <Flex
          justify="center"
          align="center"
          style={{
            borderRadius: '100%',
            background: theme.colors.primarySecondarySuccess[0],
            width: '89%',
            height: '89%',
          }}
        >
          <PiVault
            size={'1.188rem'}
            color={theme.colors.primarySecondarySuccess[2]}
          />
        </Flex>
      )}
    </Avatar>
  );
};

export const SubheaderText = ({
  method,
  currency,
  accountNumber,
  routingNumber,
  currencySymbol,
  paymentType,
}: {
  method: PayMethod;
  currency?: string;
  accountNumber: string;
  routingNumber?: string;
  currencySymbol?: string;
  paymentType?: string;
}) => {
  const conditionalRouterText = routingNumber
    ? `Routing ${GetPaddedAccountMask(routingNumber, 2)}`
    : '';
  const conditionalAccountNumberText = accountNumber
    ? `Account ${GetPaddedAccountMask(accountNumber, 2)}`
    : '';

  return (
    <Flex justify="space-between" gap="xs">
      <Text>{conditionalAccountNumberText}</Text>
      {isInternationalPayMethod(method) && currency !== 'USD' ? (
        <Badge bg="pink">
          {currencySymbol}
          {currency}
        </Badge>
      ) : (
        <Text>
          {conditionalRouterText} {paymentType}
        </Text>
      )}
    </Flex>
  );
};

type Props = {
  accountDetailsUrlParam: string | null;
  selectedRecipient?: NewRecipientType;
  internationalAccountForm: InternationalRecipientForm;
  newRecipient: Recipient | InternationalRecipient;
  onNextClick: (recipient?: Recipient | InternationalRecipient) => void;
  onBackClick: () => void;
  recipientName: string;
  setFilter: (value: string) => void;
  defaultMethod?: PayMethod;
  method: PayMethod;
  setMethod: (value: PayMethod) => void;
  setRecipient: (value: Recipient | InternationalRecipient) => void;
  isNewRecipient: boolean;
  setIsNewRecipient: (value: boolean) => void;
  accountDetails: ParsedAccount;
  setAccountDetails: (value: ParsedAccount) => void;
  footer?: ReactNode;
  isPending: boolean;
  filterAccounts: () => ParsedAccount[];
};

const PaymentMethod = ({
  accountDetailsUrlParam,
  selectedRecipient,
  internationalAccountForm,
  newRecipient,
  onNextClick,
  onBackClick,
  recipientName,
  setFilter,
  defaultMethod,
  method,
  setMethod,
  setRecipient,
  isNewRecipient,
  setIsNewRecipient,
  accountDetails,
  setAccountDetails,
  footer,
  isPending,
  filterAccounts,
}: Props) => {
  const { classes, theme } = useStyles();
  const formRef = useRef<PaymentRecipientFormRef>(null);
  const internationalPaymentRecipientFormRef =
    useRef<InternationalPaymentRecipientFormRef>(null);
  const beneficiaryRequirementsFormRef =
    useRef<BeneficiaryRequirementsFormRef>(null);

  const filteredAccounts = filterAccounts();
  const hasAtLeastOneFilteredRecipient = filteredAccounts.length > 0;
  const hasMoreThanOneFilteredRecipient = filteredAccounts.length > 1;
  const isBookAccountType = accountDetails?.type === 'book';

  const [showSelect, setShowSelect] = useState(false);

  const derivedHeaderText = (c: ParsedAccount) =>
    c?.nickName || c?.bank || c?.name;

  const getCurrencySymbol = (currency: string) =>
    supportedCurrenciesData.find((cc) => cc.value === currency)?.symbol;

  const { productStatus } = useRecoilValue(ApplicationState);

  const hasActiveIntnlPayments =
    productStatus.internationalPayments.status === 'approved';

  const validateAndSubmitInternationalForms = () => {
    const initialFormRes =
      internationalPaymentRecipientFormRef?.current?.submitForm();
    const dynamicFormRes =
      beneficiaryRequirementsFormRef?.current?.submitForm();

    if (initialFormRes && dynamicFormRes) {
      if (initialFormRes.recipientType === 'company') {
        delete dynamicFormRes.firstName;
        delete dynamicFormRes.lastName;
        delete dynamicFormRes.dob;
      } else if (initialFormRes.recipientType === 'individual') {
        delete dynamicFormRes.companyName;
      }

      const newInternationalRecipient = {
        ...initialFormRes,
        ...dynamicFormRes,
      };

      setRecipient(newInternationalRecipient);
      onNextClick(newInternationalRecipient);
    }
  };

  const onNext = async () => {
    // if adding a new counterparty, add form will be shown.
    // submit the form results
    if (!hasAtLeastOneFilteredRecipient) {
      if (isInternationalPayMethod(method)) {
        validateAndSubmitInternationalForms();
        return;
      }

      const res = await formRef?.current?.submitForm(); // response is null if validation fails
      if (res) {
        setRecipient(res);
        onNextClick(res);
      }
    } else {
      // move to the next step
      onNextClick();
    }
  };

  const onBack = () => {
    if (isNewRecipient && !hasAtLeastOneFilteredRecipient) {
      setIsNewRecipient(false);
      setFilter('active_recipient_method');
    }
    onBackClick();
  };

  const handleNewRecipient = () => {
    setFilter('new_recipient');
    setAccountDetails(counterpartyInitial);
  };

  const handleAccountDetails = () => {
    if (
      accountDetails &&
      filteredAccounts.some((item) => item.id === accountDetails.id)
    ) {
      const result = filteredAccounts.find(
        (item) => item.id === accountDetails.id,
      );
      if (result) {
        setAccountDetails(result);
        setMethod(result.type);
      }
    } else if (hasAtLeastOneFilteredRecipient) {
      setAccountDetails(filteredAccounts[0]);
    } else {
      setAccountDetails(counterpartyInitial);
    }
  };

  useEffect(() => {
    if (defaultMethod) {
      setMethod(defaultMethod);
    }
  }, []);

  useEffect(() => {
    if (isNewRecipient) {
      handleNewRecipient();
    } else if (!accountDetailsUrlParam) {
      handleAccountDetails();
    }
  }, [method, isNewRecipient, hasAtLeastOneFilteredRecipient]);

  const intlPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();

  const availableOptions = paymentMethodOptions.filter((option) => {
    if (isInternationalPayMethod(option.value)) {
      return intlPaymentsFeatureFlagIsOn && hasActiveIntnlPayments;
    } else if (option.value === 'book') {
      return !!filteredAccounts.filter((recipient) => recipient.type === 'book')
        .length;
    }
    return true;
  });

  const handleMethodSelect = (value: string | null) => {
    setMethod(value as PayMethod);

    const commonValues = {
      name: recipientName,
      recipientType: selectedRecipient?.type ?? 'individual',
    };

    if (!isInternationalWire(value as PayMethod)) {
      internationalAccountForm.setValues({
        ...internationalAccountForm.values,
        ...commonValues,
        currency: 'USD',
      });
    } else {
      internationalAccountForm.setValues({
        ...commonValues,
      });
    }
  };

  const handleSendToDifferentAccount = () => {
    setIsNewRecipient(true);
    handleMethodSelect(method);
    setRecipient(recipientInitial);
  };

  return (
    <PaymentStep
      titleText={
        isNewRecipient
          ? `Complete payment details for ${recipientName}`
          : `Payment details for ${recipientName}`
      }
      onNextClick={onNext}
      onBackClick={onBack}
      footer={footer}
      loading={isPending}
    >
      <Text size="sm" my="md" fw="500">
        Payment information
      </Text>

      <FlexbaseSelect
        value={method}
        data={availableOptions}
        id="method"
        inputProps={{ id: 'method' }}
        label="Method"
        onChange={handleMethodSelect}
      />
      {method === 'ach' ? (
        <Text color={theme.colors.neutral[7]} fz={rem(12)} mt={rem(4)}>
          {ACH_COPY}
        </Text>
      ) : null}
      {method === 'wire' && (
        <Text color={theme.colors.neutral[7]} fz={rem(12)} mt={rem(4)}>
          {WIRE_COPY}
        </Text>
      )}

      {!hasAtLeastOneFilteredRecipient &&
        (isInternationalPayMethod(method) ? (
          <>
            <InternationalPaymentAccountForm
              ref={internationalPaymentRecipientFormRef}
              form={internationalAccountForm}
              method={method}
              isNewRecipient={isNewRecipient}
            />
            <BeneficiaryRequirements
              formValues={internationalAccountForm.values}
              ref={beneficiaryRequirementsFormRef}
              newRecipient={newRecipient as InternationalRecipient}
            />
          </>
        ) : (
          <PaymentAccountForm
            ref={formRef}
            method={method}
            isNewRecipient={isNewRecipient}
            recipientName={recipientName}
            selectedRecipient={selectedRecipient}
          />
        ))}

      {!isNewRecipient && hasAtLeastOneFilteredRecipient ? (
        <Box mt={rem(16)} mb={rem(16)}>
          <div className={classes.optionInfoContainer}>
            Recipient bank details
          </div>
          <AccountBox
            headerText={derivedHeaderText(accountDetails)}
            subheaderText={
              accountDetails &&
              !isBookAccountType && (
                <SubheaderText
                  method={method}
                  currency={accountDetails.currency}
                  accountNumber={accountDetails.accountNumber}
                  routingNumber={accountDetails?.routingNumber}
                  currencySymbol={
                    accountDetails.currency &&
                    getCurrencySymbol(accountDetails.currency)
                  }
                />
              )
            }
            onClick={() =>
              setShowSelect((prev) => {
                if (hasMoreThanOneFilteredRecipient) {
                  return !prev;
                }
                return prev;
              })
            }
            classNames={{ containerClassName: classes.containerIcon }}
            showArrow={hasMoreThanOneFilteredRecipient}
            rotateArrow={showSelect}
            showBorder
            isListItem={false}
            icon={<Icon method={method} flag={accountDetails?.country} />}
          />
          {showSelect && hasAtLeastOneFilteredRecipient ? (
            <div className={classes.selectList}>
              {filteredAccounts
                .filter((c) => c.id !== accountDetails.id)
                .map((c) => (
                  <AccountBox
                    headerText={derivedHeaderText(c)}
                    subheaderText={
                      !isBookAccountType && (
                        <SubheaderText
                          method={method}
                          currency={c.currency}
                          accountNumber={c.accountNumber}
                          routingNumber={c.routingNumber}
                          currencySymbol={
                            c.currency && getCurrencySymbol(c.currency)
                          }
                        />
                      )
                    }
                    onClick={() => {
                      setAccountDetails(c);
                      setShowSelect(false);
                    }}
                    classNames={{
                      containerClassName: classes.containerIcon,
                    }}
                    showArrow={false}
                    rotateArrow={false}
                    isListItem={true}
                    icon={<Icon method={method} flag={c?.country} />}
                    key={c.id}
                    data-testid={`accounts-list-${c.name}`}
                  />
                ))}
            </div>
          ) : null}
          <div
            className={classes.newAccount}
            onClick={handleSendToDifferentAccount}
          >
            Send to a different account
          </div>
        </Box>
      ) : null}
    </PaymentStep>
  );
};

export default PaymentMethod;
