import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { CreditTransactionsTableRow } from 'areas/credit/components/credit-transactions/credit-transactions-table-helpers';
import { atom } from 'recoil';

const ChargeCardTransactionsFilterState = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'charge_card_transactions_filter_state',
  default: {},
});

const ChargeCardTransactionsFilterStateModal = atom<
  FilterFnMap<CreditTransactionsTableRow>
>({
  key: 'charge_card_transactions_filter_state_modal',
  default: {},
});

export function useChargeCardTransactionFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    ChargeCardTransactionsFilterState,
  );
}

export function useChargeCardTransactionModalFilters(): ActiveFiltersReturnType<CreditTransactionsTableRow> {
  return createUseFiltersHook<CreditTransactionsTableRow>(
    ChargeCardTransactionsFilterStateModal,
  );
}
