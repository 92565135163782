import { useEffect, useMemo, useState } from 'react';
import { formatCurrency } from '@utilities/formatters';
import { useStyles } from './credit-transactions.styles';
import FlexbaseTable from 'components/table/flexbase-table';
import { Analytics } from '@services/analytics/analytics';
import { downloadCSV } from '@utilities/file-io/csv/csv-export';
import { CreditTransactionsTableHeader } from 'areas/credit/components/credit-transactions/credit-transactions-header/credit-transactions-table-header';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import {
  creditSorter,
  CreditTransactionsTableRow,
  formatTableDataForCsv,
} from 'areas/credit/components/credit-transactions/credit-transactions-table-helpers';
import {
  mapCreditTransactionToTableRow,
  useCreditTransactionsTableColumns,
  useOpenCreditTransactionDetails,
} from 'areas/credit/components/credit-transactions/credit-transactions-columns';
import { showNotification } from '@mantine/notifications';
import { mapTransactionStatusToBusinessStatus } from '@services/flexbase/flexbase-onboarding-client';
import {
  SyncTransactionPayload,
  useExpenseCategorySyncState,
} from './credit-transactions.reducer';
import { DateTime } from 'luxon';
import {
  Expenses,
  ExpensesStatusEnum,
} from '@flexbase-eng/types/dist/accounting';
import { useSyncTransactionExpenses } from '@utilities/custom-hooks/use-sync-transaction-expenses';
import { useCreditTransactionsBulkActions } from 'areas/credit/components/credit-transactions/utils/use-credit-transactions-bulk-actions';
import { useDisclosure } from '@mantine/hooks';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';
import { useSearchParams } from 'react-router-dom';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { ActiveFiltersReturnType, FilterData } from '@common/filter/filters';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { UseQueryResult } from '@tanstack/react-query';
import { CreditTransactionsOverviewModel } from '@queries/use-credit-transactions';

type SyncTransactionsResult = {
  success: boolean;
  error?: Error;
  data: { transactionId: string; success: boolean }[];
};

const POLL_FOR_SYNC_UPDATES_INTERVAL = 1000 * 5;

/**
 * Returns true if we need to poll for sync updates for this expense.
 */
const isWaitingForUpdates = (expense: Expenses) =>
  [ExpensesStatusEnum.Queued, ExpensesStatusEnum.Failure].includes(
    expense.status,
  );

export const CreditTransactions = ({
  lineOfCredit,
  dataQuery,
  filterHook,
  modalFilterHook,
}: {
  lineOfCredit: LineOfCredit;
  dataQuery: UseQueryResult<CreditTransactionsOverviewModel>;
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
  modalFilterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
}) => {
  const { classes } = useStyles();
  const [
    expenseCategorySyncState,
    {
      removeSyncState,
      removeAndResetAllSyncStatus,
      updateSyncCategory,
      updateSyncStatus,
      resetSyncStatusAll,
    },
  ] = useExpenseCategorySyncState();
  const [isSyncingMany, setIsSyncingMany] = useState(false);
  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const { addFilter, removeFilter, activeFiltersArray } = filterHook;
  const [searchParams] = useSearchParams();
  const last4CardNumber = searchParams.get('card');

  const {
    isLoading,
    isSuccess,
    data: creditTransactionsData,
    refetch,
  } = dataQuery;

  const filterCardTransactions = (last4: string) => {
    const applyCardsFilter: FilterData<CreditTransactionsTableRow> = {
      key: 'cards',
      filterValue: [last4],
      fn: (row) => !!row.sourceLast4 && last4.includes(row.sourceLast4),
      label: `Cards: ${getPaddedAccountMask(last4, 2)}`,
      showChip: true,
    };
    removeFilter('date');
    addFilter('cards', applyCardsFilter);
  };

  // Set the initial date filter to `Last week`
  // TODO: this is kinda fragile and manually copies logic from the credit date filter code
  // Leaving this useEffect for now until there is a better way to set defaults
  useEffect(() => {
    if (last4CardNumber) {
      filterCardTransactions(last4CardNumber);
    } else {
      const endDate = DateTime.now();
      const endString = endDate.toLocaleString(DateTime.DATE_SHORT);
      const startDate = endDate.minus({ days: 30 });
      const startString = startDate.toLocaleString(DateTime.DATE_SHORT);
      const option = 'Last 30 days';

      const filterData = {
        key: 'date',
        fn: () => true,
        label: `${startString} - ${endString}`,
        filterValue: { startDate, endDate, option },
        showChip: true,
      };

      removeFilter('cards');
      addFilter('date', filterData);
    }
  }, [last4CardNumber]);

  const syncedTransactionsQueryIds = useMemo(
    () =>
      creditTransactionsData?.transactions
        .filter((tx) => mapTransactionStatusToBusinessStatus(tx) === 'Settled')
        .map((tx) => tx.id) ?? [],
    [creditTransactionsData],
  );

  // Fetch sync data for all tx.
  const expenseSyncEnabled =
    isSyncExpenseAllowed && !!expenseLink?.enabledExpenses;

  const {
    data: { syncedTransactions, syncingTransactionIds } = {},
    refetch: refetchSyncedTransactions,
  } = useSyncedTransactionsQuery({
    enabled: expenseSyncEnabled,
    connectionId,
    transactionIds: syncedTransactionsQueryIds,
    select: (data) => ({
      syncedTransactions: data,
      syncingTransactionIds: data.expenses
        .filter(isWaitingForUpdates)
        .map((tx) => tx.transactionId),
    }),
  });

  // Poll for sync updates for any tx that are waiting for updates
  const expenseSyncPollEnabled =
    expenseSyncEnabled && !!syncingTransactionIds?.length;

  useSyncedTransactionsQuery({
    enabled: expenseSyncPollEnabled,
    connectionId,
    transactionIds: syncingTransactionIds ?? [],
    refetchInterval: ({ state }) => {
      const hasIncomplete = !!state.data?.expenses.some(isWaitingForUpdates);
      return hasIncomplete ? POLL_FOR_SYNC_UPDATES_INTERVAL : false;
    },
    updateCache: true,
  });

  const { syncTransactions } = useSyncTransactionExpenses({
    connectionId,
    syncedExpenses: syncedTransactions,
  });

  const filteredItems = useMemo<CreditTransactionsTableRow[]>(() => {
    if (!isSuccess) {
      return [];
    }

    const asMap =
      syncedTransactions?.expenses.reduce<Partial<Record<string, Expenses>>>(
        (acc, item) => {
          acc[item.transactionId] = item;
          return acc;
        },
        {},
      ) ?? {};

    return creditTransactionsData.transactions
      .map((transaction, index) => {
        const txSyncData = asMap[transaction.id];
        const txCategoryChange = expenseCategorySyncState[transaction.id];
        return mapCreditTransactionToTableRow(
          transaction,
          index,
          {
            syncedExpense: txSyncData,
            expenseCategories: syncedTransactions?.accounts?.items,
          },
          lineOfCredit,
          txCategoryChange,
        );
      })
      .filter((t) => activeFiltersArray.every((f) => f.fn(t)));
  }, [
    creditTransactionsData,
    syncedTransactions,
    expenseCategorySyncState,
    activeFiltersArray,
  ]);

  useEffect(() => {
    // Table doesn't update the selection if data changes
    // so just clear the selection
    clearSelectedRows();
  }, [filteredItems]);

  const openTransactionDetails = useOpenCreditTransactionDetails(refetch);

  const handleSyncSingleClick = async (txId: string) => {
    const existingSyncData = syncedTransactions?.expenses.find(
      (e) => e.transactionId === txId,
    );

    // this shouldn't happen
    if (!existingSyncData) {
      console.error(`Transaction ${txId} is unable to be synced.`);
      return;
    }

    const txCategoryChange = expenseCategorySyncState[txId];

    if (!txCategoryChange?.data) {
      if (existingSyncData.expense?.account) {
        showNotification({
          title: 'Info',
          message: 'This transaction is already synced',
        });
      } else {
        showNotification({
          title: 'Info',
          message: `Please choose ${syncedTransactions?.accounts?.type} first`,
        });
      }
      return;
    }

    await handleSyncTransactions([txCategoryChange.data]);
  };

  const handleTransactionAccountChange = async (
    transactionId: string,
    categoryId: string | null,
  ) => {
    if (!categoryId) {
      return;
    }

    const syncedCategoryId = syncedTransactions?.expenses.find(
      (tx) => tx.transactionId === transactionId,
    )?.expense?.account?.id;

    const changeUndone = syncedCategoryId === categoryId;

    if (changeUndone) {
      removeSyncState(transactionId);
      return;
    }

    updateSyncCategory(transactionId, categoryId);

    await handleSyncTransactions([{ transactionId, accountId: categoryId }]);
  };

  const columns = useCreditTransactionsTableColumns({
    syncFeatureEnabled: isSyncExpenseAllowed && !!expenseLink?.enabledExpenses,
    onTransactionUpdate: () => refetch(),
    onTransactionSync: handleSyncSingleClick,
    onTransactionAccountChange: handleTransactionAccountChange,
    syncedTransactions,
    expenseLink,
    lineOfCredit,
  });

  const { bulkActionsConfig } = useCreditTransactionsBulkActions({
    expenseLink,
    syncLoading: isSyncingMany,
    syncedExpenses: syncedTransactions,
    onBulkCategorizeConfirm: ({ transactionsToSync, options }) => {
      return handleSyncTransactions(transactionsToSync, options);
    },
    onDownloadCsvClick: (state) => {
      return handleDownloadCsvClick(state.selectedRows);
    },
  });

  const handleDownloadCsvClick = (
    selectedRows?: CreditTransactionsTableRow[],
  ) => {
    Analytics.track('Credit Downloaded Transactions to CSV');
    const csvData = formatTableDataForCsv(
      selectedRows?.length ? selectedRows : filteredItems,
    );
    if (!selectedRows?.length) {
      const isUnit = lineOfCredit === 'unit';
      const beginningBalance = isUnit
        ? creditTransactionsData?.beginningChargeCardBalance
        : creditTransactionsData?.beginningBalance;
      const endingBalance = isUnit
        ? creditTransactionsData?.endingChargeCardBalance
        : creditTransactionsData?.endingBalance;

      csvData.push({
        custom: true,
        beginningBalance: formatCurrency(beginningBalance || 0),
      });
      csvData.push({
        custom: true,
        endingBalance: formatCurrency(endingBalance || 0),
      });
    } else {
      // selected rows comes out in filo order, so we need to reverse it
      csvData.reverse();
    }
    downloadCSV({ data: csvData, fileName: 'Transactions' });
  };

  const handleSyncTransactions = async (
    transactionsToSync: SyncTransactionPayload[],
    options?: {
      successMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
      partialSuccessMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
    },
  ) => {
    try {
      updateSyncStatus(
        transactionsToSync.map((tx) => tx.transactionId),
        'loading',
      );
      setIsSyncingMany(true);

      const syncResults = await syncTransactions(transactionsToSync, options);

      if (
        syncResults.length &&
        syncResults.every((result) => !result.success)
      ) {
        return resetSyncStatusAll();
      }

      await refetchSyncedTransactions();

      const successfulTxIds = syncResults
        .flatMap((result) => result.data)
        .filter((tx) => tx.success)
        .map((tx) => tx.transactionId);

      removeAndResetAllSyncStatus(successfulTxIds);
    } finally {
      setIsSyncingMany(false);
    }
  };

  return (
    <div className={classes.baseContainer}>
      <div className={classes.widgetContainer}>
        <CreditTransactionsTableHeader
          creditTransactionsData={creditTransactionsData}
          lineOfCredit={lineOfCredit}
          onDownloadCSVClick={handleDownloadCsvClick}
          filterHook={filterHook}
          modalFilterHook={modalFilterHook}
        />
        <FlexbaseTable
          keyField={'keyField'}
          columns={columns}
          data={filteredItems}
          sortFunction={creditSorter}
          selectableRows
          clearSelectedRows={clearSelectedRowsFlag}
          pagination={filteredItems.length > 8}
          onRowClicked={(row) => openTransactionDetails(row)}
          isFetchingData={isLoading}
          bulkActionsConfig={bulkActionsConfig}
        />
      </div>
    </div>
  );
};
