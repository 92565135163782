import { Box, Flex, Text, Divider, UnstyledButton, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { PiArrowDownBold, PiArrowUpBold } from 'react-icons/pi';
import useModal from '@common/modal/modal-hook';
import EmptyState from '../components/money-direction/empty-state';
import { formatCurrency } from '@utilities/formatters';
import { extractToFrom } from '@utilities/formatters/banking-transactions-utilities';
import { AccountTransaction } from '../components/transactions-summary/transactions-summary';
import { filterByDirection } from '@utilities/filter/filter-transactions-by-direction';

type MoneyMovementSummaryProps = {
  ModalContent: JSX.Element;
  transactions: AccountTransaction[];
  isReserveAccount: boolean;
};

export type MoneyMovementItemsProps = {
  transactions: AccountTransaction[];
  direction: 'Credit' | 'Debit';
};

const MoneyMovementItems = ({
  transactions,
  direction,
}: MoneyMovementItemsProps) => {
  const twoLatestTransactions = transactions.slice(0, 2) ?? [];

  if (!transactions.length) {
    return <EmptyState {...{ direction }} />;
  }

  return (
    <>
      {twoLatestTransactions.map((transaction) => (
        <Flex key={transaction.id} justify="space-between" c="neutral.7">
          <Text>{extractToFrom(transaction.summary)}</Text>
          <Text ta="right">
            {direction === 'Debit' && '-'}
            {formatCurrency(transaction.amount)}
          </Text>
        </Flex>
      ))}
    </>
  );
};

const MoneyMovementSummary = ({
  transactions,
  ModalContent,
  isReserveAccount,
}: MoneyMovementSummaryProps) => {
  const { openRightModal } = useModal();
  const { classes } = moneyMovementSummaryStyles();
  const creditTransactionTotal = filterByDirection(transactions, 'Credit');
  const debitTransactionTotal = filterByDirection(transactions, 'Debit');

  const openModal = () => {
    openRightModal(ModalContent);
  };

  return (
    <>
      <Box>
        <Flex align="center" gap="xs">
          <Box
            bg="positive.0"
            c="primarySecondarySuccess.2"
            className={classes.iconContainer}
          >
            <PiArrowUpBold size={'0.75rem'} />
          </Box>
          <Text>Money in</Text>
        </Flex>
        <Divider my="md" />
        <MoneyMovementItems
          transactions={creditTransactionTotal}
          direction="Credit"
        />
      </Box>

      {!isReserveAccount && (
        <Box mt="xxl">
          <Flex align="center" gap="xs">
            <Box
              className={classes.iconContainer}
              bg="critical.0"
              c="critical.4"
            >
              <PiArrowDownBold size={'0.75rem'} />
            </Box>
            <Text>Money out</Text>
          </Flex>
          <Divider my="md" />
          <MoneyMovementItems
            transactions={debitTransactionTotal}
            direction="Debit"
          />
        </Box>
      )}

      <UnstyledButton
        mt="sm"
        onClick={openModal}
        c="primarySecondarySuccess.4"
        className={classes.viewAllButton}
      >
        View all
      </UnstyledButton>
    </>
  );
};

export default MoneyMovementSummary;

export const moneyMovementSummaryStyles = createStyles(() => ({
  iconContainer: {
    width: rem(16),
    height: rem(16),
    display: 'flex',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewAllButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
  },
}));
