import {
  Flex,
  Loader,
  Center,
  UnstyledButton,
  rem,
  Box,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';
import { PlaidAccount, DepositAccount } from '../../../types/move-funds.model';
import { useStyles } from '../styles';
import TransferReview from './step-2-transfer-review';
import TransferDetails from './step-1-transfer-details';
import { Analytics } from 'services/analytics/analytics';
import TransferError from '../components/modal-error/modal-error';
import { formatCurrency } from 'utilities/formatters';
import { PaymentForm, paymentFormInitial } from 'types/payments';
import { useMakePayment } from '@queries/use-payments';
import { useKeyPress } from 'utilities/custom-hooks';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import LinkAccountStep from './link-account-step';
import { AUTHORIZATIONS } from 'constants/constants';
import { PiXLight } from 'react-icons/pi';
import { RightContentModal } from '@common/modal/right-content.modal';
import useModal from '@common/modal/modal-hook';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'types/user-info';
import { showNotification } from '@mantine/notifications';

export enum MoveFundsSteps {
  TRANSFER_DETAILS,
  TRANSFER_REVIEW,
  LINK_ACCOUNT,
  SUCCESS,
  ERROR,
}

const MoveFunds = () => {
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const user = useRecoilValue(UserInfoState);
  const modal = useModal();

  // get account from location state
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');

  // move funds states
  const paymentRequest = useMakePayment();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(MoveFundsSteps.TRANSFER_DETAILS);
  const {
    data: plaidAccountsData,
    isLoading: isPlaidAccountsLoading,
    isError: isPlaidAccountsError,
    refetch: plaidAccountsRefetch,
  } = useExternalAccounts();
  const {
    data: depositAccountsData,
    isLoading: isDepositAccountsLoading,
    isError: isDepositAccountsError,
    refetch: depositAccountsRefecth,
  } = useGetDepositAccounts();

  const bankingAccounts =
    depositAccountsData?.accounts
      ?.filter((acc) => acc.status === 'Open')
      .map((account) => {
        return { ...account, plaidOrDeposit: 'deposit' } as DepositAccount;
      })
      .sort((a, b) => b.available - a.available) ?? [];

  const initDepositAccount = bankingAccounts.find(
    (acc) => acc.id === accountId,
  );
  const plaidAccounts = plaidAccountsData.filter((acc) => acc.active);
  const needsLinkAccount =
    bankingAccounts.length === 1 && !plaidAccounts.length;

  const [transferForm, setTransferForm] =
    useState<PaymentForm>(paymentFormInitial);

  const isLoading = isPlaidAccountsLoading || isDepositAccountsLoading;
  const isError = isPlaidAccountsError || isDepositAccountsError;

  useEffect(() => {
    if (needsLinkAccount) {
      setStep(MoveFundsSteps.LINK_ACCOUNT);
    }
  }, [needsLinkAccount]);

  const closeModal = () => {
    modal.closeAllModals();
  };

  useKeyPress('Escape', closeModal);

  const makePayment = async () => {
    try {
      setLoading(true);
      const transferFormToAccount = transferForm.toAccount as
        | PlaidAccount
        | DepositAccount
        | undefined;

      let payment: any;

      if (transferForm.fromAccount?.plaidOrDeposit === 'plaid') {
        // fund banking account with plaid external account
        payment = await paymentRequest.mutateAsync({
          type: 'ach',
          direction: 'Debit',
          description: `ACHIn ${transferForm.fromAccount.last4}`,
          accountId: transferFormToAccount?.id ?? '',
          plaidTokenId: transferForm.fromAccount?.id,
          amount: formatCurrency(transferForm.amount),
          authorizations: [AUTHORIZATIONS.achDebit],
        });
      } else if (
        transferForm.fromAccount?.plaidOrDeposit === 'deposit' &&
        transferForm.toAccount?.plaidOrDeposit === 'deposit'
      ) {
        // book payment, flexbase banking account to account
        payment = await paymentRequest.mutateAsync({
          type: 'book',
          description: 'Account to account transfer',
          direction: 'Credit',
          accountIdTo: transferForm.toAccount?.id,
          accountId: transferForm.fromAccount?.id,
          amount: formatCurrency(transferForm.amount),
        });
      } else if (
        !!transferFormToAccount?.id &&
        transferForm.fromAccount?.plaidOrDeposit === 'deposit'
      ) {
        // move money from flexbase banking to plaid external account
        payment = await paymentRequest.mutateAsync({
          type: 'ach',
          direction: 'Credit',
          description: 'Disburse',
          accountId: transferForm.fromAccount.id,
          plaidTokenId: transferFormToAccount?.id,
          amount: formatCurrency(transferForm.amount),
          authorizations: [AUTHORIZATIONS.default],
        });
      }

      if (payment.status === 'Rejected' && payment.reason) {
        setTransferForm({
          ...transferForm,
          status: payment.status,
          reason: payment.reason,
        });
        setError(payment.reason);
        setStep(MoveFundsSteps.ERROR);
      }

      if (payment?.id) {
        setTransferForm({
          ...transferForm,
          status: payment?.status,
          reason: payment?.reason,
          id: payment?.id,
        });

        showNotification({
          color: 'flexbase-teal',
          title: 'Success',
          message: user.roles.includes('COMPTROLLER')
            ? `Your ${formatCurrency(transferForm.amount)} transfer to ${
                transferForm.toAccount?.plaidOrDeposit === 'deposit'
                  ? transferForm.toAccount.nickName
                  : transferForm.toAccount?.plaidOrDeposit === 'plaid' &&
                    transferForm.toAccount?.bankName
              } ${
                transferForm.toAccount?.plaidOrDeposit === 'deposit'
                  ? transferForm.toAccount?.accountNumber
                  : transferForm.toAccount?.account
              } is complete`
            : `Your ${formatCurrency(transferForm.amount)} transfer needs to be approved by another admin`,
        });

        closeModal();
      }
      Analytics.track('Banking Payment Submitted', { payment });
    } catch (err) {
      console.error('Unable to make the payment', err);

      setError('Unable to make the payment. Please, try it again');
      setStep(MoveFundsSteps.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccount = async () => {
    await depositAccountsRefecth();
    setStep(MoveFundsSteps.TRANSFER_DETAILS);
  };

  const handleLinkSuccess = async () => {
    await plaidAccountsRefetch();
    setStep(MoveFundsSteps.TRANSFER_DETAILS);
  };

  const handleLinkError = () => {
    setError(
      'Plaid linking is unavailable at this time, please try again later!',
    );
    setStep(MoveFundsSteps.ERROR);
  };

  // generate the contents of this flow based on step state
  const getContents = () => {
    switch (step) {
      case MoveFundsSteps.LINK_ACCOUNT:
        return (
          <LinkAccountStep
            onLinkError={handleLinkError}
            onLinkSuccess={handleLinkSuccess}
            onCreateAccount={handleCreateAccount}
          />
        );
      case MoveFundsSteps.TRANSFER_DETAILS:
        return (
          <TransferDetails
            form={transferForm}
            onBackClick={closeModal}
            onContinue={(values: PaymentForm) => {
              setTransferForm({ ...transferForm, ...values });
              setStep(MoveFundsSteps.TRANSFER_REVIEW);
            }}
            refetchPlaidAccounts={plaidAccountsRefetch}
            {...{
              initDepositAccount,
              bankingAccounts,
              plaidAccounts,
            }}
          />
        );

      case MoveFundsSteps.TRANSFER_REVIEW:
        return (
          <TransferReview
            form={transferForm}
            onContinue={makePayment}
            loading={loading}
            onBackClick={() => setStep(MoveFundsSteps.TRANSFER_DETAILS)}
          />
        );

      case MoveFundsSteps.ERROR:
        return <TransferError errorMessage={error} onGoBack={closeModal} />;
    }
  };

  if (isLoading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader
          color={theme.colors.primarySecondarySuccess[7]}
          size={rem(35)}
        />
      </Center>
    );
  }

  if (isError) {
    return (
      <Flex
        justify="center"
        align="center"
        h="100vh"
        bg={theme.colors.neutral[2]}
      >
        <TransferError
          errorMessage="We've encountered an error attempting to get the accounts list. Try again later."
          onGoBack={closeModal}
        />
      </Flex>
    );
  }

  if (isMobile) {
    return (
      <Box className={classes.styleTest}>
        <UnstyledButton
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: `${rem(15)} ${rem(15)} 0 0`,
          }}
          onClick={closeModal}
        >
          <PiXLight size={'2rem'} color={theme.colors.neutral[5]} />
        </UnstyledButton>
        <Text fw={500} ta="center">
          Transfer between accounts
        </Text>
        <Box className="content">{getContents()}</Box>
      </Box>
    );
  }

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={rem(30)}
        title={'Transfer between accounts'}
        rightTitle={
          <UnstyledButton c="neutral.0" onClick={closeModal}>
            <PiXLight size="2rem" />
          </UnstyledButton>
        }
      />
      <RightContentModal.Body>{getContents()}</RightContentModal.Body>
    </RightContentModal>
  );
};

export default MoveFunds;
