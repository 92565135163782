import { IconType } from 'react-icons';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { ReactElement, useState } from 'react';
import { Menu, Group, Button, Text, Box, Tooltip, rem } from '@mantine/core';
import useModal from 'components/modal/modal-hook';
import { useStyles } from '@common/charge-and-credit-cards/debit-card-details.styles';
import CancelDebitCard from '../cancel-debit-card/cancel-physical-card/cancel-debit-card';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import { CardByUser, CardStatus } from 'services/flexbase/banking.model';
import ActivateDebitCard from '../activate-debit-card/activate-debit-card';
import { UserInfoState } from 'types/user-info';
import { createSearchParams, useNavigate } from 'react-router-dom';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import {
  useGetPinStatus,
  useUpdateDebitCardStatus,
} from '@queries/use-debit-cards';
import CancelVirtualCard from '../cancel-debit-card/cancel-virtual-card/cancel-virtual-card';
import UpdatePIN from './update-pin';
import { FlexCard, cardIsChargeCard } from '@services/flexbase/card.model';
import SetDebitCardPIN from './set-pin';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import {
  PiCaretDownBold,
  PiListBulletsBold,
  PiPauseCircleBold,
  PiPlayFill,
} from 'react-icons/pi';

type Props = {
  card: CardByUser;
  cardStatus: string;
  setError: (x: boolean) => void;
  setEditCard: (x: boolean) => void;
  setCardStatus: (x: string) => void;
};

const DebitCardActions = ({
  card,
  setError,
  cardStatus,
  setEditCard,
  setCardStatus,
}: Props) => {
  const navigate = useNavigate();
  const { classes, cx, theme } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const userId = useRecoilValue(UserIdState);
  const user = useRecoilValue(UserInfoState);
  const unitcoCardId = card.ucCardId.substring(5);
  const [status, setStatus] = useState<CardStatus>();
  const { mutate, isPending } = useUpdateDebitCardStatus();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [updateCusToken, setUpdateCusToken] = useState(false);
  const { openFullModal, closeAllModals, openSizedCenterModal } = useModal();
  const { data: pinStatusdata, refetch } = useGetPinStatus(card.id, cardStatus);

  const isChargeCard = cardIsChargeCard(card as unknown as FlexCard);

  const updateCardStatus = async (currentStatus: CardStatus) => {
    setStatus(currentStatus);
    mutate(
      {
        cardId: card.id,
        status: currentStatus,
      },
      {
        onSuccess: (data) => {
          const cardUpdated = data.card;
          setCardStatus(cardUpdated.status);
        },
        onError: (error) => {
          console.error('Error updating debit card status', error);
          setError(true);
        },
      },
    );
  };

  // update pin state
  const [pinAction, setPinAction] = useState<'set' | 'change' | null>();
  const [bearerToken, setBearerToken] = useState<string | null>();
  const needsSetNewPin = pinStatusdata === 'NotSet' && !isChargeCard;

  const canChangePIN =
    user.id === card.userId &&
    cardStatus === 'active' &&
    !needsSetNewPin &&
    !isChargeCard;

  const handlePinAction = (action: 'set' | 'change') => {
    setUpdateCusToken(true);
    setPinAction(action);
  };

  const onSuccessSettingNewPin = () => {
    setBearerToken(null);
    refetch();
  };

  const resetUpdatePin = () => {
    setBearerToken(null);
    setPinAction(null);
  };

  const handleUpdateSatusPINcard = (token?: string) => {
    if (token) {
      setBearerToken(token);
      setUpdateCusToken(false);
    } else {
      updateCardStatus(status!);
    }
  };

  const navigateToTransactions = () => {
    if (isChargeCard) {
      navigate({
        pathname: `/charge-card/overview`,
        search: createSearchParams({
          card: `${card.cardNumber.slice(-4)}`,
        }).toString(),
      });
    } else {
      navigate({
        pathname: `/banking/transactions`,
        search: createSearchParams({
          cardId: `${unitcoCardId}`,
        }).toString(),
      });
    }
  };

  const disableActionButton =
    card.status === 'issued' && card.userId !== userId;

  let cardAction: {
    style: string;
    actionTxt: string;
    onClick: () => void;
    icon: ReactElement<IconType>;
  };
  switch (cardStatus) {
    case 'issued':
      cardAction = {
        style: 'actions',
        actionTxt: 'Activate',
        onClick: () =>
          openFullModal(
            <ActivateDebitCard card={card} isChargeCard={isChargeCard} />,
          ),
        icon: <PiPlayFill color={theme.colors.contentBackground[2]} />,
      };
      break;

    case 'suspended':
      cardAction = {
        style: 'actions',
        actionTxt: 'Unfreeze',
        onClick: () => updateCardStatus('unfreeze'),
        icon: <PiPlayFill color={theme.colors.contentBackground[2]} />,
      };
      break;

    default:
      cardAction = {
        actionTxt: 'Freeze',
        style: 'freeze-action',
        onClick: () => updateCardStatus('freeze'),
        icon: <PiPauseCircleBold size="1.12rem" />,
      };
      break;
  }

  const cancelDebitCard = () => {
    if (card.cardType === 'PHYSICAL') {
      openFullModal(
        <CancelDebitCard
          card={card}
          setCardStatus={setCardStatus}
          isChargeCard={isChargeCard}
        />,
      );
    } else {
      openSizedCenterModal(<CancelVirtualCard {...{ card, setCardStatus }} />, {
        width: '35%',
      });
    }
  };

  const menuOptions = [
    {
      name: 'Edit card',
      dataTestId: 'data-testid',
      onClick: () => setEditCard(true),
      visible: isAdmin,
    },
    {
      name: 'Reset PIN',
      dataTestId: 'reset-pin',
      onClick: () => handlePinAction('change'),
      visible: canChangePIN,
    },
    {
      name: 'Set PIN',
      dataTestId: 'create-pin',
      onClick: () => handlePinAction('set'),
      visible: needsSetNewPin && user.id === card.userId,
    },
    {
      name: 'Cancel card',
      dataTestId: 'cancel-card',
      onClick: () => cancelDebitCard(),
      visible: cardStatus === 'active' && isAdmin,
    },
  ];

  return (
    <div>
      <Group mx={useMobileView ? 20 : 50} justify="space-between">
        <Tooltip
          withArrow
          disabled={!disableActionButton}
          label="The owner of this card is the only one who can activate it."
        >
          <Button
            loading={isPending}
            leftSection={cardAction.icon}
            onClick={cardAction.onClick}
            disabled={disableActionButton}
            data-testid="debit-card-action"
            sx={{
              '&[data-disabled]': { pointerEvents: 'unset' },
            }}
          >
            {cardAction.actionTxt}
          </Button>
        </Tooltip>
        <Box
          data-testid="transactions"
          className={classes.transactionsAction}
          onClick={() => {
            closeAllModals();
            navigateToTransactions();
          }}
        >
          <PiListBulletsBold size="1.25rem" />
          <Text>Transactions</Text>
        </Box>
        <Box>
          <Menu position="bottom-end">
            <Menu.Target>
              <Box className={cx(classes.rowContent, classes.cursor)}>
                <Text fz={rem(14)} mr={rem(5)} data-testid="more-dropdown">
                  More
                </Text>
                <PiCaretDownBold />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              {menuOptions
                .filter((opt) => opt.visible)
                .map((item) => (
                  <Menu.Item
                    key={item.name}
                    onClick={item.onClick}
                    data-testid={item.dataTestId}
                  >
                    {item.name}
                  </Menu.Item>
                ))}
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
      {updateCusToken && (
        <Box className={classes.twoFactorContainer}>
          <TwoFactorAuthSection
            isVGSflow={true}
            onSuccess={handleUpdateSatusPINcard}
          />
        </Box>
      )}

      {pinAction === 'change' && bearerToken && (
        <UpdatePIN
          bearerToken={bearerToken}
          onCancel={resetUpdatePin}
          onPINupdated={resetUpdatePin}
          // the API brings this value like 'ucdc_{unitID}', so we must substring the 'ucdc_' value
          unitcoCardId={unitcoCardId}
        />
      )}

      {needsSetNewPin && bearerToken && (
        <SetDebitCardPIN
          bearerToken={bearerToken}
          unitcoCardId={unitcoCardId}
          onSuccess={onSuccessSettingNewPin}
          onCancel={() => setBearerToken(null)}
        />
      )}
    </div>
  );
};

export default DebitCardActions;
