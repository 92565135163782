import { Button, Group, Popover, rem, Switch, Text } from '@mantine/core';
import useModal from 'components/modal/modal-hook';
import { formatCurrency } from '@utilities/formatters';
import { useMemo, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useStyles } from './credit-payment.styles';
import CardRepaymentModal from '@common/card-repayment-modal';
import { DateTime } from 'luxon';
import { showNotification } from '@mantine/notifications';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IoReloadOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import TransparentCenteredLoader from '@common/loading/transaparent-centered-loader';
import {
  ApplicationState,
  IsAccountant,
} from '../../../../recoil-state/application/product-onboarding';
import { RepeatIcon } from 'assets/svg';
import { UseGetTenant } from 'queries/use-tenant';
import { useGetCompanyBalance } from '@queries/use-credit-payment';
import { useGetPaymentScheduleData } from '@queries/use-credit-payments-schedule-chart';
import { isGraceDayEarlyPayConfig } from '../../../../types/onboarding-info';
import { useMediaQuery } from '@mantine/hooks';

type CompanyBalance = {
  success: boolean;
  totalInvoices: number;
  totalPayments: number;
  currentBalance: number;
  creditLimit: number;
  availableLimit: number;
  minimumDue: number;
  maximumAllowedPayment: number;
  billDate: string;
  graceDate: string;
  companySpendLimit?: number;
  delinquentAmount?: number;
};

type Props = {
  credit?: boolean;
  disablePayNowBtn?: boolean;
};

const CreditPaymentCard = ({ credit, disablePayNowBtn }: Props) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [aprPopover, setAprPopover] = useState(false);
  const { openRightModal, openFullModal, closeAllModals } = useModal();
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const { data } = UseGetTenant();
  const {
    data: companyBalanceData,
    refetch,
    isError,
  } = useGetCompanyBalance(company.id);

  const { refetch: refetchPaymentSchedule } = useGetPaymentScheduleData(
    company.id,
  );

  const x = data?.aprPct;
  const y: number = x ? +x : 0;

  const aprPct = (Math.round(y * 100) / 100).toFixed(2);

  const closeModal = () => {
    localStorage?.removeItem('paymentAmount');
    refetch();
    refetchPaymentSchedule();
    closeAllModals();
  };

  const refetchCompanyBalance = () => {
    refetch();
  };

  const openPaymentModal = () => {
    if (isMobile) {
      openFullModal(<CardRepaymentModal closeModal={closeModal} />);
    } else {
      openRightModal(<CardRepaymentModal closeModal={closeModal} />);
    }
  };

  const { classes, theme } = useStyles({ credit });

  const isAccountant = useRecoilValue(IsAccountant);
  const [companyBalance, setCompanyBalance] = useState<CompanyBalance>({
    success: false,
    totalInvoices: 0,
    totalPayments: 0,
    currentBalance: 0,
    creditLimit: 0,
    availableLimit: 0,
    minimumDue: 0,
    maximumAllowedPayment: 0,
    billDate: '',
    graceDate: '',
    companySpendLimit: 0,
    delinquentAmount: 0,
  });
  const [loading, setLoading] = useState(false);

  const currentBalanceString = `current balance : ${formatCurrency(
    companyBalance.maximumAllowedPayment,
  )}`;

  const getDaysTilDue = () => {
    if (!companyBalance.billDate) {
      return 'N/A';
    }
    const diff = DateTime.fromFormat(
      companyBalance.billDate,
      'yyyy-MM-dd',
    ).diffNow();

    const diffDays = Math.floor(diff.as('days'));
    if (companyBalance.minimumDue <= 0) {
      return 'Nothing due';
    }

    return diffDays < 0
      ? 'Due today'
      : `Due in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  };

  const onAutopayToggle = async (enable: boolean) => {
    setLoading(true);
    try {
      await flexbaseOnboardingClient.updateCompany({
        autopay: enable ? { when: 'due' } : {},
      });
      setCompany((prev) => ({
        ...prev,
        company: { ...prev.company, autopay: enable ? { when: 'due' } : {} },
      }));
      showNotification({
        title: 'Success!',
        message: `Autopay successfully ${enable ? 'enabled' : 'disabled'}.`,
        color: 'flexbase-teal',
      });
    } catch (error) {
      showNotification({
        title: 'Failure',
        message: `Autopay could not be ${
          enable ? 'enabled' : 'disabled'
        }. Please contact support if the issue persists.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (companyBalanceData) {
      setCompanyBalance(companyBalanceData);
    }
  }, [companyBalanceData]);

  return (
    <div
      className={classes.creditPaymentContainer}
      onClick={() => {
        navigate('/cards/pay');
      }}
    >
      <TransparentCenteredLoader visible={loading} />
      <div className={classes.header}>
        <Text className={classes.amountDueLabel}>
          {isGraceDayEarlyPayConfig(company.earlypay)
            ? 'Net 60 billing payment'
            : 'Next payment'}
        </Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {credit && (
            <div className={classes.aprContainer}>
              <Popover opened={aprPopover} position="top">
                <Popover.Target>
                  <Text
                    className={classes.daysTilDue}
                    onMouseEnter={() => setAprPopover(true)}
                    onMouseLeave={() => setAprPopover(false)}
                  >
                    View APR
                  </Text>
                </Popover.Target>
                <Popover.Dropdown>
                  <div>
                    <Text
                      m="auto"
                      fw={'bold'}
                      color="primarySecondarySuccess.8"
                      fz={rem(14)}
                    >
                      APR
                    </Text>
                    <Text m="auto" color="blackish.1" fz={rem(14)}>
                      Net-60 APR Interest: 0%
                    </Text>
                    <Text m="auto" color="blackish.1" fz={rem(14)}>
                      Post-60 day APR Interest: {aprPct} %
                    </Text>
                  </div>
                </Popover.Dropdown>
              </Popover>
            </div>
          )}
          {(companyBalance.billDate || companyBalance.delinquentAmount) && (
            <div
              className={classes.daysTilDueContainer}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Popover opened={popoverOpened} position="top">
                <Popover.Target>
                  <Text
                    className={classes.daysTilDue}
                    onMouseEnter={() => setPopoverOpened(true)}
                    onMouseLeave={() => setPopoverOpened(false)}
                  >
                    {companyBalance.delinquentAmount
                      ? 'OVERDUE'
                      : getDaysTilDue()}
                  </Text>
                </Popover.Target>
                <Popover.Dropdown>
                  <div>
                    <Text
                      style={{
                        fontSize: rem(14),
                        color: theme.colors.blackish[0],
                        margin: 'auto',
                      }}
                    >
                      To ensure your payment is processed on the date submitted,
                      it must be sent before 5:00pm EST
                    </Text>
                  </div>
                </Popover.Dropdown>
              </Popover>
            </div>
          )}
        </div>
      </div>
      <Group>
        {isError ? (
          <Group style={{ marginTop: rem(10), alignItems: 'flex-start' }}>
            <IoReloadOutline
              style={{ cursor: 'pointer' }}
              onClick={refetchCompanyBalance}
            />
            <Text className={classes.amountDueLabel}>Error. Reload data.</Text>
          </Group>
        ) : (
          <div className={classes.amountDue}>
            {formatCurrency(
              companyBalance.minimumDue > 0
                ? companyBalance.minimumDue.toString()
                : 0,
            )}
            {companyBalance.minimumDue > 0 && (
              <div className={classes.billDate}>
                <RepeatIcon stroke={theme.colors.neutral[6]} />
                <Text>
                  {company?.autopay?.when ? 'Auto pay on ' : 'Payment due on '}
                  {DateTime.fromISO(companyBalance.billDate).toFormat('MMM dd')}
                </Text>
              </div>
            )}
          </div>
        )}

        <Group justify="right">
          {!credit && !company.autopay && (
            <Group>
              <div className={classes.autopayLabel}>
                <Text className={classes.autopayLabelText}>Auto Pay</Text>
                <Text className={classes.autopaySchedule}>60 day billing</Text>
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <Switch
                  disabled={isAccountant}
                  checked={company.autopay}
                  color="flexbase-ornage"
                  onChange={(e) => {
                    onAutopayToggle(e.currentTarget.checked);
                  }}
                  size={'lg'}
                  styles={{
                    input: { width: rem(50), cursor: 'pointer' },
                  }}
                />
              </div>
            </Group>
          )}
        </Group>
      </Group>

      <div className={classes.actionButtonsContainer}>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Button
            disabled={isAccountant || disablePayNowBtn}
            onClick={(e: any) => {
              e.stopPropagation();
              openPaymentModal();
            }}
            data-testid="pay-now-button"
            mr={'md'}
          >
            Pay now
          </Button>
        </div>

        <Text className={classes.currentBalance}>{currentBalanceString}</Text>
      </div>
    </div>
  );
};

export default CreditPaymentCard;
