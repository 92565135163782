import {
  AppShell,
  Box,
  Container,
  Flex,
  Group,
  Loader,
  rem,
  Stack,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useCheatSheetFeatureFlag } from '@utilities/feature-flags';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { PiCircleFill, PiDiamondsFour } from 'react-icons/pi';

type Component = {
  // The label to display in the navbar
  label: string;
  // The path segment to use in the URL
  path: string;
  // The component to render
  component: React.LazyExoticComponent<React.ComponentType<any>>;
};

// Components that will be rendered in the sidebar
const COMPONENTS: Record<string, Component> = {
  drawer: {
    label: 'Drawer',
    path: 'drawer',
    component: lazy(() => import('./components/drawer/drawer-container')),
  },
  forms: {
    label: 'Forms',
    path: 'forms',
    component: lazy(() => import('./components/form/form')),
  },
  wizard: {
    label: 'Wizard',
    path: 'wizard',
    component: lazy(() => import('./components/wizard/wizard')),
  },
  errorCard: {
    label: 'Error Card',
    path: 'errorCard',
    component: lazy(() => import('./components/wizard/error-card')),
  },
  table: {
    label: 'Table',
    path: 'table',
    component: lazy(() => import('./components/table')),
  },
  alert: {
    label: 'Alert',
    path: 'alert',
    component: lazy(() => import('./components/alert/alert')),
  },
  widget: {
    label: 'Widget',
    path: 'widget',
    component: lazy(() => import('./components/widget/widget')),
  },
  mainLayout: {
    label: 'Main Layout',
    path: 'mainLayout',
    component: lazy(() => import('./components/main-layout/main-layout')),
  },
  detailLayout: {
    label: 'Detail Layout',
    path: 'detailLayout',
    component: lazy(() => import('./components/detail-layout/datail-layout')),
  },
};

// Components that will be used by subroutes / outlets
const NESTED_COMPONENTS: Record<string, Component> = {
  firstTab: {
    label: 'Layout First Tab',
    path: 'first-tab',
    component: lazy(() => import('./components/main-layout/layout-first-tab')),
  },
  secondTab: {
    label: 'Layout Second Tab',
    path: 'second-tab',
    component: lazy(() => import('./components/main-layout/layout-second-tab')),
  },
  detailLayoutItem: {
    label: 'Detail Layout Item',
    path: 'detail-layout-item/:id',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item'),
    ),
  },
  detailLayoutItemTabOne: {
    label: 'Detail Layout Item Tab One',
    path: 'tab-one',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item-tab-one'),
    ),
  },
  detailLayoutItemTabTwo: {
    label: 'Detail Layout Item Tab Two',
    path: 'tab-two',
    component: lazy(
      () => import('./components/detail-layout/detail-layout-item-tab-two'),
    ),
  },
};

const EmeraldHeader = () => {
  const navigate = useNavigate();
  return (
    <AppShell.Header>
      <Flex justify="space-between" align="center" h="100%" px="lg">
        <Group
          gap="xs"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/emerald')}
        >
          <Text size="xl">❇️</Text>
          <Text size="lg" fw={600}>
            Emerald
          </Text>
        </Group>

        <Text size="sm" c="gray.6">
          Kickstart your UI workflow.
        </Text>
      </Flex>
    </AppShell.Header>
  );
};

const SidebarRow = ({
  component,
  isActive,
}: {
  component: Component;
  isActive: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Flex
      key={component.path}
      onClick={() => navigate(`/emerald/${component.path}`)}
      py="xs"
      px="md"
      bg={isActive ? 'gray.1' : 'transparent'}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.colors.gray[1] },
        transition: 'background-color 180ms ease',
        borderRadius: rem(6),
      })}
      align="center"
      justify="space-between"
    >
      <Text
        fw={isActive ? 500 : 400}
        color={isActive ? 'black' : 'gray.7'}
        mr="sm"
      >
        {component.label}
      </Text>
      <Transition
        mounted={isActive}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => <PiCircleFill size={7} color="black" style={styles} />}
      </Transition>
    </Flex>
  );
};

const EmptyState = () => {
  const theme = useMantineTheme();
  return (
    <Box p="lg" pt="20%" mih="100%">
      <Flex justify="center" align="center" direction="column" gap="sm">
        <PiDiamondsFour size={36} color={theme.colors.gray[6]} />
        <Text color={theme.colors.gray[6]} fw={500}>
          Select a component on the sidebar
        </Text>
      </Flex>
    </Box>
  );
};

const Emerald = () => {
  const location = useLocation();
  const isEnabled = useCheatSheetFeatureFlag();
  if (!isEnabled) {
    return <Navigate to="/" replace />;
  }
  return (
    <Box>
      <AppShell
        padding="md"
        styles={(theme) => ({
          main: {
            backgroundColor: theme.colors.neutral[2],
          },
        })}
        header={{ height: 50 }}
        navbar={{ breakpoint: 'base', width: 250 }}
      >
        <EmeraldHeader />
        <AppShell.Navbar p="xs">
          <Stack gap="xxs">
            {Object.values(COMPONENTS).map((component) => {
              const isActive = location.pathname.includes(
                `/emerald/${component.path}`,
              );
              return (
                <SidebarRow
                  key={component.path}
                  component={component}
                  isActive={isActive}
                />
              );
            })}
          </Stack>
        </AppShell.Navbar>
        <AppShell.Main>
          <Container size="xl" p={0} mih="100%">
            <Suspense
              fallback={
                <Box p="lg" pt="20%" mih="100%">
                  <Flex justify="center" align="center">
                    <Loader size="sm" />
                  </Flex>
                </Box>
              }
            >
              <Routes>
                <Route
                  path={`${COMPONENTS.widget.path}`}
                  element={<COMPONENTS.widget.component />}
                />
                <Route
                  path={`${COMPONENTS.drawer.path}`}
                  element={<COMPONENTS.drawer.component />}
                />
                <Route
                  path={`${COMPONENTS.forms.path}`}
                  element={<COMPONENTS.forms.component />}
                />
                <Route
                  path={`${COMPONENTS.wizard.path}`}
                  element={<COMPONENTS.wizard.component />}
                />
                <Route
                  path={`${COMPONENTS.errorCard.path}`}
                  element={<COMPONENTS.errorCard.component />}
                />
                <Route
                  path={`${COMPONENTS.table.path}`}
                  element={<COMPONENTS.table.component />}
                />
                <Route
                  path={`${COMPONENTS.mainLayout.path}`}
                  element={<COMPONENTS.mainLayout.component />}
                >
                  <Route
                    index
                    element={
                      <Navigate
                        to={`${NESTED_COMPONENTS.firstTab.path}`}
                        replace
                      />
                    }
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.firstTab.path}`}
                    element={<NESTED_COMPONENTS.firstTab.component />}
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.secondTab.path}`}
                    element={<NESTED_COMPONENTS.secondTab.component />}
                  />
                </Route>

                <Route
                  path={`${COMPONENTS.alert.path}`}
                  element={<COMPONENTS.alert.component />}
                />
                <Route path={`${COMPONENTS.detailLayout.path}`}>
                  <Route
                    path=""
                    element={<COMPONENTS.detailLayout.component />}
                  />
                  <Route
                    path={`${NESTED_COMPONENTS.detailLayoutItem.path}`}
                    element={<NESTED_COMPONENTS.detailLayoutItem.component />}
                  >
                    <Route
                      index
                      element={
                        <Navigate
                          to={`${NESTED_COMPONENTS.detailLayoutItemTabOne.path}`}
                          replace
                        />
                      }
                    />
                    <Route
                      path={`${NESTED_COMPONENTS.detailLayoutItemTabOne.path}`}
                      element={
                        <NESTED_COMPONENTS.detailLayoutItemTabOne.component />
                      }
                    />
                    <Route
                      path={`${NESTED_COMPONENTS.detailLayoutItemTabTwo.path}`}
                      element={
                        <NESTED_COMPONENTS.detailLayoutItemTabTwo.component />
                      }
                    />
                  </Route>
                </Route>
                <Route path="*" element={<EmptyState />} />
              </Routes>
            </Suspense>
          </Container>
        </AppShell.Main>
      </AppShell>
    </Box>
  );
};

export default Emerald;
