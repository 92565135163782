import { Text, Flex, Paper, useMantineTheme } from '@mantine/core';

type LineItemsTotalProps = {
  title: string;
  description: string;
  invoiceTotal: string;
  isError: boolean;
};

const LineItemsTotal = ({
  title,
  description,
  invoiceTotal,
  isError,
}: LineItemsTotalProps) => {
  const theme = useMantineTheme();
  const errorStyles = {
    backgroundColor: theme.colors.critical[0],
    border: `1px solid ${theme.colors.critical[3]}`,
  };

  return (
    <Paper
      radius="md"
      bg={isError ? errorStyles.backgroundColor : 'neutral.0'}
      style={{
        border: isError ? errorStyles.border : 'none',
        textAlign: 'center',
      }}
    >
      <Flex w="100%" direction="column" align="center" p="xxl">
        {isError ? (
          <>
            <Text fw="500" size="sm" color="theme.colors.critical[6]">
              {title}
            </Text>
            <Text fw="400" size="2.5rem">
              {invoiceTotal}
            </Text>
            <Text size="xs" color="theme.colors.critical[6]">
              {description}
            </Text>
          </>
        ) : (
          <>
            <Text fw={500} size="sm">
              {title}
            </Text>
            <Text fz={40} ta="center" lh={1}>
              {invoiceTotal}
            </Text>
          </>
        )}
      </Flex>
    </Paper>
  );
};

export default LineItemsTotal;
