import {
  Anchor,
  Button,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import FlexIconLink from '@common/icons/flex-icon-link';
import { Link } from 'react-router-dom';
import { MultiStepFormSubmitButton } from '@common/multi-step-form/multi-step-form-submit-button';
import { useOnboardingStepperStyles } from '@utilities/custom-hooks/use-onboarding-stepper-styles';
import { useMediaQuery } from '@mantine/hooks';
import { useFactorSetupMultistepFormContext } from './factor-setup-multi-step-form.context';
import { useEffect } from 'react';

export const FactorSetupFormContainer = () => {
  const { classes, cx } = useOnboardingStepperStyles();
  const isMobileScreenSize = useMediaQuery('(max-width: 767px)');
  const { currentStep, state, setState, hasPreviousStep, goToPreviousStep } =
    useFactorSetupMultistepFormContext();

  useEffect(() => {
    setState({ loading: false, error: null });
  }, [currentStep]);

  return (
    <>
      <Flex justify="space-between" direction="row" p={32} align="center">
        <FlexIconLink width={68} />
        <Anchor c="neutral.7" component={Link} to="/">
          Cancel
        </Anchor>
      </Flex>
      <Divider />
      <Stack
        gap="2.5rem"
        className={cx(
          classes.webContainer,
          isMobileScreenSize && classes.mobileContainer,
        )}
        sx={(_, helpers) => ({
          width: '100%',
          [helpers.largerThan(767)]: {
            width: '25rem',
          },
        })}
      >
        <Text fz="2rem" lh="48px" lts="-0.64px">
          {currentStep!.metadata.title}
        </Text>
        <Text>{currentStep!.metadata.subtitle}</Text>
        {currentStep?.element}
        {state.error && (
          <Text fz={12} c="critical.2">
            {state.error}
          </Text>
        )}
        <Group>
          {hasPreviousStep && (
            <Button
              variant="outline"
              onClick={() => goToPreviousStep()}
              disabled={state.loading}
            >
              Back
            </Button>
          )}
          {!currentStep?.metadata.hideContinueButton && (
            <MultiStepFormSubmitButton
              form={currentStep?.metadata.id ?? 'none'}
              ml="auto"
              data-testid="button-continue"
              loading={state.loading}
            >
              Continue
            </MultiStepFormSubmitButton>
          )}
        </Group>
      </Stack>
    </>
  );
};
