import { useState } from 'react';
import {
  Button,
  Image,
  Tabs,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { ArrowRight, BenefitsSearch, CloseIcon } from '../../assets/svg';
import { useStyles } from './styles';
import { Benefit, BenefitTabs, getBenefits } from './benefits-data';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import useModal from '@common/modal/modal-hook';

import StockImage from '../../assets/images/benefits-and-rewards/stock-image.jpeg';
import { useNavigate } from 'react-router-dom';

const benefits: Benefit[] = getBenefits(11, '#94928F');
const benefitMatch = (benefit: Benefit, searchString: string) => {
  return (
    benefit.type.toLowerCase().includes(searchString) ||
    benefit.category.toLowerCase().includes(searchString) ||
    benefit.title.toLowerCase().includes(searchString) ||
    benefit.description.toLowerCase().includes(searchString) ||
    benefit.ctaButtonText.toLowerCase().includes(searchString)
  );
};

const BenefitsList = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const modal = useModal();
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 200);
  const [activeTab, setActiveTab] = useState<string | null>('featured');
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  let filteredBenefits = benefits.filter((bf) => {
    if (activeTab === 'all') {
      return true;
    }
    if (activeTab === 'featured') {
      return bf.featured;
    }
    return bf.category.toLowerCase() === activeTab;
  });
  if (debounced?.length) {
    filteredBenefits = filteredBenefits.filter((bf) =>
      benefitMatch(bf, debounced.toLowerCase()),
    );
  }

  const handleLearnMoreClick = () => {
    modal.openSizedCenterModal(<DetailModal></DetailModal>, {
      width: '823px',
      height: 'auto',
      margin: '0',
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  const navigate = useNavigate();

  const handleAccessButtonClick = (path: string) => {
    navigate(path);
  };

  const closeModal = () => {
    modal.closeAllModals();
  };

  const DetailModal = () => {
    return (
      <div className={classes.detailModalDiv}>
        <div className={classes.detailModalCloseIconDiv}>
          <CloseIcon
            height={10}
            width={10}
            style={{ margin: 'auto' }}
            onClick={closeModal}
          />
        </div>
        <div>
          <Text className={classes.tabLabel}>Visa Infinite Card</Text>
          <Text className={classes.detailModalTitle}>
            Relais & Chateaux <br />
            Fine Hotel Collection
          </Text>
          <div
            className={
              useMobileView
                ? classes.mobileDetailModalContent
                : classes.detailModalContent
            }
          >
            <Image
              src={StockImage}
              width={useMobileView ? '220px' : '281px'}
              height={useMobileView ? '150px' : '219px'}
              mr={useMobileView ? 'auto' : theme.spacing.xl}
              ml={useMobileView ? 'auto' : 0}
            ></Image>
            <Text
              className={classes.detailModalDescription}
              mt={useMobileView ? theme.spacing.sm : 0}
            >
              Visa Infinite cardholders enjoy premium benefits including
              complimentary room upgrade (upon availability), VIP welcome and
              complimentary daily breakfast for all members of their party
              (available at select properties) 1 with Relais & Châteaux, a
              prestigious collection of approximately 500 luxury properties in
              over 60 countries worldwide. The VIP welcome applies to all Relais
              & Châteaux properties, and could include, for example, a bottle of
              champagne in room upon arrival. Visit www.google.com/.visa
              infinite or call Visa Infinite Concierge for more details and to
              book your stay.
            </Text>
          </div>
          <div className={classes.detailModalButtons}>
            <Button
              h={42}
              w={88}
              mr={16}
              variant="outline"
              classNames={{
                root: classes.detailModalButtonRoot,
              }}
              onClick={closeModal}
            >
              Dismiss
            </Button>
            <Button
              h={42}
              w={112}
              classNames={{
                root: classes.detailModalButtonRoot,
              }}
            >
              Activate
              <ArrowRight
                width={16}
                height={16}
                className={classes.featuredCardButtonArrow}
              ></ArrowRight>
            </Button>
          </div>
          <Text className={classes.detailModalFinePrint}>
            1 Complimentary breakfast available at approximately 200
            participating properties. Must book 72 hours in advance through Visa
            Infinite Concierge. Limited to stays of up to 7 consecutive nights.
            See https://www.relaischateaux.com/us/osp/visainfinite-us for list
            of participating properties.
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: useMobileView ? '90%' : '1100px',
        margin: '0 auto',
      }}
    >
      <TextInput
        classNames={{
          root: useMobileView ? classes.mobileSearchRoot : classes.searchRoot,
          section: classes.searchRightSection,
        }}
        placeholder="Search for a benefit or perk"
        value={searchValue}
        onChange={(event) => {
          setActiveTab('all');
          setSearchValue(event.currentTarget.value);
        }}
        rightSection={<BenefitsSearch />}
      ></TextInput>
      <Tabs
        value={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
          // reset search when changing tabs
          setSearchValue('');
        }}
        classNames={{
          tab: classes.tab,
          tabSection: classes.tabIcon,
          root: classes.tabRoot,
          ...(useMobileView && { tabsList: classes.tabList }),
        }}
      >
        <Tabs.List>
          {BenefitTabs.map((benefit) => {
            return (
              <Tabs.Tab
                key={benefit.value}
                value={benefit.value}
                leftSection={benefit.icon}
              >
                {benefit.label}
              </Tabs.Tab>
            );
          })}
        </Tabs.List>

        <Tabs.Panel value="featured">
          <div
            className={
              useMobileView ? classes.mobileBenefits : classes.featuredBenefits
            }
          >
            {filteredBenefits.map((benefit) => {
              if (benefit.featured) {
                return (
                  <div
                    key={benefit.title}
                    className={
                      useMobileView
                        ? classes.mobileFeaturedCard
                        : classes.featuredCard
                    }
                  >
                    <div className={classes.featuredTitle}>
                      <Text className={classes.cardTitle}>{benefit.type}</Text>
                      <span className={classes.featuredCardSubtitleSpan}>
                        {benefit.categoryIcon}
                        <Text className={classes.featuredCardSubtitle}>
                          {benefit.category}
                        </Text>
                      </span>
                    </div>
                    <Image
                      src={benefit.imageSrc}
                      width="100%"
                      height={166}
                      mr="auto"
                      ml="auto"
                      mt={8}
                    ></Image>
                    <Text className={classes.featuredCardBigText}>
                      {benefit.title}
                    </Text>
                    <Text className={classes.featuredCardDescription}>
                      {benefit.description}
                    </Text>
                    <div className={classes.featuredCardButtonDiv}>
                      <Text
                        className={classes.learnMore}
                        onClick={handleLearnMoreClick}
                      >
                        Learn more
                      </Text>
                      <Button h={32} className={classes.ctaButton}>
                        {benefit.ctaButtonText}{' '}
                        <ArrowRight
                          width={16}
                          height={16}
                          className={classes.featuredCardButtonArrow}
                        ></ArrowRight>
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </Tabs.Panel>
        {activeTab !== 'featured' && (
          <div
            className={
              useMobileView
                ? classes.mobileBenefits
                : classes.nonFeaturedBenefits
            }
          >
            {filteredBenefits.map((benefit) => {
              return (
                <div
                  key={benefit.title}
                  className={
                    useMobileView ? classes.mobileFeaturedCard : classes.card
                  }
                >
                  <div className={classes.featuredTitle}>
                    <Text className={classes.cardTitle}>{benefit.type}</Text>
                    <span className={classes.featuredCardSubtitleSpan}>
                      {benefit.categoryIcon}
                      <Text className={classes.featuredCardSubtitle}>
                        {benefit.category}
                      </Text>
                    </span>
                  </div>
                  <Image
                    src={benefit.imageSrc}
                    width="100%"
                    height={166}
                    mr="auto"
                    ml="auto"
                    mt={8}
                  ></Image>
                  <Text className={classes.featuredCardBigText}>
                    {benefit.title}
                  </Text>
                  <Text className={classes.featuredCardDescription}>
                    {benefit.description}
                  </Text>
                  <div className={classes.featuredCardButtonDiv}>
                    <Text
                      className={classes.learnMore}
                      onClick={handleLearnMoreClick}
                    >
                      Learn more
                    </Text>
                    <Button
                      h={32}
                      className={classes.ctaButton}
                      onClick={() =>
                        handleAccessButtonClick(benefit.path || '')
                      }
                    >
                      {benefit.ctaButtonText}{' '}
                      <ArrowRight
                        width={16}
                        height={16}
                        className={classes.featuredCardButtonArrow}
                      ></ArrowRight>
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default BenefitsList;
