import { useRecoilState, useRecoilValue } from 'recoil';
import { Center, LoadingOverlay, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import CardPaymentAmount from '@common/card-payment-amount';
import CardPaymentReview from '@common/charge-and-credit-cards/card-payment-review';
import { formatCurrency } from 'utilities/formatters';
import { Analytics } from 'services/analytics/analytics';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { paymentAccountQuery } from 'recoil-state/payment.states';
import type { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { AddAccount } from 'areas/credit/repayment/add-account';
import { useGetMinimumDue } from '@queries/use-company';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import {
  useMakePaymentUnit,
  useMakePaymentPlaid,
} from '@queries/use-credit-payment';

type Props = {
  closeModal: () => void;
  onSuccess: (
    paymentId: string,
    paymentAmount: number,
    paymentAccount: DepositAccount | PlaidAccount,
    paymentStatus: string,
  ) => void;
  onError: () => void;
};

export const getInitialChipValue = (
  paymentAmount: number,
  currentBalance: number,
  minimumDue: number,
) => {
  if (paymentAmount === currentBalance) {
    return 'max';
  }

  if (paymentAmount === minimumDue) {
    return 'min';
  }

  return 'custom';
};

const ReviewAndPay = ({ closeModal, onSuccess, onError }: Props) => {
  const { company } = useRecoilValue(ApplicationState);
  const [reviewing, setReviewing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);

  const {
    data: balance,
    isLoading: isCompanyBalanceLoading,
    isError: isCompanyBalanceError,
  } = useGetMinimumDue(company.id);
  const frozen = balance?.frozen ?? false;
  const maxPayment = balance?.maximumAllowedPayment ?? 0;
  // check to not present a negative value to the user
  const currentBalance =
    balance && balance.currentBalance > 0 ? balance.currentBalance : 0;
  const minimumDue = balance && balance.minimumDue > 0 ? balance.minimumDue : 0;
  const currentPaymentAmount = frozen ? maxPayment : currentBalance;
  const delinquentAmount = balance?.delinquentAmount ?? 0;
  const interestDue = balance?.interestDue ?? 0;
  const minimumAllowedPayment = balance?.minimumAllowedPayment ?? 0;
  const minPayment = frozen ? interestDue : minimumAllowedPayment;
  const comeCurrentPayment = frozen ? (balance?.comeCurrentPayment ?? 0) : 0;

  const [chip, setChip] = useState<'min' | 'max' | 'custom'>(
    getInitialChipValue(currentPaymentAmount, currentBalance, minimumDue),
  );
  const [paymentAmount, setPaymentAmount] = useState(currentPaymentAmount);
  const initialCustomValue = chip === 'custom' ? paymentAmount : 0;
  const [customAmount, setCustomAmount] = useState(initialCustomValue);

  const handleReview = (data: {
    chip: 'min' | 'max' | 'custom';
    customAmount: number;
  }) => {
    setChip(data.chip);
    setReviewing(true);
    setCustomAmount(data.customAmount);
  };

  const {
    data: depositAccountsData,
    isLoading: isDepositAccountsLoading,
    isError: isDepositAccountsError,
  } = useGetDepositAccounts();
  const depositAccounts = depositAccountsData?.accounts ?? [];
  const { data: plaidAccounts, refetch } = useExternalAccounts();
  const [paymentAccount, setPaymentAccount] =
    useRecoilState(paymentAccountQuery);

  const { mutate: makePaymentUnit, isPending: isPendingPaymentUnit } =
    useMakePaymentUnit();
  const { mutate: makePaymentPlaid, isPending: isPendingPaymentPlaid } =
    useMakePaymentPlaid();

  const onSuccessPayment = (cardPayment: { id: string; status: string }) => {
    if (cardPayment.status !== 'failed') {
      Analytics.track('Credit Payment Submitted', { paymentAmount });
      onSuccess(
        cardPayment.id,
        paymentAmount,
        paymentAccount!,
        cardPayment.status,
      );
    }
  };

  const onConfirmClick = async () => {
    // SAFETY: paymentAccount *must* exist by this point
    // (see the assertion in the "stack" variable below)
    const paymentAccountId = paymentAccount!.id;

    if (paymentAccount?.plaidOrDeposit === 'plaid') {
      makePaymentPlaid(
        {
          amount: paymentAmount,
          plaidTokenId: paymentAccountId,
        },
        {
          onSuccess(data) {
            onSuccessPayment(data.cardPayment);
          },
          onError() {
            onError();
          },
        },
      );
    } else {
      makePaymentUnit(
        {
          amount: paymentAmount,
          depositAccountId: paymentAccountId,
          lineOfCredit: 'lithic',
        },
        {
          onSuccess(data) {
            onSuccessPayment(data.cardPayment);
          },
          onError() {
            onError();
          },
        },
      );
    }
  };

  const isLoading = isCompanyBalanceLoading || isDepositAccountsLoading;
  const isError = isCompanyBalanceError || isDepositAccountsError;

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  useEffect(() => {
    const unlinked = plaidAccounts.some(
      (account) => account.active && account.unlinked,
    );

    if (!loading && unlinked && !token) {
      flexbaseClient
        .getPlaidLinkToken()
        .then((plaidLinkToken) => setToken(plaidLinkToken));
    }
  }, [loading, plaidAccounts]);

  const paymentAccounts = [
    ...depositAccounts
      .filter((a) => a.status === 'Open')
      .map((a) => ({ ...a, plaidOrDeposit: 'deposit' }) as DepositAccount)
      .sort((a, b) => b.balance - a.balance),
    ...plaidAccounts.reduce((accounts, account) => {
      if (
        !account.unlinked &&
        !accounts.some(({ last4 }) => account.last4 === last4)
      ) {
        accounts.push(account);
      }
      return accounts;
    }, [] as PlaidAccount[]),
  ];

  const handleAddAccountSuccess = (accounts: PlaidAccount[]) => {
    refetch();
    setPaymentAccount(accounts[0]); // Famous last words but this should always have at least one entry, otherwise the fn is never called
  };

  const stack = paymentAccount ? (
    reviewing ? (
      <CardPaymentReview
        frozen={frozen}
        paymentAccount={paymentAccount}
        paymentAmount={formatCurrency(paymentAmount)}
        onConfirmClick={onConfirmClick}
        onGoBackClick={() => setReviewing(false)}
        loadingRequest={isPendingPaymentPlaid || isPendingPaymentUnit}
      />
    ) : (
      <CardPaymentAmount
        delinquentAmount={delinquentAmount}
        frozen={frozen}
        interestDue={interestDue}
        paymentAmount={paymentAmount}
        currentBalance={currentBalance}
        comeCurrentAmount={comeCurrentPayment}
        closeModal={closeModal}
        onReviewClick={handleReview}
        paymentAccounts={paymentAccounts}
        currentAccount={paymentAccount}
        onPaymentAmountChange={setPaymentAmount}
        onPaymentAccountChange={setPaymentAccount}
        isChargePay={false}
        maxPaymentAmount={maxPayment}
        minPaymentAmount={minPayment}
        minimumDue={minimumDue}
        initialData={{ chip, customAmount }}
      />
    )
  ) : (
    <AddAccount
      onPlaidAccountLinked={handleAddAccountSuccess}
      setLoading={setLoading}
    />
  );

  if (isLoading) {
    return (
      <Center>
        <LoadingOverlay visible={isLoading} />
      </Center>
    );
  }

  return <Stack>{stack}</Stack>;
};

export default ReviewAndPay;
