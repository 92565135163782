import { FactorSetupFormContainer } from './factor-setup-form-container';
import {
  FactorSetupFormProvider,
  FactorSetupFormState,
} from './factor-setup-multi-step-form.context';
import { ContainerWithFooter } from '@common/footer/footer-container';
import { useOnboardingStepperStyles } from '@utilities/custom-hooks/use-onboarding-stepper-styles';
import { ChooseNewFactor } from '../../components/choose-new-factor';
import { ConfigureSmsFactor } from '../../components/configure-sms-factor';
import { ComponentStep } from '../../../../providers/multi-step-form-provider';

const STEPS: ComponentStep<FactorSetupFormState>[] = [
  {
    metadata: {
      id: 'choose-factor',
      title: 'Set up two-factor authentication to continue',
      subtitle:
        'Protect your account by selecting an authentication method. Your authentication preferences can be updated in settings.',
      hideContinueButton: true,
    },
    element: <ChooseNewFactor />,
  },
  {
    metadata: {
      id: 'sms',
      title: 'Login in with SMS',
      subtitle: 'Use a trusted mobile phone to receive one-time codes.',
      hideContinueButton: true,
    },
    element: <ConfigureSmsFactor />,
    condition: (state) => state.flow === 'otp',
  },
  // {
  //   metadata: {
  //     id: 'authenticator',
  //     title: 'Log in with an authenticator app',
  //     subtitle:
  //       'Using this method will require you to enter a one-time code generated by your authenticator app to log in. Don’t have an authenticator app? Download Authy from Twilio on your mobile device.',
  //   },
  //   element: <ConfigureTotpFactor />,
  //   condition: (state) => state.flow === 'totp',
  // },
];

export const AddAuthenticationFactorPage = () => {
  const { classes } = useOnboardingStepperStyles();
  return (
    <FactorSetupFormProvider
      initialState={{ loading: false, error: null, flow: null }}
      steps={STEPS}
    >
      <ContainerWithFooter
        disclaimer2={false}
        withFooter={false}
        classNames={{
          scrollContainer: classes.scrollContainer,
          container: classes.scrollContainer,
        }}
      >
        <FactorSetupFormContainer />
      </ContainerWithFooter>
    </FactorSetupFormProvider>
  );
};
