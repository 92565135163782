import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { formatCreditTransactionStatus } from '@utilities/formatters/credit-transactions-utilities';
import { useChargeCardTransactionModalFilters } from 'recoil-state/filters/use-charge-cards-filters';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['Pending'],
    key: 'pending',
  },
  {
    label: 'Settled',
    filterCriteria: ['Settled'],
    key: 'settled',
  },
  { label: 'Declined', filterCriteria: ['Declined'], key: 'declined' },
  {
    label: 'Failed',
    filterCriteria: ['Canceled', 'Failed', 'Chargeback'],
    key: 'failed',
  },
  {
    label: 'Disputed',
    filterCriteria: ['Disputed'],
    key: 'disputed',
  },
];

type CreditStatusFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditStatusFilter = ({ filterHook }: CreditStatusFilterProps) => {
  return (
    <SimpleSegmentSelectedFilter
      filterHook={filterHook}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => formatCreditTransactionStatus(row)}
    />
  );
};

export const CreditTransactionsStatusFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditStatusFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsStatusFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditStatusFilter filterHook={filterHook} />;
};
