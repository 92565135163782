import { useMediaQuery } from '@mantine/hooks';
import { PiFunnelBold, PiMagnifyingGlass } from 'react-icons/pi';
import {
  Box,
  Button,
  Flex,
  rem,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import {
  usePaymentFilters,
  usePaymentModalFilters,
} from './use-payment-filters';
import { TablePayment } from '@utilities/payments-rows';
import { FilterChips } from '@common/filter/filter-chips';
import { useFilterModal } from '@common/filter/filter-modal';
import { PaymentTypeFilter } from './filters/payment-type-filter';
import { PaymentStatusFilter } from './filters/payment-status-filter';
import { PaymentAmountFilter } from './filters/payment-amount-filter';
import { PaymentRecipientFilter } from './filters/payment-recipient-filter';
import { PaymentDateRangeFilter } from './filters/payment-date-range.filter';
import { PaymentAccountNameFilter } from './filters/payment-account-name-filter';

const PaymentLeftHeader = ({
  paymentsData,
}: {
  paymentsData: TablePayment[];
}) => {
  const theme = useMantineTheme();
  const filterHook = usePaymentFilters();
  const modalFilterHook = usePaymentModalFilters();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });
  const isMobile = useMediaQuery('(max-width: 767px)');

  const searchFilterValue = getFilterByKey('search')?.filterValue;

  const PAYMENTS_FILTERS = [
    {
      key: 'date',
      header: 'Date',
      component: <PaymentDateRangeFilter />,
    },
    {
      key: 'amount',
      header: 'Amount',
      component: <PaymentAmountFilter />,
    },
    {
      key: 'status',
      header: 'Status',
      component: <PaymentStatusFilter />,
    },
    {
      key: 'type',
      header: 'Type',
      component: <PaymentTypeFilter />,
    },
    {
      key: 'recipient',
      header: 'Recipient',
      component: <PaymentRecipientFilter paymentsData={paymentsData} />,
    },
    {
      key: 'accountName',
      header: 'Account name',
      component: <PaymentAccountNameFilter paymentsData={paymentsData} />,
    },
  ];

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(transaction).filter(
          (v) => !!v && typeof v === 'string',
        ) as string[];

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleOpenFilters = () => {
    openResponsiveFilterModal(PAYMENTS_FILTERS);
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  return (
    <Box>
      <Flex gap="md" mb="md">
        <TextInput
          value={searchFilterValue}
          w={isMobile ? '100%' : rem(370)}
          placeholder="Search payments"
          onChange={(e) => handleSearchChange(e.target.value)}
          leftSection={
            <PiMagnifyingGlass
              color={theme.colors.neutral[5]}
            />
          }
        />
        <Button
          variant="outline"
          rightSection={<PiFunnelBold />}
          onClick={handleOpenFilters}
        >
          Filters
        </Button>
      </Flex>
      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </Box>
  );
};

export default PaymentLeftHeader;
