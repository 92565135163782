import { BankImage } from '../../assets/svg';
import { Box, Text } from '@mantine/core';

type Props = { totalActionItems: number };

export const StatusCard = ({ totalActionItems }: Props) => {
  return (
    <Box
      sx={(theme, helpers) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing.xxxl,
        [helpers.smallerThan('sm')]: {
          flexDirection: 'column',
        },
      })}
    >
      <BankImage height="210px" width="199px" />
      <Box maw={507}>
        <Text c="primarySecondarySuccess.8" fz={36} lh="normal" ff="redaction">
          You have {totalActionItems} pending action item
          {totalActionItems !== 1 ? 's' : ''}
        </Text>
        <Text fz={16} lh="24px">
          You have action items that must be completed before we can continue
          processing your application.
        </Text>
      </Box>
    </Box>
  );
};
