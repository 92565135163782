import { GraceDayEarlyPayConfig } from 'types/onboarding-info';
import {
  Anchor,
  Box,
  Flex,
  Group,
  Popover,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { PiCalendarLight } from 'react-icons/pi';
import { useGetCompanyEarlyPayProjectedDueAmounts } from '@queries/use-get-company-early-pay-projected-due';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { DateTime } from 'luxon';
import { formatCurrency } from '@utilities/formatters';
import useModal from '@common/modal/modal-hook';
import { EarlyPayScheduleDrawer } from 'areas/credit/components/early-pay-card/early-pay-schedule-drawer';
import { useMediaQuery } from '@mantine/hooks';
import { Issuer, Line } from '@services/flexbase/credit.model';

type Props = {
  earlyPayConfig?: GraceDayEarlyPayConfig;
  creditLine?: Line;
  issuer?: Issuer;
};

export const EarlyPayProjectedDueCard = ({
  earlyPayConfig,
  issuer,
  creditLine,
}: Props) => {
  const isMobileView = useMediaQuery(`(max-width: 767px`);
  const theme = useMantineTheme();
  const { company } = useRecoilValue(ApplicationState);
  const { openRightModalNoOpacity, openFullModal } = useModal();
  const {
    data: projectedDueAmounts,
    isLoading,
    isError,
  } = useGetCompanyEarlyPayProjectedDueAmounts(company.id, issuer);

  const firstProjectedDue = projectedDueAmounts?.earlyPayProjectedDueAmounts[0];
  const earlyPayDate = firstProjectedDue?.earlyPayDate
    ? DateTime.fromISO(firstProjectedDue.earlyPayDate)
    : null;

  const dateString = earlyPayDate
    ? earlyPayDate.toLocaleString({
        month: 'short',
        day: 'numeric',
      })
    : 'No date available';

  const diffDays = earlyPayDate
    ? Math.floor(earlyPayDate.diffNow('days').days)
    : null;

  const inDaysString =
    diffDays === 0
      ? 'Today'
      : `In ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;

  const amount = firstProjectedDue?.projectedDue
    ? formatCurrency(firstProjectedDue.projectedDue / 100)
    : '$0.00';

  const getNextRebateDate = (fromDate: DateTime, rebateDate: number) => {
    const baseDate =
      rebateDate <= fromDate.day ? fromDate.plus({ months: 1 }) : fromDate;

    if (rebateDate > (baseDate.daysInMonth ?? 0)) {
      return baseDate.endOf('month');
    } else {
      return baseDate.set({ day: rebateDate });
    }
  };

  const handleViewScheduleClick = () => {
    if (!projectedDueAmounts?.earlyPayProjectedDueAmounts) return;

    if (isMobileView) {
      openFullModal(
        <EarlyPayScheduleDrawer
          projectedDueAmounts={projectedDueAmounts.earlyPayProjectedDueAmounts}
        />,
      );
    } else {
      openRightModalNoOpacity(
        <EarlyPayScheduleDrawer
          projectedDueAmounts={projectedDueAmounts.earlyPayProjectedDueAmounts}
        />,
      );
    }
  };

  const rebatePct = earlyPayConfig
    ? earlyPayConfig.rebatePct
    : creditLine?.repaymentConfigurations?.earlyPay?.rebatePct;

  const rebateDateInfo = earlyPayConfig
    ? earlyPayConfig.rebateDate
    : creditLine?.repaymentConfigurations?.earlyPay?.rebateDate;

  const graceDays = earlyPayConfig
    ? earlyPayConfig.graceDays
    : creditLine?.repaymentConfigurations?.earlyPay?.graceDays;

  if (isLoading) {
    return <Skeleton w="100%" h={rem(200)} />;
  }

  if (isError) {
    return (
      <Stack p={rem(24)} w="100%" h={rem(200)} bg="neutral.0" gap={rem(8)}>
        {' '}
        <Text fw={500} c={theme.colors.blackish[0]}>
          Unable to access early pay.
        </Text>{' '}
      </Stack>
    );
  }

  return (
    <Stack p={rem(24)} w="100%" h={rem(200)} bg="neutral.0" gap={rem(8)}>
      <Text fw={500} c={theme.colors.blackish[0]}>
        Next automatic early pay*
      </Text>
      <Group>
        <Flex
          c="promote.5"
          bg="promote.0"
          h={rem(32)}
          w={rem(32)}
          align="center"
          justify="center"
          sx={{ borderRadius: rem(4) }}
        >
          <PiCalendarLight />
        </Flex>
        <Box>
          <Text fz={rem(14)}>{dateString}</Text>
          <Text fz={rem(12)} lh="1">
            {inDaysString}
          </Text>
        </Box>
        <Text ml="auto" fz={rem(26)} fw={500}>
          {amount}
        </Text>
      </Group>
      <Text fz={rem(12)} c="neutral.7">
        Early pay cash back bonus of {rebatePct}% deposited on{' '}
        {earlyPayDate && rebateDateInfo !== undefined
          ? getNextRebateDate(earlyPayDate, rebateDateInfo).toLocaleString(
              DateTime.DATE_SHORT,
            )
          : null}
      </Text>
      <Flex mt="auto" justify="space-between" align="center">
        <Anchor onClick={handleViewScheduleClick}>
          <Flex align="center" gap={rem(8)} fz={rem(14)}>
            <PiCalendarLight /> View Schedule
          </Flex>
        </Anchor>
        <Popover withinPortal width={rem(300)} withArrow>
          <Popover.Target>
            <Anchor fz={rem(12)}>*What&apos;s this?</Anchor>
          </Popover.Target>
          <Popover.Dropdown>
            This represents the &quot;early pay&quot; amount that will be paid
            automatically on {earlyPayDate?.toLocaleString(DateTime.DATE_SHORT)}
            . The balance shown is a snapshot of what is currently due on this
            date, and is subject to change if payments are made before the
            scheduled date or if additional credit transactions are made before{' '}
            {earlyPayDate
              ?.minus({ days: graceDays })
              .toLocaleString(DateTime.DATE_SHORT)}
            .
          </Popover.Dropdown>
        </Popover>
      </Flex>
    </Stack>
  );
};
