import { useTreatment } from 'providers/split.provider';
import { useSplitClient } from '@splitsoftware/splitio-react';

export const useIntlPaymentsFeatureFlag = () => {
  const featureFlag = useTreatment('international_payments');
  return featureFlag.treatment === 'on';
};

export const useMultiAccountFeatureFlag = () => {
  const featureFlag = useTreatment('multi_account');
  return featureFlag.treatment === 'on';
};

export const useSpendPlansFeatureFlag = () => {
  const featureFlag = useTreatment('spend_plans');
  return featureFlag.treatment === 'on';
};

export const useBillPayFeatureFlag = () => {
  const featureFlag = useTreatment('bill_pay_internal');
  return featureFlag.treatment === 'on';
};

export const useBillPayCreditFlag = () => {
  const featureFlag = useTreatment('bill_pay_credit');
  return featureFlag.treatment === 'on';
};

export const useEliteFeatureFlag = () => {
  const featureFlag = useTreatment('elite');
  return featureFlag.treatment === 'on';
};

export const useProvePrefillFeatureFlag = (): {
  treatmentOn: boolean;
  treatmentReady: boolean;
} => {
  const splitClient = useSplitClient({ updateOnSdkTimedout: true });
  const featureFlag = useTreatment('prove_prefill');
  return {
    treatmentOn: featureFlag.treatment === 'on',
    treatmentReady: splitClient.isReady || splitClient.isTimedout,
  };
};

export const useAllowUserInvite = () => {
  const featureFlag = useTreatment('Invite_users');
  return featureFlag.treatment === 'on';
};

export const useRecipientDetailsFeatureFlag = () => {
  const featureFlag = useTreatment('recipient_details_page');
  return featureFlag.treatment === 'on';
};

/**
 * If this is true, we are currently undergoing active "maintenance". False is normal operation
 */
export const useMaintenanceFlag = () => {
  const featureFlag = useTreatment('maintenance_flag');
  return featureFlag.treatment === 'on';
};

export const useEarlyPayProjectedDueFlag = () => {
  const featureFlag = useTreatment('early_pay_projected_due');
  return featureFlag.treatment === 'on';
};

export const useAdsEcommerceIntegrations = () => {
  const featureFlag = useTreatment('integrations_ads_ecommerce');
  return featureFlag.treatment === 'on';
};

export const useCheatSheetFeatureFlag = () => {
  const featureFlag = useTreatment('cheat_sheet');
  return featureFlag.treatment === 'on';
};

export const useIssueDebitCardFeatureFlag = () => {
  const featureFlag = useTreatment('issue_debit_card');
  return featureFlag.treatment === 'on';
};
