import { DateTime } from 'luxon';
import { forwardRef, useState } from 'react';
import { Box, Button, Center, Loader, rem, Select, Text } from '@mantine/core';
import {
  DepositAccount,
  ChargeCardAccount,
} from '@services/flexbase/banking.model';
import { FlexIconShort } from 'assets/svg';
import AccountBox from '@common/account-box/account-box';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { formatCurrency } from '@utilities/formatters';
import AccountSelection from '@common/account-selection';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { useUpdateAutoPayForChargeAccount } from '@queries/use-charge-card-accounts';

type ItemProps = {
  label: string;
  balance?: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SelectDepositAccount = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, balance, ...others }: ItemProps, ref) => (
    <Box p="0" ref={ref} {...others}>
      <AccountBox
        showArrow={false}
        isListItem={true}
        headerText={label}
        rotateArrow={false}
        subheaderText={balance}
        icon={<FlexIconShort />}
      />
    </Box>
  ),
);
SelectDepositAccount.displayName = 'SelectDepositAccount';

const getSelectedAccount = (id: string, accountsList: DepositAccount[]) => {
  return accountsList.find((acc) => acc.id === id) ?? null;
};

type ChargeAutoPayProps = {
  isChargeAccountsError: boolean;
  chargeAccount: ChargeCardAccount;
  refetchChargeAccounts: () => void;
};

const ChargeAutoPay = ({
  chargeAccount,
  isChargeAccountsError,
  refetchChargeAccounts,
}: ChargeAutoPayProps) => {
  const {
    data: depositAccountsData,
    isError: isDepositAccountsError,
    isLoading,
    refetch: refetchDepositAccounts,
  } = useGetDepositAccounts();

  const { mutate: updateAutoPay, isPending } =
    useUpdateAutoPayForChargeAccount();

  const isError = isChargeAccountsError || isDepositAccountsError;
  const refreshData = () => {
    if (isChargeAccountsError) {
      refetchChargeAccounts();
    }

    if (isDepositAccountsError) {
      refetchDepositAccounts();
    }
  };

  const minimumDue = Number(chargeAccount?.remainingAmountDue) / 100;

  const depositAccounts =
    depositAccountsData?.accounts
      .filter((acc) => acc.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((acc) => {
        return {
          ...acc,
          plaidOrDeposit: 'deposit',
        } as DepositAccount;
      }) ?? [];

  const depositAccountsDropdownData = depositAccounts.map((acc) => ({
    value: acc.id,
    label: acc.nickName ?? acc.name,
    balance: `${formatCurrency(
      acc.balance / 100,
    )} / ${capitalizeOnlyFirstLetter(acc.accountType)} ${getPaddedAccountMask(
      acc.accountNumber,
      4,
    )}`,
  }));

  const initAccount = getSelectedAccount(
    chargeAccount?.repaymentConfigurations?.autoPay?.depositAccountId,
    depositAccounts,
  );

  const [selectedAccount, setSelectedAccount] = useState<DepositAccount | null>(
    initAccount,
  );

  const currentAccount = selectedAccount ?? initAccount;

  const handleAccountChange = (value: string | null) => {
    if (!value) return;
    updateAutoPay(
      {
        accountId: chargeAccount.id,
        depositAccountId: value,
      },
      {
        onSuccess() {
          setSelectedAccount(getSelectedAccount(value, depositAccounts));
        },
      },
    );
  };

  if (isError) {
    return (
      <Box
        p="xl"
        h="100%"
        bg="neutral.0"
        sx={(theme) => ({
          borderRadius: theme.defaultRadius,
          border: `1px solid ${theme.colors.neutral[1]}`,
        })}
      >
        <Text c="neutral.7" fw={500}>
          Auto pay settings
        </Text>
        <Text size="sm" mt="sm">
          Something went wrong trying to retrieve auto pay information
        </Text>
        <Button mt="md" onClick={refreshData}>
          Reload data
        </Button>
      </Box>
    );
  }

  return (
    <Box
      p="xl"
      h="100%"
      bg="neutral.0"
      sx={(theme) => ({
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.colors.neutral[1]}`,
      })}
    >
      <Text c="neutral.7" fw={500}>
        Auto pay settings
      </Text>
      {isLoading ? (
        <Center h="80%">
          <Loader size="md" />
        </Center>
      ) : (
        <>
          <Text fw={500} fz={rem(26)} mb="xl">
            {chargeAccount?.nextRepaymentDueDate
              ? DateTime.fromISO(chargeAccount?.nextRepaymentDueDate).toFormat(
                  'LLL dd',
                )
              : DateTime.now().toFormat('LLL dd')}{' '}
            for {formatCurrency(minimumDue)}
          </Text>
          <Text c="neutral.7" fw={500} mb="sm">
            Default payment account
          </Text>
          {currentAccount ? (
            <AccountSelection
              disabled={isPending}
              accounts={depositAccounts}
              currentAccount={currentAccount}
              onAccountChange={(account) => handleAccountChange(account.id)}
            />
          ) : (
            <Select
              onChange={handleAccountChange}
              data={depositAccountsDropdownData}
              renderOption={({ option }) => (
                <SelectDepositAccount {...option} />
              )}
              placeholder="Select a deposit account"
              rightSection={isPending && <Loader size="xs" />}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ChargeAutoPay;
