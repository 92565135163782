import { Box, Group, rem, Tooltip, useMantineTheme } from '@mantine/core';
import useModal from 'components/modal/modal-hook';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ViewRecipientModal from './view-recipient';
import DeleteVendor from '@common/delete-vendor';
import { Recipient } from 'types/recipient';
import EditRecipientModal from './edit-recipient-modal/edit-recipient-modal';
import {
  PiEye,
  PiPaperPlaneTilt,
  PiPencilSimpleLine,
  PiTrash,
} from 'react-icons/pi';

const RecipientActions = ({
  refetch,
  recipient,
}: {
  refetch: () => void;
  recipient: Recipient;
}) => {
  const theme = useMantineTheme();
  const { openModal, openCenterModalUnstyled, closeAllModals, openRightModal } =
    useModal();
  const navigate = useNavigate();

  const handleRowClick = (row: Recipient) => {
    openModal(row.name, <ViewRecipientModal recipient={row} />);
  };

  const handleNavigateToSendPaymentFlow = (currentRecipient: Recipient) => {
    navigate({
      pathname: '/payments/outgoing/recipient',
      search: createSearchParams({
        recipient: currentRecipient.name,
        recipientId: currentRecipient.id,
      }).toString(),
    });
  };

  const openEditRecipientModal = () => {
    openRightModal(
      <EditRecipientModal closeModal={closeAllModals} recipient={recipient} />,
    );
  };

  return (
    <Group
      w="80%"
      justify="space-between"
      c="primarySecondarySuccess.8"
      style={{ flexWrap: 'nowrap', gap: rem(8) }}
    >
      <Tooltip label="Edit recipient" position="bottom">
        <Box>
          <PiPencilSimpleLine
            size={'1.25rem'}
            onClick={() => openEditRecipientModal()}
          />
        </Box>
      </Tooltip>
      <Tooltip label="View recipient details" position="bottom">
        <Box>
          <PiEye size={'1.25rem'} onClick={() => handleRowClick(recipient)} />
        </Box>
      </Tooltip>
      <Tooltip label="Send a payment" position="bottom">
        <Box>
          <PiPaperPlaneTilt
            size={'1.25rem'}
            onClick={() => handleNavigateToSendPaymentFlow(recipient)}
          />
        </Box>
      </Tooltip>
      <Tooltip label="Remove recipient" position="bottom">
        <Box>
          <PiTrash
            size={'1.25rem'}
            color={theme.colors.critical[6]}
            onClick={() =>
              openCenterModalUnstyled(
                <DeleteVendor
                  closeModal={closeAllModals}
                  recipientId={recipient.id}
                  recipientName={recipient.name}
                  refresh={refetch}
                />,
                'md',
              )
            }
          />
        </Box>
      </Tooltip>
    </Group>
  );
};

export default RecipientActions;
