import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import { BrowserRouter } from 'react-router-dom';
import {
  RecoilRoot,
  RecoilValue,
  RecoilState,
  useRecoilCallback,
} from 'recoil';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { getEnvironment } from './utilities/url/window-helpers';
import FlexbaseRoutes from './routes';
import ZenDesk from 'components/zendesk/zendesk';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { FacebookPixel } from './services/analytics/pixel';
import Loadable from './components/loading/loadable';
import TagManager from 'react-gtm-module';
import { AuthProvider } from './providers/auth.provider';
import { RouteSectionProvider } from './providers/route-context';
import QueryProvider from 'providers/query-provider';
import { SplitProvider } from 'providers/split.provider';
import { BackgroundProvider } from './providers/background-provider';
import { MantineProviders } from 'providers/mantine-providers';
import { SardineProvider } from '@sardine-ai/react-js-wrapper';
// import { init } from '@fullstory/browser';

type Nexus = {
  get?: <T>(atom: RecoilValue<T>) => T;
  getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>;
  set?: <T>(
    atom: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void;
  reset?: (atom: RecoilState<any>) => void;
};

const nexus: Nexus = {};

// https://github.com/luisanton-io/recoil-nexus
const RecoilNexus = () => {
  nexus.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );

  nexus.getPromise = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getPromise(atom);
      },
    [],
  );

  //nexus.set = useRecoilCallback(({ set }) => set, [])
  useRecoilCallback(({ set }) => {
    nexus.set = set;
    return async () => undefined;
  })();

  nexus.reset = useRecoilCallback(({ reset }) => reset, []);

  return null;
};

// https://github.com/segmentio/analytics-next/tree/master/packages/browser
export const analytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.VITE_APP_SEGMENT_KEY || '',
  },
  {
    user: {
      persist: false,
    },
  },
);

function App() {
  const hostEnv = getEnvironment(window.location.host);
  FacebookPixel.init();

  if (hostEnv === 'production' || hostEnv === 'development') {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_KEY,
      release: import.meta.env.VITE_APP_GITHUB_SHA,
      replaysSessionSampleRate: hostEnv === 'production' ? 0.3 : 0.0,
      replaysOnErrorSampleRate: hostEnv === 'production' ? 1.0 : 0.0,
      // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: hostEnv === 'production' ? 0.3 : 1.0,
      environment: hostEnv,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        "Can't find variable: _AutofillCallbackHandler",
      ],
    });
  }

  if (hostEnv === 'development' || hostEnv === 'local') {
    TagManager.initialize({
      gtmId: 'GTM-WFHSQ3V',
    });
  }

  if (hostEnv === 'production') {
    TagManager.initialize({
      gtmId: 'GTM-MTJK9FZ',
    });
    // init({ orgId: 'o-1ZG1VB-na1' });
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ height: '100vh' }} id="app-body">
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="r9grrg8jfhkdxbdbo6zaifyynbfluf"
          />
          <script
            type="text/javascript"
            src="https://js.verygoodvault.com/vgs-show/2.1.0/show.js"
          ></script>
        </Helmet>
        <SardineProvider
          clientId={import.meta.env.VITE_APP_SARDINE_KEY}
          environment={hostEnv === 'production' ? 'production' : 'sandbox'}
        >
          <QueryProvider>
            <RecoilRoot>
              <RecoilNexus />
              <SplitProvider>
                <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
                  <MantineProviders>
                    {hostEnv === 'production' && <ZenDesk />}
                    <AuthProvider>
                      <Loadable>
                        <RouteSectionProvider>
                          <BackgroundProvider>
                            <FlexbaseRoutes />
                          </BackgroundProvider>
                        </RouteSectionProvider>
                      </Loadable>
                    </AuthProvider>
                  </MantineProviders>
                </BrowserRouter>
              </SplitProvider>
            </RecoilRoot>
          </QueryProvider>
        </SardineProvider>
      </div>
    </>
  );
}

export default App;
