import { Box, Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useNavigate } from 'react-router-dom';
import { FilterConfig, useFilterModal } from '@common/filter/filter-modal';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import { PaymentWithInvoiceNumber } from '../payments/payments';
import { FilterChips } from '@common/filter/filter-chips';
import { PiFunnel, PiMagnifyingGlass, PiPlusBold } from 'react-icons/pi';

type Props = {
  placeholder: string;
  data?: PaymentWithInvoiceNumber[];
  filtersData?: FilterConfig[];
  showFilters?: boolean;
  useFilters: () => ActiveFiltersReturnType<any>;
  useFiltersModal: () => ActiveFiltersReturnType<any>;
};

const Header = ({
  placeholder,
  data,
  filtersData = [],
  useFilters,
  useFiltersModal,
}: Props) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');

  const filterHook = useFilters();
  const modalFilterHook = useFiltersModal();
  const {
    activeFiltersArray,
    removeFilter,
    getFilterByKey,
    addFilter,
    removeAllFilters,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const navigate = useNavigate();
  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = value.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const inputWidth = useMobileView ? '100%' : 368;

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filtersData);
  };

  return (
    <>
      <Group justify="space-between" mb="md">
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          })}
          mb="md"
        >
          <Box
            sx={(_, helpers) => ({
              display: 'flex',
              gap: theme.spacing.md,
              [helpers.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
          >
            <TextInput
              aria-label={placeholder}
              w={inputWidth}
              placeholder={placeholder}
              leftSection={
                <PiMagnifyingGlass
                  size="1.25rem"
                  color={theme.colors.blackish[0]}
                />
              }
              value={getFilterByKey('search')?.filterValue || ''}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <Button
              variant="outline"
              rightSection={<PiFunnel />}
              onClick={() => openModal()}
              disabled={data?.length === 0}
            >
              Filters
            </Button>
          </Box>
        </Box>
        {!isBookkeeper && (
          <Group>
            <Button
              type="button"
              bg={'primarySecondarySuccess.6'}
              leftSection={<PiPlusBold />}
              onClick={handleCreateInvoice}
            >
              Add New Bill
            </Button>
          </Group>
        )}
      </Group>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};

export default Header;
