import { forwardRef, ReactNode, useMemo, useRef, useState } from 'react';
import {
  Box,
  CloseButton,
  Combobox,
  ComboboxItem,
  ComboboxItemGroup,
  ComboboxProps,
  InputBase,
  InputBaseProps,
  useCombobox,
} from '@mantine/core';
import { PiCaretDownLight, PiCaretUpLight } from 'react-icons/pi';

/**
 * FlexbaseSelect is a wrapper around Mantine's Combobox component that provides a more flexible API
 * to meet most of the use cases we've needed so far in our application.
 *
 * Most of this code has been adapted from the examples provided by Mantine in their documentation:
 * https://mantine.dev/combobox/
 *
 * Specifically the "Searchable select with groups" and "Select with custom option" examples
 *
 * If you want to add the previous `creatable` functionality, you can use the `nothingFound` prop to render a custom component when no options are found.
 * You can use that prop to add the create functionality. This can be seen in document-upload-modal.tsx
 */

export type SelectItem = ComboboxItem & Record<string, unknown>;
export type FlexbaseSelectProps = {
  id?: string;
  value?: string | null;
  label?: ReactNode;
  onChange: (value: string | null) => void;
  data?: SelectItem[];
  groupedData?: ComboboxItemGroup<SelectItem>[];
  placeholder?: string;
  searchable?: boolean;
  filter?: (item: SelectItem, search: string) => boolean;
  nothingFound?: React.FC<{ value: string }>;
  clearable?: boolean;
  maxDropdownHeight?: number; // controls the max height of the dropdown
  itemComponent?: React.FC<SelectItem>;
  comboboxProps?: ComboboxProps;
  inputProps?: React.BaseHTMLAttributes<HTMLInputElement> & InputBaseProps;
  keepDropdownOpenOnSelect?: boolean;
  initiallyOpened?: boolean;
  footer?: ReactNode;
  error?: string;
  'data-testid'?: string;
};

const FlexbaseSelect = ({
  id,
  value,
  label,
  onChange,
  data,
  groupedData,
  placeholder,
  searchable,
  nothingFound,
  clearable,
  maxDropdownHeight,
  itemComponent,
  comboboxProps,
  inputProps,
  keepDropdownOpenOnSelect,
  initiallyOpened,
  footer,
  error,
  'data-testid': dataTestId,
}: FlexbaseSelectProps) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // /**
  //  * Item selection uses the onMouseDown handler under the hood.
  //  * onMouseDown handles closing the dropdown and propagating the value up to the consumer's onChange handler
  //  *
  //  * To prevent the dropdown from closing, we provide a custom itemComponent and override the onMouseDown handler and call onChange manually
  //  */
  const ItemPersistDropdown = useMemo(
    () =>
      forwardRef<HTMLDivElement, ComboboxItem>(function f(
        { label: itemLabel, value: itemValue, ...rest }: ComboboxItem,
        _ref,
      ) {
        const handleMouseDown = () => {
          onChangeRef.current?.(
            typeof itemValue === 'undefined' ? null : itemValue,
          );
        };

        // Check if a different custom component was provided, but still override onMouseDown
        const Item = itemComponent;

        return Item ? (
          <Item
            label={itemLabel}
            value={itemValue}
            {...rest}
            onMouseDown={handleMouseDown}
          />
        ) : (
          <Box onMouseDown={handleMouseDown}>{itemLabel}</Box>
        );
      }),
    [itemComponent],
  );

  const combobox = useCombobox({
    defaultOpened: initiallyOpened,
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const flattenedData = useMemo(
    () => data || groupedData?.flatMap((group) => group.items) || [],
    [data, groupedData],
  );
  const selectedOption = flattenedData.find((item) => item.value === value);
  const [search, setSearch] = useState(selectedOption?.label || '');

  const filteredData = useMemo(() => {
    if (groupedData) {
      return groupedData.map((group) => {
        const filteredOptions = searchable
          ? group.items.filter((item) => {
              const keys = Object.keys(item);
              for (const key of keys) {
                const field = item[key];
                if (
                  typeof field === 'string' &&
                  field.toLowerCase().includes(search.toLowerCase())
                ) {
                  return true;
                }
              }
              return false;
            })
          : group.items;

        return { ...group, items: filteredOptions };
      });
    }
    return searchable
      ? flattenedData.filter((item) => {
          const keys = Object.keys(item);
          for (const key of keys) {
            const field = item[key];
            if (
              typeof field === 'string' &&
              field.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
        })
      : data;
  }, [data, groupedData, search]);

  const isOptionsEmpty = useMemo(() => {
    if (groupedData !== undefined) {
      return (
        !filteredData?.length ||
        (filteredData as ComboboxItemGroup<SelectItem>[])?.every(
          (group) => !group?.items?.length,
        )
      );
    }
    return !filteredData?.length;
  }, [filteredData, groupedData]);

  const ItemComponent = itemComponent;

  const options = useMemo(() => {
    if (groupedData) {
      return filteredData?.map((group) => {
        const groupOptions = (
          group as ComboboxItemGroup<SelectItem>
        )?.items.map((item, index) => (
          <Combobox.Option
            id={id ? `${id}-${index}` : undefined}
            value={item.value}
            key={item.value}
          >
            {keepDropdownOpenOnSelect ? (
              <ItemPersistDropdown {...item} />
            ) : ItemComponent ? (
              <ItemComponent {...item} />
            ) : (
              item.label
            )}
          </Combobox.Option>
        ));

        return (
          <Combobox.Group
            label={group.group as string}
            key={group.group as string}
          >
            {groupOptions}
          </Combobox.Group>
        );
      });
    }
    return (filteredData as SelectItem[])?.map((item, index) => (
      <Combobox.Option
        id={id ? `${id}-${index}` : undefined}
        value={item.value}
        key={item.value}
      >
        {keepDropdownOpenOnSelect ? (
          <ItemPersistDropdown {...item} />
        ) : ItemComponent ? (
          <ItemComponent {...item} />
        ) : (
          item.label
        )}
      </Combobox.Option>
    ));
  }, [groupedData, filteredData]);

  const handleSearch: React.FormEventHandler<HTMLInputElement> = (event) => {
    combobox.openDropdown();
    combobox.updateSelectedOptionIndex();
    setSearch(event.currentTarget.value);
  };

  const handleBlur = () => {
    combobox.closeDropdown();
    setSearch(selectedOption ? selectedOption.label : search);
  };

  const renderComboboxTarget = () => {
    /**
     * These need to be rendered as separate components because the prop types
     * change based on the `component` prop passed to `InputBase`. Namely the `placeholder` prop
     */
    if (searchable) {
      return (
        <Combobox.Target>
          <InputBase
            id={id}
            label={label}
            rightSection={
              !!value && clearable ? (
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => onChange(null)}
                  aria-label="Clear value"
                />
              ) : combobox.dropdownOpened ? (
                <PiCaretUpLight />
              ) : (
                <PiCaretDownLight />
              )
            }
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={handleBlur}
            rightSectionPointerEvents={!value || !clearable ? 'none' : 'all'}
            multiline
            onChange={handleSearch}
            value={search}
            placeholder={placeholder}
            error={error}
            data-testid={dataTestId}
            {...inputProps}
          />
        </Combobox.Target>
      );
    }
    return (
      <Combobox.Target>
        <InputBase
          id={id}
          label={label}
          pointer
          rightSection={
            !!value && clearable ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => onChange(null)}
                aria-label="Clear value"
              />
            ) : combobox.dropdownOpened ? (
              <PiCaretUpLight />
            ) : (
              <PiCaretDownLight />
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={!value || !clearable ? 'none' : 'all'}
          multiline
          value={selectedOption ? selectedOption.label : ''}
          placeholder={placeholder}
          sx={{ caretColor: 'transparent' }}
          error={error}
          data-testid={dataTestId}
          {...inputProps}
        />
      </Combobox.Target>
    );
  };

  const NothingFound = nothingFound;

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        onChange(val);
        if (searchable) {
          setSearch(
            flattenedData.find((item) => item.value === val)?.label || '',
          );
        }
        combobox.closeDropdown();
      }}
      {...comboboxProps}
    >
      {renderComboboxTarget()}
      <Combobox.Dropdown>
        <Combobox.Options
          mah={maxDropdownHeight || 220}
          sx={{ overflowY: 'auto' }}
        >
          {isOptionsEmpty ? (
            <Combobox.Empty>
              {NothingFound ? <NothingFound value={search} /> : 'Nothing found'}
            </Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
        {footer && <Combobox.Footer>{footer}</Combobox.Footer>}
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default FlexbaseSelect;
