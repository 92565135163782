import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  PiArrowsLeftRight,
  PiCaretDown,
  PiHandCoins,
  PiWallet,
} from 'react-icons/pi';
import useModal from '@common/modal/modal-hook';
import MoveFunds from 'areas/banking/move-funds/move-funds';
import AddFunds from 'areas/banking/add-funds/add-funds';

type MenuItemWithIconProps = {
  id: string;
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
};

const MenuItemWithIcon = ({
  onClick,
  label,
  icon,
  id,
}: MenuItemWithIconProps) => (
  <Menu.Item leftSection={icon} onClick={onClick} id={id} data-testid={id}>
    {label}
  </Menu.Item>
);

const BankingActionButton = () => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { classes, theme } = useStyles(opened);
  const { openRightModal } = useModal();

  const openMoveFundsModal = () => {
    openRightModal(<MoveFunds />);
  };

  const openAddFundsModal = () => {
    openRightModal(<AddFunds />);
  };

  const menuOptions = [
    {
      onClick: () => navigate('/payments/outgoing/recipient'),
      icon: <PiHandCoins size={'1.25rem'} />,
      label: 'Send payment',
      id: 'send-payment',
    },
    {
      onClick: () => openAddFundsModal(),
      icon: <PiWallet size={'1.25rem'} />,
      label: 'Add or receive funds',
      id: 'add-funds',
    },
  ];

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      id="banking-button-header"
      data-testid="banking-button-header"
      classNames={{ item: classes.item }}
    >
      <Menu.Target>
        <Button
          size="sm"
          rightSection={
            <PiCaretDown
              fill={theme.colors.contentBackground[2]}
              size={'1.25rem'}
              className={classes.icon}
            />
          }
        >
          Move Money
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuOptions.map((option) => (
          <MenuItemWithIcon
            id={option.id}
            key={option.id}
            icon={option.icon}
            label={option.label}
            onClick={option.onClick}
          />
        ))}
        <Menu.Item
          id="transfer-between-accounts"
          data-testid="transfer-between-accounts"
          leftSection={<PiArrowsLeftRight size={'1.25rem'} />}
          onClick={openMoveFundsModal}
        >
          Transfer between your accounts
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default BankingActionButton;

const useStyles = createStyles((theme, opened: boolean) => ({
  item: {
    fill: theme.primaryColor,
    color: theme.primaryColor,
    '&:hover': {
      fill: theme.colors.primarySecondarySuccess[2],
      color: theme.colors.primarySecondarySuccess[2],
    },
  },

  icon: {
    transition: '0.2s ease-out',
    transform: opened ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
}));
