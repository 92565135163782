import {
  Card,
  Text,
  Avatar,
  Flex,
  Box,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { formatCurrency } from '@utilities/formatters';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { ParsedAccount } from 'types/parsed-account';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { DateTime } from 'luxon';
import { PiBank } from 'react-icons/pi';

type ReviewBodyBankingAccountProps = {
  recipientAccount?: ParsedAccount;
  recipientName: string;
  sendFrom?: DepositAccount | PlaidAccount;
  sendOnDate: Date;
  invoiceTotalFormatted: string;
  memo: string;
};

export default function ReviewBodyBankingAccount({
  recipientAccount,
  recipientName,
  sendFrom,
  sendOnDate,
  invoiceTotalFormatted,
  memo,
}: ReviewBodyBankingAccountProps) {
  const theme = useMantineTheme();
  const sendOnDateLabel =
    DateTime.fromJSDate(sendOnDate).toLocaleString(DateTime.DATE_MED) || '';

  const type: Record<'wire' | 'ach', string> = {
    wire: 'Wire',
    ach: 'ACH',
  };

  return (
    <>
      <Card p="xxl" bg="#ddd4c3">
        <Text size="md">
          {type[recipientAccount?.type as 'ach' | 'wire']} to {recipientName}
        </Text>
        <Text size={rem(40)} style={{ lineHeight: rem(40) }}>
          {invoiceTotalFormatted}
        </Text>
      </Card>

      <Text mt="xl" fw="500" size="xl">
        Recipient details
      </Text>
      <Text size="sm" mt="md" mb="xxs">
        Recipient
      </Text>
      <Card p="md">
        <Flex align="center">
          <Avatar radius="xl" mr="sm" w={rem(36.8)} h={rem(36.8)}>
            {getInitialsOfNames(recipientName)?.slice(0, 1).toUpperCase()}
          </Avatar>
          {recipientName}
        </Flex>
      </Card>

      <Text size="sm" mt="md" mb="xxs">
        Send to
      </Text>

      <Card p="md">
        <Flex align="center">
          <Avatar radius="xl" mr="sm" w={rem(36.8)} h={rem(36.8)}>
            <PiBank size={'1rem'} />
          </Avatar>
          <Box>
            <Text>
              {recipientAccount?.nickName ?? recipientAccount?.name} via{' '}
              {recipientAccount?.type.toUpperCase()}
            </Text>
            <Text size="sm" color={theme.colors.neutral[6]}>
              Account{' '}
              {GetPaddedAccountMask(recipientAccount?.accountNumber ?? '', 2)} |
              Routing{' '}
              {GetPaddedAccountMask(recipientAccount?.routingNumber ?? '', 2)}
            </Text>
          </Box>
        </Flex>
      </Card>

      <Box mt="xxl">
        <Text mt="xl" fw="500" mb="md" size="xl">
          Transferring from
        </Text>

        <Text size="sm" mt="md" mb="xxs">
          Pay from
        </Text>
        <Card p="md">
          <Flex align="center">
            <Avatar radius="xl" mr="sm" w={rem(36.8)} h={rem(36.8)}>
              <PiBank size={'1rem'} />
            </Avatar>
            <Box>
              {sendFrom?.plaidOrDeposit === 'deposit' ? (
                <>
                  <Text>{sendFrom.nickName ?? sendFrom.name}</Text>
                  <Text size="sm" color={theme.colors.neutral[6]}>
                    {formatCurrency(sendFrom.available)} available *{' '}
                    {capitalizeOnlyFirstLetter(sendFrom.accountType)}
                  </Text>
                </>
              ) : (
                <>
                  <Text>{sendFrom?.accountName}</Text>
                  <Text size="sm" color={theme.colors.neutral[6]}>
                    {sendFrom?.bankName} -{' '}
                    {`${GetPaddedAccountMask(sendFrom?.last4 ?? '', 4)}`}
                  </Text>
                </>
              )}
            </Box>
          </Flex>
        </Card>

        <Flex justify="space-between" align="center" mt="xl">
          <Box>
            <Text size="sm">Sending on</Text>
            <Text size="md" fw="500">
              {sendOnDateLabel}
            </Text>
          </Box>
          <Box>
            <Text size="sm" mt="xxs" color={theme.colors.neutral[7]}>
              ETA 1 day
            </Text>
          </Box>
        </Flex>

        <Text mt="xxl" fw="500" size="xl">
          Memo
        </Text>
        <Text size="md" w="80%">
          {memo}
        </Text>
      </Box>
    </>
  );
}
