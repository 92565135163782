import {
  Alert,
  Box,
  Button,
  Collapse,
  ComboboxItem,
  Container,
  Group,
  Radio,
  Stack,
  Text,
  Title,
  rem,
  useMantineTheme,
  Select,
  Grid,
} from '@mantine/core';
import Input from 'components/input/flexbase-input';
import { useForm } from '@mantine/form';
import { useMemo } from 'react';
import { Group as CardInfoGroup } from 'constants/card-info';
import { CustomMantineStyles } from '@common/cards-styles';
import { RequiredFieldValidator } from 'utilities/validators/validate-required';
import {
  useCreateCardCategory,
  useIssueCreditCardMutation,
  useNet60CreditCards,
} from '@queries/use-credit-cards';
import { cardIsCreditCard } from '@services/flexbase/card.model';
import {
  LIMIT_INTERVALS,
  LimitInterval,
} from '@utilities/content/limit-intervals';
import { formatCurrency } from '@utilities/formatters';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from '../../../recoil-state/application/onboarding-form.state';
import { useCreditBalance } from '@queries/use-credit-balance';
import { showNotification } from '@mantine/notifications';
import { UserInfoState } from 'types/user-info';
import { SelectItem } from 'components/select/flexbase-select';
import { CalendarIcon } from '../../../assets/svg';
import { DatePickerInput } from '@mantine/dates';
import { DateTime } from 'luxon';
import CardCategorySelect from '@common/select/card-category-select';
import FlexNumberInput from '../../../components/flex-number-input';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import Multiselect from '@common/custom-multiselect';
import {
  useActiveSpendPlans,
  useLinkCardToSpendPlan,
} from '@queries/use-spend-plans';
import { useGetMe } from '@queries/use-get-me';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import {
  SpendPlanAdminView,
  SpendPlanView,
  SpendPlanViewFrequencyEnum,
} from '@flexbase-eng/types/dist/accounting';
import { useGetUsers } from '@queries/use-users';
import { uniq } from 'underscore';
import { isSpendPlanAdminView } from '@utilities/is-spend-plan-admin-view';

type Props = {
  closeModal: () => void;
  card?: { id: string };
  spendPlan?: SpendPlanAdminView | SpendPlanView;
  method?: 'credit' | 'spendPlan';
};

type CardType = 'physical' | 'virtual';
type YesNo = 'yes' | 'no';

type CardForm = {
  userId: string;
  spendPlanId: string;
  cardType: CardType;
  cardName: string;
  spendingLimit: number;
  spendingLimitInterval: LimitInterval | undefined;
  spendingLimitFuture: number; // for spend plans
  groups: CardInfoGroup[];
  categoryId?: string;
  terminateAt: string | null;
  limitMerchantCategories: YesNo;
  autoExpire: YesNo;
};

const STACK_SPACING = 'lg';
const RADIO_SPACING = 'lg';

const IssueCardForm = ({
  closeModal,
  card,
  spendPlan,
  method = 'credit',
}: Props) => {
  const spendPlanFlagEnabled = useSpendPlansFeatureFlag();
  const theme = useMantineTheme();
  const companyId = useRecoilValue(CompanyIdState);
  const user = useRecoilValue(UserInfoState);
  const isAdmin = useRecoilValue(IsAdmin);
  const { data } = useCreditBalance(companyId);
  const { data: users = [] } = useGetUsers();
  const { data: me } = useGetMe();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const { data: spendPlans } = useActiveSpendPlans({
    enabled: spendPlanFlagEnabled,
    accountId: me?.accountId || '',
    isAdmin,
  });
  const { data: creditCards } = useNet60CreditCards();

  const form = useForm<CardForm>({
    initialValues: {
      userId: '',
      spendPlanId: spendPlan?.id ?? '',
      cardType: 'virtual',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: undefined,
      spendingLimitFuture: 0,
      groups: spendPlan?.id
        ? ((spendPlan?.allowedMccs?.length > 0
            ? spendPlan.allowedMccs
            : spendPlan?.blockedMccs) as CardInfoGroup[])
        : [],
      categoryId: '',
      terminateAt: null,
      limitMerchantCategories:
        spendPlan?.allowedMccs.length || spendPlan?.blockedMccs.length
          ? 'yes'
          : 'no',
      autoExpire: 'no',
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return values.spendPlanId
            ? 'Spending limit must be greater than 0.'
            : 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        if (data?.creditLimit && value > data.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            data.creditLimit,
          )}`;
        }
        return null;
      },

      spendingLimitFuture: (value, values) => {
        if (!values.spendPlanId || isSelectedSpendPlanOneTime) {
          return null;
        }

        if (value <= 0) {
          return 'Spending limit must be greater than 0.';
        }

        if (data?.creditLimit && value > data.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            data.creditLimit,
          )}`;
        }

        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      cardType: (value, values) => {
        if (value !== 'physical') {
          return null;
        }

        const [memberName] =
          users
            ?.filter((u) => u.id === values.userId)
            .map((u) => `${u.firstName} ${u.lastName}`) || [];
        const activePhysicalCards =
          creditCards?.filter(
            (c) =>
              c.userId === values.userId &&
              c.cardType === 'PHYSICAL' &&
              c.status !== 'terminated',
          ) || [];

        return activePhysicalCards.length
          ? `${memberName || 'Selected member'} already has an active physical card. You must cancel that card before ordering a new one.`
          : null;
      },
      userId: RequiredFieldValidator('A user must be selected for this card'),
      spendingLimitInterval: (value, values) => {
        return values.spendPlanId
          ? null
          : RequiredFieldValidator('Select a limit type')(value);
      },
    },
  });

  const filteredUsers: SelectItem[] = useMemo(() => {
    if (!users) {
      return [];
    }

    const availableUsers: SelectItem[] = users
      .filter(
        (u) =>
          u.completedOnboarding &&
          !u.completedOffboarding &&
          u.status === 'active',
      )
      .map((u) => ({ value: u.id!, label: `${u.firstName} ${u.lastName}` }));

    if (method === 'credit') {
      return availableUsers;
    } else if (spendPlan) {
      if (isSpendPlanAdminView(spendPlan)) {
        const allSpendPlanMembers = new Set([
          ...spendPlan.members.map((u) => u.personId),
          ...spendPlan.managers.map((u) => u.personId),
        ]);
        return availableUsers.filter((u) => allSpendPlanMembers.has(u.value));
      } else {
        return availableUsers.filter((u) => u.value === user.id);
      }
    }

    return availableUsers;
  }, [users, spendPlan, method]);

  const isVirtualCard = form.values.cardType === 'virtual';

  const selectedSpendPlan = spendPlans?.find(
    (sp) => sp.id === form.values.spendPlanId,
  ) as SpendPlanView | undefined;

  const isSelectedSpendPlanOneTime =
    selectedSpendPlan?.frequency === SpendPlanViewFrequencyEnum.Onetime;
  const linkWithSpendPlan = isVirtualCard && !!selectedSpendPlan;
  const disableCardType = !!card?.id || linkWithSpendPlan;
  const expenseCategoryFilter = selectedSpendPlan
    ? (categories: SelectItem[]) => {
        // API should be returning this as Array | undefined
        const { allowedRutterCategories } = selectedSpendPlan;

        if (allowedRutterCategories) {
          return categories.filter((c) =>
            allowedRutterCategories.some(
              (categoryId) => categoryId === c.value,
            ),
          );
        } else {
          return categories;
        }
      }
    : undefined;

  const mccCategoryFilter = selectedSpendPlan
    ? (categories: ComboboxItem[]) => {
        // API should only return one or neither, but not both
        const { allowedMccs, blockedMccs } = selectedSpendPlan;

        if (allowedMccs && allowedMccs.length > 0) {
          return categories.filter((c) =>
            allowedMccs.some((mcc) => c.value === mcc),
          );
        } else if (blockedMccs && blockedMccs.length > 0) {
          return categories.filter(
            (c) => !blockedMccs.some((mcc) => c.value === mcc),
          );
        } else {
          return categories;
        }
      }
    : undefined;

  const filteredSpendPlanSelectItems: SelectItem[] = useMemo(() => {
    if (spendPlan) {
      return [
        {
          label: spendPlan.name,
          value: spendPlan.id,
        },
      ];
    }

    if (!spendPlans || !form.values.userId) {
      return [];
    }

    const filteredPlans = spendPlans.filter((sp) => {
      const userIsMemberInSpendPlan = isSpendPlanAdminView(sp)
        ? sp.members.some((member) => member.personId === form.values.userId)
        : true;

      const userIsManagerInSpendPlan = isSpendPlanAdminView(sp)
        ? sp.managers.some((manager) => manager.personId === form.values.userId)
        : false;

      return userIsMemberInSpendPlan || userIsManagerInSpendPlan;
    });

    // Create a unique array based on spendPlans IDs
    const uniqueSpendPlans = uniq(filteredPlans, (plan) => plan.id);

    return uniqueSpendPlans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    }));
  }, [form.values.userId, spendPlans, spendPlan]);

  const selectedMember = filteredUsers.find(
    (e) => e.value === form.values.userId,
  );

  const showSpendPlan = spendPlanFlagEnabled && isVirtualCard;

  const showLimitType = !linkWithSpendPlan;
  const showExpenseCategory = !!expenseLink?.enabledExpenses && !!connectionId;

  const { mutate: issueCreditCard, isPending } = useIssueCreditCardMutation();
  const { mutate: mutateCategoryCard } = useCreateCardCategory();
  const { mutateAsync: linkCardToSpendPlan } = useLinkCardToSpendPlan();

  const handleSpendPlanIdChange = (spendPlanId: string | null) => {
    if (spendPlanId) {
      // categoryId and groups depend on spendplan, so if the plan changes, reset them
      form.setFieldValue('spendPlanId', spendPlanId);
      form.setFieldValue('categoryId', undefined);
      form.setFieldValue('groups', []);
    } else {
      form.setFieldValue('spendPlanId', '');
    }

    const spendplanData = spendPlans?.find((sp) => sp.id === spendPlanId);

    if (
      spendplanData &&
      (spendplanData.allowedMccs.length > 0 ||
        spendplanData.blockedMccs.length > 0)
    ) {
      form.setFieldValue('limitMerchantCategories', 'yes');
      form.setFieldValue(
        'groups',
        (spendplanData.allowedMccs.length > 0
          ? spendplanData.allowedMccs
          : spendplanData.blockedMccs) as CardInfoGroup[],
      );
    } else {
      form.setFieldValue('limitMerchantCategories', 'no');
      form.setFieldValue('groups', []);
    }
  };

  const handleSaveClick = form.onSubmit(async (formValues) => {
    const isUnlimited = formValues.spendingLimitInterval === 'unlimited';
    const limits: {
      interval?: LimitInterval;
      amount?: number;
      futureAmount?: number;
      groups: CardInfoGroup[];
    } = {
      groups: formValues.groups,
    };

    if (linkWithSpendPlan) {
      limits.amount = formValues.spendingLimit;
      limits.futureAmount = limits.amount;
      limits.interval = undefined; // inherited from spend plan

      if (!isSelectedSpendPlanOneTime) {
        limits.futureAmount = formValues.spendingLimitFuture;
      }
    } else if (!isUnlimited) {
      limits.amount = formValues.spendingLimit;
      limits.futureAmount = undefined; // only applies to spend plan
      limits.interval = formValues.spendingLimitInterval || undefined;
    }

    issueCreditCard(
      {
        userId: formValues.userId,
        card: {
          cardType: formValues.cardType,
          cardName: formValues.cardName,
          limits,
          ...(formValues.autoExpire === 'yes' && {
            terminateAt: formValues.terminateAt,
          }),
        },
      },
      {
        onSuccess: async (response) => {
          if (linkWithSpendPlan) {
            await linkCardToSpendPlan({
              accountId: me?.accountId || '',
              spendPlanId: selectedSpendPlan.id,
              cardId: response.card.id,
              payload: {
                initialAmount: limits.amount,
                futureAmount: limits.futureAmount,
                recurring: true,
                fullFundsAccess: false,
              },
            }).catch(() => {
              showNotification({
                color: 'red',
                title: 'Error',
                message: `Card was successfully created, but unable to be linked to spend plan: ${selectedSpendPlan.name}.`,
                autoClose: false,
              });
            });
          }

          if (cardIsCreditCard(response.card)) {
            if (showExpenseCategory && formValues.categoryId) {
              mutateCategoryCard({
                categoryId: formValues.categoryId,
                cardName: response.card.cardName,
                userId: formValues.userId,
                connectionId,
              });
            }
          }
          closeModal();
        },
        onError: (err) => {
          if (err.message.includes('must not be frozen')) {
            showNotification({
              title: 'Error',
              message:
                'The account is currently frozen and we are unable to issue a new cards until it is unfrozen. Please contact support at 415-840-8721.',
              color: 'critical.2',
            });
          } else {
            showNotification({
              title: 'Error',
              message: 'Unable to issue the card. Try it again.',
              color: 'critical.2',
            });
          }
        },
      },
    );
  });

  return (
    <Container>
      <Stack gap={STACK_SPACING}>
        <Title
          style={{
            textAlign: 'start',
            fontSize: '28px',
            fontFamily: 'PP Neue Montreal',
            fontWeight: 400,
          }}
        >
          {card?.id ? 'Update credit card' : 'Create a new credit card'}
        </Title>

        <Select
          label="Team member"
          searchable
          data={filteredUsers}
          radius={8}
          disabled={!!card?.id}
          maxDropdownHeight={280}
          data-testid="select-employee"
          placeholder="Select employee"
          {...form.getInputProps('userId')}
        />

        <Input
          label="Card purpose"
          data-testid="card-name"
          placeholder="e.g. Advertising expenses"
          {...form.getInputProps('cardName')}
        />

        <Radio.Group
          label="Card type"
          size="sm"
          {...form.getInputProps('cardType')}
          color={theme.primaryColor}
          aria-label="card-type"
        >
          <Group mt="xxs" gap={RADIO_SPACING}>
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              disabled={disableCardType || method === 'spendPlan'}
              aria-label="physical-card"
              value="physical"
              label="Physical"
            />
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              disabled={disableCardType}
              aria-label="virtual-card"
              value="virtual"
              label="Virtual"
            />
          </Group>
        </Radio.Group>

        {showSpendPlan && (
          <Select
            label={
              method === 'spendPlan' ? 'Spend plan' : 'Spend plan (optional)'
            }
            placeholder={
              selectedMember
                ? `Select spend plan`
                : `Select a team member first`
            }
            data={filteredSpendPlanSelectItems}
            nothingFoundMessage={
              selectedMember
                ? `${selectedMember.label} is not a member of any spend plans`
                : `No spend plans found`
            }
            {...form.getInputProps('spendPlanId')}
            onChange={handleSpendPlanIdChange}
            disabled={!form.values.userId || method === 'spendPlan'}
            searchable
            clearable
          />
        )}

        {selectedSpendPlan && (
          <Alert
            color="info"
            withCloseButton={false}
            styles={{
              root: {
                borderWidth: 0,
              },
              message: {
                fontSize: rem(12),
              },
            }}
          >
            This virtual card will inherit the restrictions defined in{' '}
            {selectedSpendPlan.name || 'the selected spend plan'}.
          </Alert>
        )}

        {showLimitType && (
          <Select
            label="Limit frequency"
            placeholder="Select frequency"
            data={LIMIT_INTERVALS}
            radius={8}
            maxDropdownHeight={280}
            data-testid="select-limit-type"
            {...form.getInputProps('spendingLimitInterval')}
            onChange={(e) => {
              form.setFieldValue('spendingLimit', 0);
              if (e) {
                form.setFieldValue('spendingLimitInterval', e as LimitInterval);
              }
            }}
          />
        )}

        {form.values.spendingLimitInterval !== 'unlimited' && (
          <FlexNumberInput
            label={
              linkWithSpendPlan && !isSelectedSpendPlanOneTime
                ? `Limit (current period)`
                : `Limit`
            }
            variant="default"
            radius={8}
            leftSection={<>$</>}
            pattern="[0-9]*"
            thousandSeparator=","
            data-testid="amount"
            onValueChange={(value) => {
              form.setFieldValue('spendingLimit', value.floatValue ?? 0);
            }}
            value={form.values.spendingLimit}
            error={form.errors.spendingLimit}
          />
        )}

        {linkWithSpendPlan && !isSelectedSpendPlanOneTime && (
          <FlexNumberInput
            label="Limit (future periods)"
            variant="default"
            radius={8}
            leftSection={<>$</>}
            pattern="[0-9]*"
            thousandSeparator=","
            data-testid="future-amount"
            onValueChange={(value) => {
              form.setFieldValue('spendingLimitFuture', value.floatValue ?? 0);
            }}
            value={form.values.spendingLimitFuture}
            error={form.errors.spendingLimitFuture}
          />
        )}

        {showExpenseCategory && (
          <CardCategorySelect
            {...form.getInputProps('categoryId')}
            filter={expenseCategoryFilter}
          />
        )}
        {spendPlan && showSpendPlan && (
          <Input label="Frequency" value={spendPlan.frequency} disabled />
        )}
        <Box>
          {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
          <Radio.Group
            label="Restrict spending to specific categories?"
            size="sm"
            color={theme.primaryColor}
            {...form.getInputProps('limitMerchantCategories')}
          >
            <Group gap={RADIO_SPACING} mt="xxs">
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="categoriesYes"
                value="yes"
                label="Yes"
                disabled={!!form.values.spendPlanId}
              />
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="categoriesNo"
                value="no"
                label="No"
                disabled={!!form.values.spendPlanId}
              />
            </Group>
          </Radio.Group>

          <Box>
            {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
            <Collapse in={form.values.limitMerchantCategories === 'yes'}>
              <Box mt={STACK_SPACING}>
                <Multiselect
                  form={form}
                  filter={mccCategoryFilter}
                  disabled={!!form.values.spendPlanId}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>

        <Box>
          {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
          <Radio.Group
            label="Set termination date?"
            size="sm"
            color={theme.primaryColor}
            {...form.getInputProps('autoExpire')}
          >
            <Group gap={RADIO_SPACING} mt="xxs">
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="autoExpireYes"
                value="yes"
                label="Yes"
              />
              <Radio
                styles={{ body: CustomMantineStyles().issueCard.radio.body }}
                aria-label="autoExpireNo"
                value="no"
                label="No"
              />
            </Group>
          </Radio.Group>

          <Box>
            {' '}
            {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
            <Collapse in={form.values.autoExpire === 'yes'}>
              <DatePickerInput
                leftSection={
                  <CalendarIcon
                    color={theme.colors.blackish[0]}
                    height="20px"
                    width="20px"
                  />
                }
                mt={STACK_SPACING}
                clearable
                valueFormat="YYYY-MM-DD"
                label="Termination date"
                minDate={DateTime.fromJSDate(new Date())
                  .plus({ days: 1 })
                  .toJSDate()}
                onChange={(d) => {
                  if (d) {
                    const date = DateTime.fromJSDate(d);
                    form.setFieldValue(
                      'terminateAt',
                      date.toFormat('yyyy-MM-dd'),
                    );
                  } else {
                    form.setFieldValue('terminateAt', null);
                  }
                }}
              />
            </Collapse>
          </Box>
        </Box>
      </Stack>

      <Grid
        className="buttons-container"
        justify="space-between"
        align="center"
        mt="1rem"
        gutter={0}
      >
        {form.values.cardType === 'virtual' && (
          <Grid.Col span={6}>
            <Text c="#4B4B4B" fz="xs" style={{ marginRight: '16px' }}>
              The card will be activated within a few moments.
            </Text>
          </Grid.Col>
        )}

        <Grid.Col span="content">
          <Button
            loading={isPending}
            onClick={() => handleSaveClick()}
            fullWidth={form.values.cardType === 'physical'}
            className="btn-create-card"
          >
            {card?.id ? 'Update Card' : 'Create Card'}
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default IssueCardForm;
