import { ReactNode } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Box, Button, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import FlexbaseInput from '@common/input/flexbase-input';
import { useOnboardingStyles } from '../onboarding.styles';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

type FormBox<T extends FormValues> = {
  title: string;
  loading: boolean;
  children: ReactNode;
  errorMessage: string;
  onSubmit: () => void;
  onCancel: () => void;
  type: 'owner' | 'officer';
  form: UseFormReturnType<T>;
};

const OwnerOfficerForm = <T extends FormValues>({
  type,
  form,
  title,
  loading,
  children,
  errorMessage,
  onCancel,
  onSubmit,
}: FormBox<T>) => {
  const { classes, cx } = useOnboardingStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const cancelButtonLabel = type === 'owner' ? 'Clear' : 'Cancel';

  return (
    <div className={classes.addOwnerForm}>
      <Text fz={20}>{title}</Text>

      <Box mt="xl" className={classes.containerOwnerForm}>
        <FlexbaseInput
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Enter first name"
          data-sardine-id="input-owner-first-name"
          label="Legal first name"
          {...form.getInputProps('firstName')}
        />
        <FlexbaseInput
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Enter last name"
          label="Legal last name"
          data-sardine-id="input-owner-last-name"
          {...form.getInputProps('lastName')}
        />
      </Box>

      <Box mt="md" className={classes.containerOwnerForm}>
        <FlexbaseInput
          w={useMobileView ? 'auto' : '60%'}
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Email address"
          data-sardine-id="input-owner-email"
          label="Email address"
          {...form.getInputProps('email')}
        />

        {children}
      </Box>

      {errorMessage && (
        <Box mt="xs" className={cx(classes.stepText, classes.stepErrorText)}>
          {errorMessage}
        </Box>
      )}

      <Box mt="xl" className={classes.buttonGroup}>
        <Button variant="outline" onClick={() => onCancel()} disabled={loading}>
          {cancelButtonLabel}
        </Button>
        <Button
          variant="outline"
          loading={loading}
          onClick={() => onSubmit()}
          classNames={{ root: classes.addNewOwnerBtn }}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default OwnerOfficerForm;
