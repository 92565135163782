import {
  Alert,
  Divider,
  LoadingOverlay,
  Progress,
  rem,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import {
  Limits,
  CardByUser,
  DepositAccount,
  ChargeCardAccount,
} from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import Actions from 'areas/banking/debit-cards/debit-card-details/actions';
import BillingAddress from 'areas/banking/debit-cards/debit-card-details/billing-address';
import DebitCard from 'areas/banking/debit-cards/debit-card-details/debit-card';
import { useStyles } from './debit-card-details.styles';
import TerminatedDebitCard from 'areas/banking/debit-cards/debit-card-details/terminated-debit-card';
import UpdateDebitOrChargeCard from 'areas/banking/debit-cards/debit-card-details/update-debit-or-charge-card';
import {
  getATMSpendLimitByTier,
  getTier,
} from 'areas/banking/debit-cards/util';
import { ModalHeaderBar } from '@common/modal/modal-header-bar';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { useRecoilValue } from 'recoil';
import { IsEmployee } from 'recoil-state/application/product-onboarding';
import { cardIsChargeCard, FlexCard } from '@services/flexbase/card.model';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { PiXCircle } from 'react-icons/pi';

type Props = {
  card: CardByUser;
};

const DebitCardDetails = ({ card }: Props) => {
  const { classes, theme } = useStyles();
  const [error, setError] = useState(false);
  const isEmployee = useRecoilValue(IsEmployee);
  const [editCard, setEditCard] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [cardStatus, setCardStatus] = useState(card.status);
  const isChargeCard = cardIsChargeCard(card as unknown as FlexCard);
  const { data } = useGetDepositAccounts(!(isChargeCard || isEmployee));
  const depositAccounts = data?.accounts || [];
  const { data: chargeAccountsData, isLoading: isChargeAccountLoading } =
    useGetChargeCardAccounts(isChargeCard);
  const chargeAccounts = chargeAccountsData?.accounts ?? [];

  const getLimitType = (expensesTypes: Limits) => {
    if (expensesTypes?.dailyPurchase) {
      return 'daily';
    }
    if (expensesTypes?.monthlyPurchase) {
      return 'monthly';
    }
    return 'unlimited';
  };

  const limitType = getLimitType(card.expensesTypes);

  const cardLimit =
    card.expensesTypes?.dailyPurchase ??
    card.expensesTypes?.monthlyPurchase ??
    0;

  const [cardData, setCardData] = useState({
    ...card,
    limit: cardLimit,
    limitType: limitType,
  });

  const displayPercent = Math.round(
    (card.monthToDateSpends?.mtdSpend * 100) / cardData.limit,
  );
  const dailyWithdrawal = cardData.expensesTypes?.dailyWithdrawal
    ? cardData.expensesTypes.dailyWithdrawal / 100
    : null;
  const currentDailyWithdrawalLimit =
    dailyWithdrawal ??
    getATMSpendLimitByTier(depositAccounts, card.depositAccountId);

  const getAccountName = (
    accts: DepositAccount[] | ChargeCardAccount[],
    currentId: string,
  ) => {
    const { nickName, name } = accts.find(({ id }) => id === currentId) ?? {};
    return nickName || name || '';
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (error) {
        setError(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  const getCurrentLimit = () => {
    switch (cardData.limitType) {
      case 'daily':
        return ((cardData.expensesTypes?.dailyPurchase ?? 0) / 100).toFixed(2);
      case 'monthly':
        return ((cardData.expensesTypes?.monthlyPurchase ?? 0) / 100).toFixed(
          2,
        );
      default:
        return 0;
    }
  };

  const getAccountNameValue = () => {
    if (isChargeCard) {
      return getAccountName(chargeAccounts, card.chargeCardAccountId);
    }

    return getAccountName(depositAccounts, card.depositAccountId);
  };

  const footerData = [
    {
      title: 'Account',
      value: getAccountNameValue(),
    },
    {
      title: 'Card type',
      value: capitalizeOnlyFirstLetter(card.cardType),
    },
    {
      title:
        cardData.limitType !== 'unlimited'
          ? `${capitalizeOnlyFirstLetter(cardData.limitType)} spending limit`
          : 'Spending limit',
      value:
        cardData.limitType !== 'unlimited'
          ? formatCurrency(getCurrentLimit())
          : 'Unlimited',
    },
  ];

  if (!(isChargeCard || card.cardType === 'VIRTUAL')) {
    footerData.splice(2, 0, {
      title: 'Daily ATM withdrawal limit',
      value: formatCurrency(currentDailyWithdrawalLimit),
    });
  }

  if (cardStatus === 'terminated') {
    return (
      <TerminatedDebitCard
        card={card}
        cardStatus={cardStatus}
        limit={cardData.limit}
        limitType={limitType}
      />
    );
  }

  return (
    <div className={classes.cardDetails}>
      <LoadingOverlay visible={isChargeAccountLoading} />
      <div style={{ opacity: editCard ? 0.5 : 1 }}>
        <ModalHeaderBar
          title={cardData.cardName || null}
          rightTitle={formatCurrency(card.monthToDateSpends?.mtdSpend)}
          subtitle={card.holder}
          rightSubtitle="Spent this month"
        />

        {cardData.limitType !== 'unlimited' && (
          <div className={classes.padding}>
            <Progress
              h={rem(5)}
              my={rem(20)}
              value={displayPercent}
              color={theme.colors.primarySecondarySuccess[2]}
              bg={theme.colors.contentBackground[0]}
            />
            <div className={classes.rowContent}>
              <Text
                color="primarySecondarySuccess.8"
                fz={rem(14)}
                fw={500}
                ta="right"
              >
                {formatCurrency(cardData.limit / 100)} {cardData.limitType}{' '}
                spending limit
              </Text>
            </div>
          </div>
        )}

        <DebitCard {...{ card, cardStatus }} />
      </div>

      {editCard ? (
        <>
          <Divider className={classes.divider} />
          <UpdateDebitOrChargeCard
            {...{
              cardData,
              setCardData,
              setEditCard,
            }}
            accountTier={getTier(depositAccounts, card.depositAccountId)}
            atmWithdrawalLimit={currentDailyWithdrawalLimit}
          />
        </>
      ) : (
        <>
          <Actions
            {...{
              card,
              setError,
              cardStatus,
              setEditCard,
              setCardStatus,
            }}
          />

          {card.address && (
            <>
              <Divider className={classes.divider} />
              <BillingAddress card={card} />
            </>
          )}

          <Divider className={classes.divider} />
          <div className={classes.padding}>
            {footerData.map(({ title, value }) => (
              <div
                className={`${classes.rowContent} ${classes.footerItem}`}
                key={title}
              >
                <Text fz={rem(14)}>{title}</Text>
                <Text fz={rem(14)}>{value}</Text>
              </div>
            ))}
          </div>
        </>
      )}

      {error && (
        <Alert
          color="red"
          mt={rem(20)}
          mx={useMobileView ? rem(20) : rem(50)}
          icon={<PiXCircle size="1.25rem" />}
        >
          Unable to update card status. Please try again.
        </Alert>
      )}
    </div>
  );
};

export default DebitCardDetails;
