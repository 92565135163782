import { ExpandingFilterBar } from '@common/filter/expanding-filter-bar';
import { useCheckDepositsFilters } from './filters/use-check-deposits-filters';
import { CheckDepositsStatusFilter } from './filters/check-deposits-status-filter';
import { CheckDepositsAmountFilter } from './filters/check-deposits-amount-filter';
import { CheckDepositsDateRangeFilter } from './filters/check-deposits-date-range.filter';
import {
  PiCalendarBlank,
  PiCurrencyCircleDollar,
  PiShieldStar,
} from 'react-icons/pi';

const CheckDepositsFilters = ({ showFilters }: { showFilters: boolean }) => {
  const { activeFiltersArray, removeFilter } = useCheckDepositsFilters();

  const CHECK_DEPOSITS_FILTERS = [
    {
      key: 'date',
      header: 'Date',
      icon: <PiCalendarBlank size={'1.5rem'} />,
      component: <CheckDepositsDateRangeFilter />,
    },
    {
      key: 'status',
      header: 'Status',
      icon: <PiShieldStar size={'1.5rem'} />,
      component: <CheckDepositsStatusFilter />,
    },
    {
      key: 'amount',
      header: 'Amount',
      icon: <PiCurrencyCircleDollar size={'1.5rem'} />,
      component: <CheckDepositsAmountFilter />,
    },
  ];

  return (
    <ExpandingFilterBar
      showFilters={showFilters}
      filters={CHECK_DEPOSITS_FILTERS}
      activeFilters={activeFiltersArray}
      onRemoveActiveFilterClick={removeFilter}
    />
  );
};

export default CheckDepositsFilters;
