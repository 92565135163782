import { Box, Button, TextInput } from '@mantine/core';
import { SearchIcon } from 'assets/svg';
import {
  useSpendTransactionFilters,
  useSpendTransactionModalFilters,
} from './use-spend-transactions-filter';
import { isTruthyString } from '@utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import { SpendNameFilter } from './filters/spend-name-filter';
import { SpendAmountFilter } from './filters/spend-amount-filter';
import { SpendDateRangeFilter } from './filters/spend-date-filter';
import { SpendAttachmentsFilter } from './filters/spend-attachments-filter';
import { SpendCardsFilter } from './filters/spend-cards-filter';
import { SpendCategoryFilter } from './filters/spend-category-filter';
import { SpenMerchantFilter } from './filters/spend-merchant-filter';
import { SpendStatusFilter } from './filters/spend-status-filter';
import { useFilterModal } from '@common/filter/filter-modal';
import { SpendSyncStatusFilter } from './filters/spend-sync-status-filter';
import { TransactionData } from '@services/flexbase/spend-plans.model';
import { FilterChips } from '@common/filter/filter-chips';

type Props = {
  data: TransactionData[];
};

const SpendTransactionsTableHeader = ({ data }: Props) => {
  const filterHook = useSpendTransactionFilters();
  const modalFilterHook = useSpendTransactionModalFilters();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const filters = [
    {
      key: 'sync',
      header: 'Sync status',
      component: <SpendSyncStatusFilter />,
    },
    {
      key: 'status',
      header: 'Transaction status',
      component: <SpendStatusFilter />,
    },
    {
      key: 'date',
      header: 'Date',
      component: <SpendDateRangeFilter />,
    },
    {
      key: 'name',
      header: 'Cardholder',
      component: <SpendNameFilter transactions={data || []} />,
    },
    {
      key: 'cards',
      header: 'Cards',
      component: <SpendCardsFilter transactions={data || []} />,
    },
    {
      key: 'caategory',
      header: 'Category',
      component: <SpendCategoryFilter transactions={data || []} />,
    },
    {
      key: 'attachments',
      header: 'Attachments',
      component: <SpendAttachmentsFilter />,
    },
    {
      key: 'merchant',
      header: 'Merchant',
      component: <SpenMerchantFilter transactions={data || []} />,
    },
    {
      key: 'amount',
      header: 'Amount',
      component: <SpendAmountFilter />,
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filters);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing.md,
          })}
        >
          <TextInput
            placeholder="Search transactions"
            leftSection={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
            sx={(_, helpers) => ({
              width: 368,
              [helpers.smallerThan('md')]: {
                width: '100%',
              },
            })}
          />
          <Button
            variant="outline"
            rightSection={<IoFunnelOutline />}
            onClick={() => openModal()}
            disabled={data.length === 0}
          >
            Filters
          </Button>
        </Box>
      </Box>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};

export default SpendTransactionsTableHeader;
