import {
  Box,
  Grid,
  LoadingOverlay,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import ChargeAutoPay from './charge-auto-pay';
import ChargePaymentsTable from './charge-payments-table';
import ChargePaymentCard from '@common/charge-payment';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { UseGetTenant } from '@queries/use-tenant';
import RebateHistoryTable from '@common/charge-and-credit-cards/rebate-history-table';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import { NavTab } from '@common/tabs/nav-tabs';
import { useEffect, useMemo, useState } from 'react';

const ChargePayments = () => {
  const theme = useMantineTheme();
  const [activeTab, setActiveTab] = useState('payment-history-charge-card');
  const {
    data: chargeAccountsData,
    isError: isChargeAccountsError,
    isLoading: isChargeAccountsLoading,
    refetch: refetchChargeAccounts,
  } = useGetChargeCardAccounts();

  const {
    data,
    isError: isTenantError,
    isLoading: isTenantLoading,
  } = UseGetTenant();

  const isRebateHistoryAvailable = !!data?.earlypay.dates;
  const activeChargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];
  const isLoading = isChargeAccountsLoading || isTenantLoading;
  const isError = isChargeAccountsError || isTenantError;

  const tabs: NavTab[] = useMemo(
    () =>
      [
        {
          label: 'Payment history',
          route: 'payment-history-charge-card',
          visible: true,
        },
        {
          label: 'Rebate history',
          route: 'rebate-history-charge-card',
          visible: isRebateHistoryAvailable,
        },
      ].filter((tab) => tab.visible),
    [isRebateHistoryAvailable],
  );

  const renderContent = () => {
    if (activeTab === 'payment-history-charge-card') {
      return <ChargePaymentsTable />;
    }
    if (activeTab === 'rebate-history-charge-card') {
      return <RebateHistoryTable issuer="unit" />;
    }
    return null;
  };

  useEffect(() => {
    if (!tabs.find((tab) => tab.route === activeTab)) {
      setActiveTab(tabs[0]?.route || 'payment-history-charge-card');
    }
  }, [tabs, activeTab]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (isError) {
    return (
      <Stack p="1.5rem" w="100%" h={rem(200)} bg="neutral.0" gap="0.5rem">
        {' '}
        <Text fw={500} c={theme.colors.blackish[0]}>
          An unexpected error occurred
        </Text>{' '}
      </Stack>
    );
  }

  return (
    <Box>
      <Grid mb="xl">
        <Grid.Col span={{ md: 6 }}>
          <ChargeAutoPay
            isChargeAccountsError={isChargeAccountsError}
            // for v1 the company will have one charge account
            chargeAccount={activeChargeAccounts[0]}
            refetchChargeAccounts={refetchChargeAccounts}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <ChargePaymentCard
            isError={isChargeAccountsError}
            // for v1 the company will have one charge account
            chargeBalance={activeChargeAccounts[0]}
            refetchChargeAccounts={refetchChargeAccounts}
          />
        </Grid.Col>
      </Grid>
      <NavTabContainer
        tabs={tabs}
        onTabChange={(tab) => setActiveTab(tab.route)}
      >
        {renderContent()}
      </NavTabContainer>
    </Box>
  );
};

export default ChargePayments;
