import SuccessTransfer from 'assets/images/transferred-banking.png';
import errorPendingTransfer from 'assets/images/watch-banking.png';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { MoneyMovement } from '../../../../../services/flexbase/banking.model';
import { getCurrentIsoDate } from 'utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters';
import GetPaddedAccountMask from '../../../../../utilities/formatters/get-padded-account-mask';
import { setPaymentRejectedError } from 'utilities/formatters/payment-rejected-message';
import { PaymentStatusDetails } from '../payment-confirmation';
import {
  getInternationalWireCurrency,
  tempRecipientValueConditionalFix,
} from '../international-payments/util';
import { PayMethod } from 'types/payments';

// map a Payment's type/method from the API into something formatted for humans
const formatPaymentMethod = (method: PayMethod) => {
  const methods = {
    wire: 'Wire',
    ach: 'ACH',
    book: 'Instant Transfer',
    flexPay: 'Flex Pay',
    internationalWire: 'International Wire',
    internationalWireUSD: 'International Wire USD',
  };

  return methods[method];
};

// map a Payment's type to its expected arrival time
const formatArrival = (method: PayMethod) => {
  const arrivals = {
    wire: 'in 1-2 business days',
    ach: 'in 1 business day',
    book: 'instantly',
    flexPay: 'in 3 business days',
    internationalWire: 'in 3 business days',
    internationalWireUSD: 'in 3 business days',
  };

  return arrivals[method];
};

const getETADescription = (method: PayMethod) => {
  switch (method) {
    case 'internationalWire':
    case 'internationalWireUSD':
      return 'Funds should arrive within 3-5 business days.';
    default:
      return 'Funds should arrive within 1 business day.';
  }
};

const errorPaymentStatus = {
  title: `An error occurred while sending your payment.`,
  description: 'Please contact support at support@flex.one.',
  sketch: errorPendingTransfer,
  downloadWire: false,
};

export const usePaymentStatus = (
  payment: MoneyMovement | null,
  confirmationStatus: string | null,
  rejectedReason: string | undefined,
  paymentStatusDetails: PaymentStatusDetails,
  isError?: boolean,
) => {
  const currency = getInternationalWireCurrency(paymentStatusDetails);

  const {
    moneyMovementDetails,
    recipientName,
    method,
    counterparty,
    recipient,
  } = paymentStatusDetails;

  const getPaymentError = (reason?: string) => {
    if (reason) {
      return setPaymentRejectedError(reason);
    } else {
      return 'An error occurred setting up your payment info';
    }
  };

  let paymentStatus: {
    title: string;
    description: string;
    downloadWire: boolean;
    sketch: string;
  };

  const formattedDate = DateTime.fromFormat(
    moneyMovementDetails.scheduledFor ?? getCurrentIsoDate(),
    'yyyy-MM-dd',
  ).toFormat('DD');

  if (isError) {
    return errorPaymentStatus;
  }

  switch (confirmationStatus ?? payment?.status) {
    case 'AwaitingApproval':
      paymentStatus = {
        title: `Your ${formatCurrency(moneyMovementDetails.amount, currency)}${
          currency === 'USD' ? '' : ` ${currency}`
        } ${formatPaymentMethod(
          method,
        )} to ${recipientName} (Account ${GetPaddedAccountMask(
          counterparty.accountNumber ||
            tempRecipientValueConditionalFix(recipient, 'accountNumber'),
          0,
        )}) is pending approval.`,
        description: 'Payment will be scheduled upon approval.',
        sketch: SuccessTransfer,
        downloadWire: false,
      };
      break;

    case 'AwaitingConfirmation':
      paymentStatus = {
        title: 'Two Factor Verification',
        description:
          'To ensure the safety of your transfer, we are requesting you reauthenticate with the six digit code sent via SMS.',
        sketch: errorPendingTransfer,
        downloadWire: false,
      };
      break;

    case 'Pending':
    case 'Clearing':
    case 'PendingReview':
      paymentStatus = {
        title: `You've sent ${formatCurrency(
          moneyMovementDetails.amount,
          currency,
        )}${currency === 'USD' ? '' : ` ${currency}`} to ${recipientName}!`,
        description: getETADescription(method),
        sketch: SuccessTransfer,
        downloadWire: true,
      };
      break;
    case 'Rejected':
      paymentStatus = {
        title: 'Your transaction has failed',
        description: getPaymentError(payment?.reason ?? rejectedReason),
        sketch: errorPendingTransfer,
        downloadWire: false,
      };
      break;

    case 'Sent':
      paymentStatus = {
        title: `You've sent ${formatCurrency(
          moneyMovementDetails.amount,
          currency,
        )}${currency === 'USD' ? '' : ` ${currency}`} to ${recipientName}!`,
        description: `Funds should arrive ${formatArrival(method)}.`,
        sketch: SuccessTransfer,
        downloadWire: true,
      };
      break;

    case 'Scheduled':
      paymentStatus = {
        title: `You’ve scheduled a payment of ${formatCurrency(
          moneyMovementDetails.amount,
          currency,
        )}${currency === 'USD' ? '' : ` ${currency}`} to ${recipientName}!`,
        description: `The payment will initiate on ${formattedDate} and funds should arrive ${formatArrival(
          method,
        )}.`,
        sketch: SuccessTransfer,
        downloadWire: true,
      };
      break;

    default:
      paymentStatus = errorPaymentStatus;
      break;
  }

  useEffect(() => {
    if (rejectedReason) {
      getPaymentError(rejectedReason);
    }
  }, []);

  return paymentStatus;
};
