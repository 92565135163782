import { OverviewUser } from '../../../types/onboarding-info';
import { ActionItemCard } from './action-item-card';
import { Box } from '@mantine/core';
import { useMemo } from 'react';
import { FilledBadge } from '@common/self-service-dashboard/filled-badge';
import { useSelfServiceStyles } from '@common/self-service-dashboard/self-service-dashboard.styles';
import { OverflowTooltip } from '@common/overflow-tooltip/overflow-tooltip';

type Props = { companyUsers: OverviewUser[] };
export const OutstandingOwnersItem = ({ companyUsers }: Props) => {
  const { classes, cx } = useSelfServiceStyles();

  const incompleteOwners = useMemo(() => {
    return companyUsers.filter((u) => !u.isComplete);
  }, [companyUsers]);

  return (
    <ActionItemCard
      title="Submit user information"
      subtitle="All beneficial owners and officers have received login details via email so they can securely answer a few required questions. Please contact any users who have not yet submitted their information."
    >
      <Box
        className={cx(
          classes.gridTable,
          classes.gridTableHeader,
          classes.outstandingOwnersTable,
        )}
      >
        <div>Name</div>
        <div>Email</div>
        <div>Status</div>
      </Box>

      {incompleteOwners.map((o) => (
        <Box
          key={o.id}
          className={cx(
            classes.gridTable,
            classes.gridTableRow,
            classes.outstandingOwnersTable,
          )}
        >
          <OverflowTooltip text={`${o.firstName} ${o.lastName}`} />
          <OverflowTooltip text={o.email} />
          <div>
            <FilledBadge variant="secondary">
              {o.roles.includes('NO-LOGINS') ? 'Invited' : 'In progress'}
            </FilledBadge>
          </div>
        </Box>
      ))}
    </ActionItemCard>
  );
};
