import { useStyles } from './auto-pay.styles';
import {
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  rem,
  Stack,
  Text,
} from '@mantine/core';
import FullScreenModalContainer from '@common/modal/full-screen-modal-container';
import { formatCurrency } from '@utilities/formatters';
import { useForm } from '@mantine/form';
import { useUpdateAutoPay } from '@queries/use-auto-pay';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../recoil-state/application/product-onboarding';
import FlexNumberInput from '../flex-number-input';
import { useState } from 'react';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';

function positiveNumberUpTo(value: unknown, maxValue: number): boolean {
  return typeof value === 'number' && value <= maxValue && value > 0;
}

type Props = {
  creditLimit: number;
  closeModal: () => void;
};

type AutoPaySettingsFormValues = {
  utilizationPct: number | '';
  payBalancePct: number | '';
  resetDefault: boolean;
};

const AutoPaySettings = ({ closeModal, creditLimit }: Props) => {
  const { classes, theme } = useStyles();
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const isMobile = useIsMobile();
  const [utilError, setUtilError] = useState(false);
  const [payError, setPayError] = useState(false);
  const autoPayForm = useForm<AutoPaySettingsFormValues>({
    initialValues: {
      utilizationPct: company?.autopay?.utilizationPct ?? '',
      payBalancePct: company?.autopay?.payBalancePct ?? '',
      resetDefault: company?.autopay?.when === 'due',
    },
    validate: {
      utilizationPct: (v) => {
        return positiveNumberUpTo(v, 100) ? null : '';
      },
      payBalancePct: (v) => {
        return positiveNumberUpTo(v, 100) ? null : '';
      },
    },
  });

  const { mutate, isPending } = useUpdateAutoPay();

  const rootTextInput = {
    display: 'inline-block',
    width: '3.12rem',
    height: '2.5rem',
  };

  const saveAutoPaySettings = async () => {
    const errors = autoPayForm.validate();
    setUtilError(Object.hasOwn(errors.errors, 'utilizationPct'));
    setPayError(Object.hasOwn(errors.errors, 'payBalancePct'));
    if (autoPayForm.values.resetDefault || !errors.hasErrors) {
      mutate(
        {
          utilizationPct: autoPayForm.values.utilizationPct as number,
          payBalancePct: autoPayForm.values.payBalancePct as number,
          resetDefault: autoPayForm.values.resetDefault,
        },
        {
          onSuccess: (data) => {
            setCompany((prev) => ({
              ...prev,
              company: { ...prev.company, autopay: data.autopay },
            }));
            closeModal();
          },
        },
      );
    }
  };

  const calculatedUtil =
    autoPayForm.values?.utilizationPct && autoPayForm.values.utilizationPct > 0
      ? `(${formatCurrency(
          (autoPayForm.values.utilizationPct / 100) * creditLimit,
        )})`
      : '';

  return (
    <FullScreenModalContainer
      closeModal={closeModal}
      unrestrictedContent={true}
    >
      <LoadingOverlay visible={isPending} />
      <div
        className={isMobile ? classes.innerContentMobile : classes.innerContent}
      >
        <Text className={classes.editAutoPayTitle}>Edit auto pay settings</Text>
        <Text className={classes.editAutoPaySubtitle}>
          Configure how you want to auto pay based on credit utilization.
        </Text>
        <div></div>
        <Stack className={classes.editAutoPayCalculations}>
          <Text>
            When credit utilization reaches{' '}
            <FlexNumberInput
              maxLength={3}
              variant="default"
              radius={rem(8)}
              pattern="[0-9]*"
              data-testid="amount"
              decimalScale={0}
              thousandSeparator=","
              styles={{
                label: { color: 'black' },
                input: {
                  '&:focus': { borderColor: theme.primaryColor },
                },
                root: rootTextInput,
              }}
              onValueChange={(value) => {
                autoPayForm.setFieldValue(
                  'utilizationPct',
                  value.floatValue ?? 0,
                );
              }}
              value={autoPayForm.values.utilizationPct}
              error={utilError}
              disabled={autoPayForm.values.resetDefault}
            />{' '}
            % {calculatedUtil},
          </Text>

          <Text style={{ lineHeight: isMobile ? 2 : rem(40) }}>
            schedule an auto payment for{' '}
            <FlexNumberInput
              maxLength={3}
              variant="default"
              radius={rem(8)}
              pattern="[0-9]*"
              data-testid="amount"
              decimalScale={0}
              thousandSeparator=","
              styles={{
                input: {
                  '&:focus': { borderColor: theme.primaryColor },
                },
                root: rootTextInput,
              }}
              onValueChange={(value) => {
                autoPayForm.setFieldValue(
                  'payBalancePct',
                  value.floatValue ?? 0,
                );
              }}
              value={autoPayForm.values.payBalancePct}
              error={payError}
              disabled={autoPayForm.values.resetDefault}
            />{' '}
            % of your outstanding credit balance.
          </Text>
        </Stack>
        <Divider
          className={classes.borderLine}
          styles={{
            label: { fontSize: rem(14), color: theme.colors.blackish[0] },
          }}
          my="md"
          label="or"
          labelPosition="center"
        />

        <Checkbox
          {...autoPayForm.getInputProps('resetDefault', { type: 'checkbox' })}
          mt="md"
          styles={{
            label: {
              fontFamily: 'PP Neue Montreal',
              fontSize: rem(14),
              fontStyle: 'normal',
              fontWeight: 400,
            },
          }}
          label="Default option: schedule minimum required payment on due date"
        />
        {(utilError || payError) && (
          <Text className={classes.errorText}>
            Please enter a number between 1 and 100
          </Text>
        )}
        <Group
          justify="right"
          align="center"
          gap="sm"
          className={classes.buttonsDiv}
        >
          <Button variant="outline" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button variant="light" onClick={saveAutoPaySettings}>
            Save Changes
          </Button>
        </Group>
      </div>
    </FullScreenModalContainer>
  );
};

export default AutoPaySettings;
