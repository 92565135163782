import { OverflowTooltip } from '@common/overflow-tooltip/overflow-tooltip';
import { Button, Flex, Group, Image, Loader, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useGetDocument } from '@queries/use-get-document';
import { useEffect, useRef, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfDocumentProps = {
  data: ArrayBuffer;
};

const PdfDocument = ({ data }: PdfDocumentProps) => {
  // since data gets modified in-place, we need 1) 'file' to be stable 2) pass in an array copy
  const fileRef = useRef({ data: data.slice(0) });
  const [pageCounter, setPageCounter] = useState(0);

  return (
    <Document
      file={fileRef.current}
      onLoadSuccess={(res) => setPageCounter(res.numPages)}
    >
      {new Array(pageCounter).fill(0).map((_, idx) => {
        return (
          <Page
            key={`page_${idx}`}
            pageIndex={idx}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        );
      })}
    </Document>
  );
};

const usePdfData = (blob?: Blob) => {
  const [data, setData] = useState<ArrayBuffer | null>(null);

  useEffect(() => {
    if (!blob) return;
    blob.arrayBuffer().then((d) => setData(d));
  }, [blob]);

  return data;
};

type UploadedDocumentProps = {
  id: string;
};

const UploadedDocument = ({ id }: UploadedDocumentProps) => {
  const { data: blob, isLoading, isError, refetch } = useGetDocument(id);
  const pdfData = usePdfData(blob);

  if (isLoading) {
    return (
      <Flex justify={'center'} align={'center'}>
        <Loader size={'lg'} />
      </Flex>
    );
  }

  if (isError) {
    return (
      <Stack>
        <Text ta="center">Error loading uploaded document</Text>
        <Button onClick={() => refetch()}>Try again</Button>
      </Stack>
    );
  }

  if (!blob) return;

  const isPdf = blob.type === 'application/pdf';
  const url = URL.createObjectURL(new Blob([blob], { type: blob.type }));

  if (isPdf && pdfData) {
    return <PdfDocument data={pdfData} />;
  }

  return <Image src={url} />;
};

type Props = {
  id: string;
  filename: string;
};

const ExpandableFile = ({ id, filename }: Props) => {
  const handleClick = (givenId: string) => {
    modals.open({
      styles: {
        content: { minWidth: 627 },
        body: { minHeight: 260, paddingBottom: 0 },
        header: { padding: '1rem' },
      },
      children: <UploadedDocument id={givenId} />,
    });
  };

  return (
    <Group
      mt="xxs"
      onClick={() => handleClick(id)}
      sx={() => ({ cursor: 'pointer' })}
    >
      <Text fz={14} maw={150} td="underline">
        <OverflowTooltip text={filename} />
      </Text>
      <IoDocumentTextOutline />
    </Group>
  );
};

export default ExpandableFile;
