import { useState } from 'react';
import { DateTime, Interval } from 'luxon';
import { Avatar, Box, Divider, Flex, Text } from '@mantine/core';
import { ParsedAccount } from 'types/parsed-account';
import { sortDate } from '@utilities/dates/dates';
import AccountsDropdown from '../accounts-dropdown';
import { useGetBankingPayments } from '@queries/use-payments';
import { Employees } from 'types/onboarding-info';
import { DateRangeFilter } from '@common/filter/date-range.filter';
import { RightContentModal } from '@common/modal/right-content.modal';
import { formatInitials } from '@utilities/formatters/format-strings';
import { formatCurrency } from '@utilities/formatters';
import { formatFilterDate } from '@utilities/formatters/format-datetime';
import { GetMoneyMovementsParams } from '@services/flexbase/banking.model';
import { useSideModalFilters } from '@common/details-page/filters/use-side-modal-filters';

type PaymentsSummaryDetailProps = {
  users: Employees[];
  recipientId: string;
  recipientName: string;
  accountsInfo: ParsedAccount[];
  incommingCounterpartyId?: string;
};

const PaymentsSummaryDetails = ({
  users,
  recipientId,
  accountsInfo,
  recipientName,
  incommingCounterpartyId,
}: PaymentsSummaryDetailProps) => {
  const paymentsFilter = useSideModalFilters();
  const [counterpartyId, setCounterpartyId] = useState(
    incommingCounterpartyId ?? '',
  );
  const currentDateFilter = paymentsFilter.getFilterByKey('date')?.filterValue;
  const toDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.endDate?.c);
  const fromDate =
    currentDateFilter && formatFilterDate(currentDateFilter?.startDate?.c);
  const params: GetMoneyMovementsParams = !currentDateFilter?.option
    ? { recipientId }
    : { recipientId, fromDate, toDate };

  if (counterpartyId.length > 0) {
    params.counterpartyId = counterpartyId;
  }
  const { data: paymentsData } = useGetBankingPayments(params);
  const payments = paymentsData?.sort((a, b) => sortDate(a, b, false)) ?? [];

  const handleAccountChange = (providedId: string) => {
    setCounterpartyId(providedId === 'all' ? '' : providedId);
  };

  const currentAccountId = accountsInfo.find(
    (acc) => acc.id === counterpartyId,
  )?.id;

  return (
    <RightContentModal>
      <RightContentModal.Header
        titleSize={40}
        title={recipientName}
        rightTitle={
          <AccountsDropdown
            textColor="white"
            accounts={accountsInfo}
            onChange={handleAccountChange}
            defaultValue={currentAccountId}
          />
        }
      />

      <RightContentModal.Body>
        <DateRangeFilter
          filterHook={paymentsFilter}
          filterFn={(startDate, endDate, item) => {
            const dateRange = Interval.fromDateTimes(startDate, endDate);
            return dateRange.contains(DateTime.fromSQL(item.createdAt));
          }}
        />

        {!payments.length ? (
          <Text size="sm" ta="center" c="neutral.7" fs="italic" mt="xxl">
            There were no payments during this time
          </Text>
        ) : (
          <>
            {payments.map((payment) => {
              const formattedDate = payment.createdAt
                ? DateTime.fromSQL(payment.createdAt).toFormat('LLL dd, yyyy')
                : '';
              const sender = users?.find((user) => user.id === payment.userId);

              return (
                <Box key={payment.id} c="neutral.7" mt="xl">
                  <Flex justify="space-between" align="center">
                    <Flex align="center" gap="xs">
                      <Avatar size="sm">
                        {formatInitials(
                          `${sender?.firstName} ${sender?.lastName}`,
                        )}
                      </Avatar>
                      <Box>
                        <Text size="sm">{`${sender?.firstName} ${sender?.lastName}`}</Text>
                        <Text size="sm">{formattedDate}</Text>
                      </Box>
                    </Flex>
                    <Text ta="right" size="sm">
                      -{formatCurrency(Number(payment.payAmountCents) / 100)}
                    </Text>
                  </Flex>
                  <Divider my="sm" />
                </Box>
              );
            })}
          </>
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default PaymentsSummaryDetails;
