import { LoadingOverlay, Text, useMantineTheme } from '@mantine/core';
import FlexbaseHeader from './flexbase-header';
import type { TermsOfServiceDoc } from 'services/flexbase/flexbase-onboarding-client';
import { PdfIcon } from '../../assets/svg';
import { useStyles } from './styles';
import miscellaneousLinks from './legal-miscellaneous-links';
import { FlexbaseFooter } from '@common/footer/flexbase-footer';
import { useGetTermsOfServiceDocs } from '@queries/use-terms-of-service';

const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

const selectTableOfContents = (data: TermsOfServiceDoc[]) => {
  return data
    .filter(({ type }) =>
      ['credit', 'flexbase', 'banking', 'internationalPayments'].includes(type),
    )
    .reduce<Record<string, TermsOfServiceDoc[]>>((grouped, document) => {
      const words = document.type.split(/(?=[A-Z])/);
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1),
      );

      const type = capitalizedWords.join(' ');
      grouped[type] = [...(grouped[type] ?? []), document];
      return grouped;
    }, {});
};

const LegalPage = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { data, isPending } = useGetTermsOfServiceDocs({
    select: selectTableOfContents,
  });

  const dynamicContents = data
    ? Object.entries(data).map(([type, documents]) => (
        <div key={type}>
          <Text
            c={theme.colors.blackish[0]}
            className={classes.agreementTypeText}
          >
            {type} Agreements
          </Text>
          <div className={classes.agreementsContainer}>
            {documents.map((document) => (
              <div className={classes.container} key={document.url}>
                <a
                  href={document.url}
                  target="_blank"
                  className={classes.a}
                  rel="noreferrer"
                >
                  <div className={classes.documentsContainer}>
                    <PdfIcon className={classes.pdfIcon} />
                    <Text style={{ fontFamily: 'PP Neue Montreal' }}>
                      {titleCase(document.label)}
                    </Text>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      ))
    : [];

  const staticContents = (
    <div>
      {
        <div>
          <Text
            c={theme.colors.blackish[0]}
            className={classes.agreementTypeText}
          >
            Miscellaneous Links
          </Text>
          <div className={classes.agreementsContainer}>
            {miscellaneousLinks.map((document) => (
              <div className={classes.container} key={document.url}>
                <a
                  href={document.url}
                  target="_blank"
                  className={classes.a}
                  rel="noreferrer"
                >
                  <div className={classes.documentsContainer}>
                    <Text style={{ fontFamily: 'PP Neue Montreal' }}>
                      {document.label}
                    </Text>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );

  return (
    <>
      <LoadingOverlay visible={isPending} />
      <FlexbaseHeader />
      <div style={{ marginTop: 50 }}>
        <ul>{dynamicContents}</ul>
        <ul>{staticContents}</ul>
      </div>
      <FlexbaseFooter disclaimer2={true} />
    </>
  );
};

export default LegalPage;
