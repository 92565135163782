import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  titleText: string;
  subtitleText: ReactNode;
  image?: ReactNode;
};

const OnboardingTitleGroup = ({ titleText, subtitleText, image }: Props) => {
  const { classes } = useStyles();

  return (
    <div>
      {image}
      <div className={classes.title}>{titleText}</div>
      <div className={classes.subtitle}>{subtitleText}</div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  title: {
    '@media(max-width: 767px)': {
      fontSize: 30,
      lineHeight: 'unset',
      width: '100%',
    },
    fontFamily: 'Redaction',
    fontWeight: 400,
    fontSize: rem(32),
    color: theme.colors.primarySecondarySuccess[8],
    width: '445px',
  },
  subtitle: {
    color: theme.colors.primarySecondarySuccess[8],
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(21),
    marginTop: theme.spacing.xl,
    '@media(max-width: 767px)': {
      fontSize: rem(14),
      marginTop: theme.spacing.xs,
    },
  },
}));

export default OnboardingTitleGroup;
