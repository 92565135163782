import { usePaymentModalFilters } from '../use-payment-filters';
import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['Pending'],
    key: 'pending',
  },
  { label: 'Clearing', filterCriteria: 'Clearing', key: 'clearing' },
  {
    label: 'Completed',
    filterCriteria: ['Completed'],
    key: 'completed',
  },
  {
    label: 'Scheduled',
    filterCriteria: ['Scheduled'],
    key: 'scheduled',
  },
  {
    label: 'Action required',
    filterCriteria: ['Approval Needed', '2FA Required'],
    key: 'actionRequired',
  },
  {
    label: 'Non-Completed',
    filterCriteria: ['Failed', 'Returned', 'Cancelled'],
    key: 'failed',
  },
];

export const PaymentStatusFilter = () => {
  const paymentFilters = usePaymentModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      label="Status"
      filterKey="status"
      filterHook={paymentFilters}
      options={STATUS_MULTISELECT_OPTIONS}
      includesValue={(row) => row.status}
    />
  );
};
