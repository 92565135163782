import { DateTime } from 'luxon';
import PaymentsSection from './payments-section';
import { MoneyMovement } from '../../../../../services/flexbase/banking.model';
import { useChartFilter } from '@utilities/custom-hooks/use-chart-filter';
import GradientLineChart from '@common/charts/gradient-line-chart';
import { filterAndSortPaymentsByDate, getIntervalDates } from '../util';
import DetailSummaryLayout from '@common/layouts/detail-page/detail-summary-layout';
import { DetailSummaryDateRangeFilter } from '@common/details-page/filters/detail-summary-date-range-filter';

export type MoneyMovementWithSender = MoneyMovement & { sender: string };

type Props = {
  toDate?: string;
  fromDate?: string;
  modalContent: JSX.Element;
  detailsComponent: JSX.Element;
  payments: MoneyMovementWithSender[];
};

const PaymentsSummary = ({
  toDate,
  fromDate,
  payments,
  modalContent,
  detailsComponent,
}: Props) => {
  const { chartFilterValue, handleChartMouseMove, handleChartMouseLeave } =
    useChartFilter();

  const paymentsIntervalDates = getIntervalDates(payments, fromDate, toDate);

  const chronologicalBalancesAndDates = paymentsIntervalDates.map((date) => {
    const paymentsForDate = payments.filter(
      (item) =>
        DateTime.fromSQL(item.createdAt).toFormat('yyyy-MM-dd') === date,
    );
    const totalAmount = paymentsForDate.reduce(
      (acc, payment) => acc + Number(payment.payAmountCents) / 100,
      0,
    );
    return {
      name: DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('LLL dd'),
      date,
      value: totalAmount,
    };
  });

  const sortedTransactions = filterAndSortPaymentsByDate(
    chartFilterValue,
    payments,
  );

  return (
    <DetailSummaryLayout
      summaryDetails={detailsComponent}
      chart={
        <GradientLineChart
          data={chronologicalBalancesAndDates}
          onMouseLeave={handleChartMouseLeave}
          onMouseMove={handleChartMouseMove}
          noDataFoundText="There were no payments during this time"
        />
      }
      summaryActions={<DetailSummaryDateRangeFilter />}
      summary={
        <PaymentsSection
          payments={sortedTransactions}
          modalContent={modalContent}
        />
      }
    />
  );
};

export default PaymentsSummary;
