import { useState } from 'react';
import { useForm } from '@mantine/form';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import { JOB_TITLE_VALUES } from '../../../../constants/constants';
import { useOnboardingStyles } from '../../onboarding.styles';
import { RequiredFieldValidator } from '../../../../utilities/validators/validate-required';
import OwnerOfficerForm from 'areas/onboarding-v2/components/owner-officer-form-box';
import { platformClient } from '../../../../services/platform/platform-client';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};

const OfficerForm = ({ onCancel, onSuccess }: Props) => {
  const [error, setError] = useState('');
  const { classes } = useOnboardingStyles();
  const [loading, setLoading] = useState(false);
  const { accountId, company } = useRecoilValue(ApplicationState);

  // Filter the JOB_TITLE_VALUES array to exclude the 'Owner' value
  const filteredJobTitles = JOB_TITLE_VALUES.filter(
    (jobTitle) => jobTitle.value !== 'Owner',
  );

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
    },
    validate: {
      firstName: RequiredFieldValidator('First name is required'),
      lastName: RequiredFieldValidator('Last name is required'),
      email: RequiredFieldValidator('Email is required'),
      jobTitle: RequiredFieldValidator('Job title is required'),
    },
  });

  const onSubmit = async () => {
    setError('');
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      try {
        setLoading(true);
        const formValues = form.values;
        const request = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          roles: ['OFFICER'],
          companyId: company.id,
        };

        const addedOwner = await platformClient.inviteUser(
          accountId,
          formValues.email,
          'signatory',
          request,
        );
        await flexbaseOnboardingClient.addOfficer(addedOwner.person.id);
        // TODO: We also need to update the created user's job title but this is not currently possible for a non-SERVICER user. For now, the added user can supply this in their onboarding
        onSuccess();
      } catch (e) {
        if (
          e?.text &&
          typeof e.text === 'string' &&
          e.text.toLowerCase().includes('already in use')
        ) {
          setError('A user with this email already exists.');
        } else {
          setError(`An error occurred, could not add the officer.`);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <OwnerOfficerForm
      form={form}
      type="officer"
      loading={loading}
      onCancel={onCancel}
      onSubmit={onSubmit}
      errorMessage={error}
      title="Add an officer"
    >
      <FlexbaseSelect
        inputProps={{
          classNames: {
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          },
        }}
        data={filteredJobTitles}
        placeholder="Job title"
        label="Job title"
        {...form.getInputProps('jobTitle')}
      />
    </OwnerOfficerForm>
  );
};

export default OfficerForm;
