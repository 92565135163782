import {
  Box,
  Flex,
  Text,
  Button,
  Divider,
  LoadingOverlay,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { getMonthDayYear } from '@utilities/dates/dates';
import { CheckDeposit } from '@services/flexbase/banking.model';
import { useGetCheckDepositImages } from '@utilities/custom-hooks/use-check-deposit';
import { PiCheckFat } from 'react-icons/pi';

const CheckImages = ({ check }: { check: CheckDeposit }) => {
  const { classes, theme } = checkDetailsStyles();
  const { checkImages, isLoading, isError, refetch } = useGetCheckDepositImages(
    check.id,
  );

  const downloadImage = (url: string, side: 'front' | 'back') => {
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `check-${side}-${getMonthDayYear(check.createdAt)}.jpeg`;
    alink.click();
  };

  if (isError) {
    return (
      <Box mt="lg">
        <Text>
          An error occurred while trying to retrieve the check images.
        </Text>
        <Button mt="sm" variant="light" onClick={() => refetch()}>
          Try again
        </Button>
      </Box>
    );
  }

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {checkImages.length > 0 && (
        <Box mt="xxxl">
          <Text fw={600} c="primarySecondarySuccess.8" mb="md">
            Attachments
          </Text>
          {checkImages.map((img) => (
            <Flex
              px="sm"
              key={img.side}
              className={classes.checkImageContainer}
              onClick={() => downloadImage(img.url, img.side)}
            >
              <PiCheckFat color={theme.colors.primarySecondarySuccess[8]} />
              <Divider orientation="vertical" mx="sm" />
              <Text my="sm">{`check-${img.side}.jpeg`}</Text>
            </Flex>
          ))}
        </Box>
      )}
    </>
  );
};

export default CheckImages;

export const checkDetailsStyles = createStyles((theme) => ({
  checkImageContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginTop: theme.spacing.md,
    borderRadius: theme.spacing.xs,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[3]}`,
  },
}));
