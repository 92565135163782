import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
} from '@common/filter/filters';
import {
  BankingTransactionsFilterState,
  BankingTransactionsFilterStateModal,
} from 'recoil-state/filters/banking-transactions';
import { Transaction } from '@services/flexbase/banking.model';

export function useBankingTransactionsFilters(): ActiveFiltersReturnType<Transaction> {
  return createUseFiltersHook(BankingTransactionsFilterState);
}

export function useBankingTransactionsModalFilters(): ActiveFiltersReturnType<Transaction> {
  return createUseFiltersHook<Transaction>(BankingTransactionsFilterStateModal);
}
