import { ChangeEventHandler, useState } from 'react';
import { Stack, Text, TextInput } from '@mantine/core';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import { useLoginContext } from 'providers/login-provider';
import { useLoginStyles } from './login.styles';

const AuthVerifyCode = () => {
  const { classes } = useLoginStyles();
  const {
    resendSmsCode,
    setState,
    state: { selectedFactor, otpStatus, error },
  } = useLoginContext();
  const [code, setCode] = useState('');
  const { verifyCode } = useLoginContext();
  const onSubmit = async () => {
    if (code.length < 6) {
      setState({ error: 'Please enter a valid code' });
    } else {
      await verifyCode(code);
    }
  };

  const handleCodeInputChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setCode(value);
      if (value.length === 6) {
        verifyCode(value);
      }
    }
  };

  const handleResendCodeClick = () => {
    resendSmsCode();
  };

  return (
    <MultiStepFormStepWrapper id="2FA" onFormSubmit={onSubmit}>
      <Stack>
        {selectedFactor && (
          <Text className={classes.textLine}>
            {selectedFactor.method === 'sms' && !!selectedFactor.value
              ? `A 6 digit verification code has been ${otpStatus} to the phone number ending in ${selectedFactor.value}`
              : 'Enter the code displayed in your authenticator app'}
          </Text>
        )}
        <TextInput
          autoFocus
          label="Code"
          maxLength={6}
          type="number"
          error={error !== null}
          id="one-time-code"
          classNames={classes}
          placeholder="Enter 6 digit code"
          data-testid="input-one-time-code"
          value={code}
          onChange={handleCodeInputChange}
        />
      </Stack>
      <Text
        className={classes.reSendCode}
        mt="sm"
        onClick={handleResendCodeClick}
      >
        Re-send code
      </Text>
    </MultiStepFormStepWrapper>
  );
};

export default AuthVerifyCode;
