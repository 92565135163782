import { Box, Flex } from '@mantine/core';
import { useInvoiceWizard } from '../../invoice-wizard';
import { ReactNode } from 'react';
import { useWizardStyles } from '@common/wizard/wizard.styles';
import DocumentViewer from '@common/document-viewer';

const UploadInvoicePreview = ({
  hideIfNoImage,
}: {
  hideIfNoImage?: boolean;
}) => {
  const { state } = useInvoiceWizard();
  const { uploadedDocument, existingDocument } = state;
  const document = uploadedDocument || existingDocument;

  if (!document) {
    if (hideIfNoImage) {
      return null;
    }
    return (
      <Wrapper>
        <Flex justify="center" align="center" h="300px">
          Bill image will appear here
        </Flex>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <DocumentViewer document={document} altText="invoice preview" />
    </Wrapper>
  );
};

const Wrapper = ({ children }: { children?: ReactNode }) => {
  const { cx, classes } = useWizardStyles();
  return (
    <Box className={cx(classes.previewPanel, classes.scrollContainer)}>
      <Box h="100%" sx={{ zIndex: 10 }}>
        {children}
      </Box>
    </Box>
  );
};

export default UploadInvoicePreview;
