import { Box, rem, Flex, Text, Divider, UnstyledButton } from '@mantine/core';
import { ArrowUp } from '../../../../../assets/svg';
import useModal from '@common/modal/modal-hook';
import { sortDate } from '@utilities/dates/dates';
import { MoneyMovementWithSender } from './payments-summary';
import { createStyles } from '@mantine/emotion';

const PaymentsSection = ({
  payments,
  modalContent,
}: {
  modalContent: JSX.Element;
  payments: MoneyMovementWithSender[];
}) => {
  const { openRightModal } = useModal();
  const { classes } = usePaymentsSummaryStyles();
  const twoLatestPayments =
    payments.sort((a, b) => sortDate(a, b, false)).slice(0, 2) ?? [];

  const openModal = () => {
    openRightModal(modalContent);
  };

  return (
    <Box mt="xxl">
      <Flex align="center" gap="xs">
        <Box className={classes.iconContainer} bg="critical.0" c="critical.4">
          <ArrowUp width={12} />
        </Box>
        <Text>Payment</Text>
      </Flex>
      <Divider my="md" />
      {!payments.length ? (
        <Text size="sm" ta="center" c="neutral.7" fs="italic">
          There were no payments during this time
        </Text>
      ) : (
        <>
          {twoLatestPayments.map((payment) => (
            <Flex key={payment.id} justify="space-between" c="neutral.7">
              <Text>{payment.sender}</Text>
              <Text ta="right">-{payment.payAmount}</Text>
            </Flex>
          ))}
        </>
      )}
      <UnstyledButton
        mt="sm"
        onClick={openModal}
        c="primarySecondarySuccess.4"
        className={classes.viewAllButton}
      >
        View all
      </UnstyledButton>
    </Box>
  );
};

export default PaymentsSection;

const usePaymentsSummaryStyles = createStyles(() => ({
  iconContainer: {
    width: rem(16),
    height: rem(16),
    display: 'flex',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewAllButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
  },
}));
